<app-navbar *ngIf="isMobile" [title]="'Manage Rates' | translate"></app-navbar>

<div class="main pt-3">
  <div class="rates-edit">
    <div class="container-fluid">
      <h2>{{ "Edit Rates" | translate }}</h2>
      <div class="form-area">
        <form
          [formGroup]="rateForm"
          (ngSubmit)="onSubmit()"
          style="display: flex"
          [ngClass]="{ row: isMobile }"
        >
          <div
            class="form-box"
            style="width: 100%; border: none"
            [ngStyle]="{ padding: isMobile ? '0' : '0px 30px' }"
          >
            <div
              class="general"
              style="width: 100%; min-width: 100%"
              [ngStyle]="{
                padding: isMobile ? '0' : '30px 60px 30px 10px',
                'border-right': isMobile ? 'none' : '1px solid #ccc'
              }"
            >
              <h3>
                {{ "General :" | translate }}
                <div class="d-flex">
                  <i
                    class="far"
                    [ngClass]="{
                      'fa-eye-slash': isHidden,
                      'fa-eye': !isHidden
                    }"
                    (click)="toggleVisibility()"
                    [matTooltip]="'Eye Tooltip' | translate"
                  ></i>
                </div>
              </h3>

              <div class="form-group">
                <label class="d-flex"
                  >{{ "Rate Title :" | translate }}
                  <mat-icon
                    class="info-tooltip"
                    [matTooltip]="'Rate Title Tooltip' | translate"
                    >info</mat-icon
                  >
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="title"
                  placeholder=""
                  formControlName="title"
                  [max]="20"
                />
              </div>
              <div class="form-group">
                <label
                  >{{ "Duration" | translate }}
                  <span>({{ "Months" | translate }})</span> :</label
                >
                <select
                  class="form-control"
                  id=""
                  formControlName="duration"
                  [disabled]="isEdit"
                >
                  <option value="1">1 {{ "Month" | translate }}</option>
                  <option value="2">2 {{ "Month" | translate }}</option>

                  <option value="3">3 {{ "Month" | translate }}</option>
                  <option value="6">6 {{ "Month" | translate }}</option>
                  <option value="12">1 {{ "Year" | translate }}</option>
                </select>
              </div>
              <div class="form-group">
                <label>{{ "Days per week" | translate }} :</label>
                <select
                  class="form-control"
                  id=""
                  formControlName="daysPerWeek"
                  name="daysPerWeek"
                  [disabled]="isEdit"
                  required="true"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                </select>
              </div>
              <div class="form-group">
                <label>{{ "Hours" | translate }} :</label>
                <select
                  class="form-control"
                  id=""
                  formControlName="hoursPerDay"
                  [disabled]="isEdit"
                  required
                >
                  <option value="1">1 {{ "Hours" | translate }}</option>
                  <option value="2">2 {{ "Hours" | translate }}</option>
                  <option value="3">3 {{ "Hours" | translate }}</option>
                  <option value="4">4 {{ "Hours" | translate }}</option>
                </select>
              </div>
              <div class="form-group">
                <label class="d-flex">
                  {{ "Price" | translate }}:
                  <mat-icon
                    class="info-tooltip"
                    [matTooltip]="'Total Price Tooltip' | translate"
                    >info</mat-icon
                  >
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="price"
                  placeholder=""
                  autocomplete="off"
                  formControlName="price"
                  name="price"
                />
                <div
                  class="error-message"
                  *ngIf="
                    rateForm.get('price').errors &&
                    rateForm.get('price').errors.invalidPriceRange
                  "
                >
                  {{ "Price must be 0 or between 19.99 and 10000" | translate }}
                </div>
                <div
                  class="error-message"
                  *ngIf="
                    rateForm.get('price').errors &&
                    rateForm.get('price').errors.pattern
                  "
                >
                  {{ "Price must have two decimal places" | translate }}
                </div>
                <div
                  class="error-message"
                  *ngIf="
                    rateForm.get('price').errors &&
                    rateForm.get('price').errors.numeric
                  "
                >
                  {{ "Only numbers are allowed" | translate }}
                </div>
              </div>

              <div class="form-group">
                <label class="d-flex"
                  >{{ "Games for this rate" | translate }} :
                  <mat-icon
                    class="info-tooltip"
                    [matTooltip]="'Games for this rate tooltip' | translate"
                    >info</mat-icon
                  >
                </label>
                <mat-select
                  multiple
                  class="form-control"
                  id=""
                  formControlName="gamesForRate"
                  name="gamesForRate"
                  required="true"
                  [compareWith]="compareGames"
                >
                  <mat-option
                    *ngFor="let game of games$ | async"
                    [value]="game"
                    >{{ game.name }}</mat-option
                  >
                </mat-select>
              </div>
              <!-- <div class="form-group">
                <label class="d-flex">
                  {{ "Select Discount" | translate }}:
                  <mat-icon class="info-tooltip" [matTooltip]="'Select the discount for this rate' | translate">info</mat-icon>
                </label>
         
                  <mat-select class="form-control" [(value)]="discountValue" (selectionChange)="discountUpdated()" formControlName="discount">
                    <mat-option [value]="null" disabled>Select discount</mat-option>
                    <mat-option *ngFor="let discount of discounts" [value]="discount.value">
                      {{ discount.name }}
                    </mat-option>
                  </mat-select>
              </div>
              <div class="form-group">
                <label>{{ "Discount Active" | translate }}:</label>
                <mat-slide-toggle formControlName="isDiscountActive"></mat-slide-toggle>
              </div> -->
              <div>
                <app-rate-discount
                  (error)="handleError($event)"
                  (onSave)="discountsUpdated($event)"
                  [discountsList]="_discounts"
                  [selectedDiscounts]="selectedDiscounts"
                  [isDiscountAvailableForClass]="isDiscountAvailableForClass"
                ></app-rate-discount>
              </div>
            </div>
          </div>
          <!-- on right side -->

          <div style="width: 100%; margin-top: 30px">
            <h3 class="d-flex">
              {{ "Earnings Preview :" | translate }}
              <mat-icon
                class="info-tooltip"
                [matTooltip]="'Earnings Preview Tooltip' | translate"
                >info</mat-icon
              >
            </h3>
            <div class="form-group">
              <label class="d-flex"
                >{{ "Expected Number of Students" | translate }}
                <mat-icon
                  class="info-tooltip"
                  [matTooltip]="
                    'Expected Number of Students Tooltip' | translate
                  "
                  >info</mat-icon
                >
              </label>
              <select class="form-control" formControlName="maxNumOfStudents">
                <option selected disabled>
                  {{ "Only values between 5 and 12" | translate }}
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ "Total Lessons" | translate }} :</label>
              <input
                type="text"
                class="form-control"
                id="totalLessons"
                placeholder=""
                autocomplete="off"
                formControlName="totalLessons"
              />
              <div
                class="error-message"
                *ngIf="
                  rateForm.get('totalLessons').errors &&
                  rateForm.get('totalLessons').errors.numeric
                "
              >
                {{ "Only numbers are allowed" | translate }}
              </div>
            </div>
            <div class="form-group">
              <label>{{ "After Discount Price" | translate }} :</label>
              <input
                type="text"
                class="form-control"
                id="afterDiscountPrice"
                placeholder=""
                autocomplete="off"
                formControlName="afterDiscountPrice"
                [disabled]="true"
              />
            </div>

            <div class="form-group">
              <label>{{ "Service Cost per Student" | translate }} :</label>
              <input
                type="text"
                class="form-control"
                id="serviceCost"
                placeholder=""
                autocomplete="off"
                formControlName="serviceCost"
              />
              <div
                class="error-message"
                *ngIf="
                  rateForm.get('serviceCost').errors &&
                  rateForm.get('serviceCost').errors.numeric
                "
              >
                {{ "Only numbers are allowed" | translate }}
              </div>
            </div>
            <div class="form-group">
              <label
                >{{ "Price per Class" | translate }}
                <span>({{ "hourly" | translate }})</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="pricePerClassHourly"
                placeholder=""
                autocomplete="off"
                formControlName="pricePerClassHourly"
              />
              <div
                class="error-message"
                *ngIf="
                  rateForm.get('pricePerClassHourly').errors &&
                  rateForm.get('pricePerClassHourly').errors.numeric
                "
              >
                {{ "Only numbers are allowed" | translate }}
              </div>
            </div>

            <!-- <div class="form-group">
              <label
                >Price per Student after <br />starting date (hourly) :
                <span>(hourly)</span> :</label
              >
              <input
                type="text"
                class="form-control"
                id=""
                placeholder=""
                autocomplete="off"
                formControlName="pricePerStudentAfter"
              />
              <div
                class="error-message"
                *ngIf="
                  rateForm.get('pricePerStudentAfter').errors &&
                  rateForm.get('pricePerStudentAfter').errors.numeric
                "
              >
                Only numbers are allowed
              </div>
            </div> -->
            <!-- <div class="form-group">
              <label>{{ "Rate VAT" | translate }} :</label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder=""
                autocomplete="off"
                [disabled]="true"
                [value]="vat"
              />
            </div> -->
            <div class="form-group">
              <label
                >{{ "Price per Class" | translate }}
                <span>({{ "Full" | translate }})</span> :</label
              >
              <input
                type="text"
                class="form-control"
                id=""
                placeholder=""
                autocomplete="off"
                formControlName="pricePerClass"
              />
              <div
                class="error-message"
                *ngIf="
                  rateForm.get('pricePerClass').errors &&
                  rateForm.get('pricePerClass').errors.numeric
                "
              >
                {{ "Only numbers are allowed" | translate }}
              </div>
            </div>
            <div class="form-group">
              <label
                >{{ "Full Earning" | translate }}
                <span>({{ "Class" | translate }})</span> :</label
              >
              <input
                type="text"
                class="form-control"
                id=""
                placeholder=""
                autocomplete="off"
                formControlName="fullEarning"
              />
              <div
                class="error-message"
                *ngIf="
                  rateForm.get('fullEarning').errors &&
                  rateForm.get('fullEarning').errors.numeric
                "
              >
                {{ "Only numbers are allowed" | translate }}
              </div>
            </div>
          </div>
        </form>
        <!-- Discounts TODO: -->
        <!-- <div class="row mt-4" style="padding: 0 30px;"> 
          <div class="col-6">
            <mat-form-field appearance="outline" class="w-75">
              <mat-label>Discount</mat-label>
              <mat-select [(value)]="discountValue" (selectionChange)="discountUpdated()">
                <mat-option [value]="null" disabled>Select discount</mat-option>
                <mat-option *ngFor="let discount of discounts" [value]="discount.value">
                  {{ discount.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div> -->
        <div class="text-right mt-5">
          <button
            class="btn btn-primary"
            [disabled]="
              rateForm.controls['title'].invalid ||
              rateForm.controls['duration'].invalid ||
              rateForm.controls['daysPerWeek'].invalid ||
              rateForm.controls['hoursPerDay'].invalid ||
              (isEdit ? false : !rateForm.controls['duration'].touched) ||
              (isEdit ? false : !rateForm.controls['daysPerWeek'].touched) ||
              (isEdit ? false : !rateForm.controls['hoursPerDay'].touched) ||
              rateForm.controls['price'].invalid ||
              disabled
            "
            (click)="onSubmit()"
          >
            {{ "Save" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
