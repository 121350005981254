<div class="round-card">
  <div *ngFor="let discount of discountsList">
    <div class="row-between">
      <p class="promo-text" (click)="isMaster && updateDiscountDialog(discount)">
        {{ discount.percent }}% {{"off for the first" | translate}} {{ discount.count }}
        {{ discount.type | translate }}
      </p>
      <label class="switch">
        <input
          type="checkbox"
          (change)="toggleDiscount(discount, $event.target.checked)"
          [checked]="isSelected(discount)"
          [disabled]="!canToggle(discount)"
        />
        <span class="slider"></span>
      </label>
    </div>
  </div>
</div>
