<fuse-progress-bar></fuse-progress-bar>
<app-sidenav *ngIf="isMobile && userFirestore && (userFirestore$ | async)?.step >= 4"></app-sidenav>

<router-outlet></router-outlet>
<ng-container>
  <vertical-layout-1 [isMobile]="isMobile"></vertical-layout-1>
</ng-container>
<div *ngIf="isMobile && userFirestore && (userFirestore$ | async)?.step >= 4" style="z-index: 10000; position: fixed">
  <app-bottom-nav></app-bottom-nav>
</div>
