import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AuthService } from "app/shared/services/auth.service";
declare var $: any;

@Component({
  selector: "manage-rates-private",
  templateUrl: "./manage-rates-private.component.html",
  styleUrls: ["./manage-rates-private.component.scss"],
})
export class ManageRatesPrivateComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  rates: any[] = [];

  constructor(
    private authService: AuthService,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth
  ) {}

  ngOnInit() {
    this.afAuth.authState.subscribe((user) => {
      const loggedInUserId = user?.uid;
      this.afs
        .collection("users")
        .doc(loggedInUserId)
        .collection("rates", (ref) => ref.where("isgroup", "==", false))
        .valueChanges()
        .subscribe((rates) => {
          // Store the retrieved rates in a local variable
          this.rates = rates;
        });
    });
  }
}
