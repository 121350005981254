<div class="request-game">
  <div class="request-game-dialog">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      X
    </button>
    <img class="img-fluid" src="assets/images/warn.png" />
    <h3>
      {{
        "Write your Problem in the text box & our support will get back to you as soon as possible"
          | translate
      }}
    </h3>
    <textarea
      type="text"
      class="form-control"
      id=""
      placeholder="{{
        'Explain your problem in detail so we can help you in the best possible way'
          | translate
      }}"
      [(ngModel)]="message"
      autocomplete="off"
      maxlength="1500"
    ></textarea>
    <button
      [disabled]="isLoading"
      class="btn btn-danger"
      (click)="saveMessageToFirestore(message); isLoading = true"
    >
      <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i
      >{{ "Send Your Request" | translate }}
    </button>
  </div>
</div>
