<div class="main pt-2">
  <div class="earnings">
    <div class="container-fluid">
      <h2>Earnings</h2>
      <div class="nav-row">
        <h3>Money Earned :</h3>
        <div class="links">
          <a class="active">General</a>
          <a routerLink="/referrals">Referrals</a>
          <a routerLink="/products">Products</a>
        </div>
      </div>
      <h3 class="withdraw">Withdrawable Money: <span></span></h3>
      <div class="earning-graph-box">
        <div class="box-head">
          <span class="heading">
            <img src="assets/images/earnings/doller.png" alt="" />
            Earnings
          </span>
          <select class="form-control" id="">
            <option>Last 30 days</option>
            <option>Last 15 days</option>
            <option>Last 7 days</option>
          </select>
        </div>
        <div class="box-body">
          <div class="graph-area">
            <canvas
              id="myChart"
              width="800"
              height="250
                        "
            ></canvas>
          </div>
          <div class="total-earn-box">
            <img
              *ngIf="
                profilePicture$ | async as profilePicture;
                else noProfilePicture
              "
              src="{{ profilePicture }}"
              alt="Profile Picture"
              style="border-radius: 50%"
            />
            <ng-template #noProfilePicture>
              <img src="assets/images/dummy_user.png" class="rounded-circle" alt="Profile Picture" />
            </ng-template>
            <span class="total">$0</span>
          </div>
        </div>
      </div>

      <div class="games">
        <div class="row">
          <div class="col-md-4">
            <img src="" alt="" />
          </div>
        </div>
      </div>

      <div class="recent-withdraws">
        <h3>Recent Withdrawals :</h3>
        <div class="row">
          <div class="col-md-4">
            <div class="box">
              <img src="" alt="" />
              <div>
                <span class="bank"></span>
                <span class="date"></span>
              </div>
              <span class="cost"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
