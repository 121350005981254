import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { ManagerComponent } from "./manager.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { GroupClassDetailComponent } from "./group-class-detail/group-class-detail.component";
import { GroupClassEditComponent } from "./group-class-edit/group-class-edit.component";
import { ClassSettingComponent } from "./class-setting/class-setting.component";
import { PrivateClassDetailComponent } from "./private-class-detail/private-class-detail.component";
import { AddClassComponent } from "./add-class/add-class.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { calendarDialogComponent } from "../manage-rates/rates-edit/calendar/calendar-dialog.component";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import {MatChipsModule} from '@angular/material/chips';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { SharedAppModule } from "app/shared/shared-app.module";
import { TranslateModule } from "@ngx-translate/core";
import { AppAuthGuard } from "app/app.authguard";
import { MatDialogModule } from "@angular/material/dialog";
import { RateDiscountComponent, UpdateDiscountDialogComponent } from './rate-discount/rate-discount.component';
import { MatButtonModule } from "@angular/material/button";

const routes: Routes = [
  {
    path: "manager",
    component: ManagerComponent,
    canActivate: [AppAuthGuard]

  },
  {
    path: "group-class-view/:id",
    component: GroupClassDetailComponent,
    canActivate: [AppAuthGuard]

  },
  {
    path: "group-class-edit/:id/edit/:activityId",
    component: GroupClassEditComponent,
    canActivate: [AppAuthGuard]

  },
  {
    path: "group-class-edit/:id",
    component: GroupClassEditComponent,
    canActivate: [AppAuthGuard]

  },
  {
    path: "class-setting/:id",
    component: ClassSettingComponent,    canActivate: [AppAuthGuard]

  },
  {
    path: "private-class-view",
    component: PrivateClassDetailComponent,
    canActivate: [AppAuthGuard]

  },
  {
    path: "add-class",
    component: AddClassComponent,
    canActivate: [AppAuthGuard]

  },
];

@NgModule({
  declarations: [
    calendarDialogComponent,
    ManagerComponent,
    GroupClassDetailComponent,
    GroupClassEditComponent,
    ClassSettingComponent,
    PrivateClassDetailComponent,
    AddClassComponent,
    RateDiscountComponent,
    UpdateDiscountDialogComponent
  ],
  imports: [
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    NgxMatTimepickerModule.setLocale("it-IT"), 
    MatChipsModule,
    MatAutocompleteModule,
    SharedAppModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatDialogModule
    
  ],
})
export class ManagerModule {}
