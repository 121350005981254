import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "summary",
})
export class SummaryPipe implements PipeTransform {
  transform(message: string, length: number = 50): string {
    if (message == '' || message == null) return '';
    if (message.length < length) {
        return message;
    }
    return message.substring(0, length) + '...';
  }
}
