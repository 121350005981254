import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {
  FormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { RequestGameDialogComponent } from "app/main/authorizations/choose-game/request-game-dialog/request-game-dialog.component";
import { AuthService } from "app/shared/services/auth.service";
import { FirestoreService } from "app/shared/services/firestore.service";
import * as firebase from "firebase/compat/app";
import { ToastrService } from "ngx-toastr";
import { Observable, firstValueFrom, map, startWith } from "rxjs";
declare var $: any;
import { v4 as uuidv4 } from "uuid";
import { DiscountModel } from "../rate-discount/rate-discount.component";

@Component({
  selector: "class-setting",
  templateUrl: "./class-setting.component.html",
  styleUrls: ["./class-setting.component.scss"],
})
export class ClassSettingComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  btnDisabled: boolean = false;
  // Chips
  separatorKeysCodes: number[] = [ENTER, COMMA];
  studentCtrl = new FormControl("");
  filteredstudents: Observable<string[]>;
  allStudents = [];

  classId: string = "";
  classData: any;
  presentStudents: any = [];
  @ViewChild("studentInput") studentInput: ElementRef<HTMLInputElement>;
  selectedOption: string = "";

  isMoveStudent: boolean = false;
  isRemoveStudent: boolean = false;
  allClasses = [];

  description = "";
  motivation = "";
  toClass: string = "";
  user: any;

  isBlockSubscription = false;
  isDeleteClass = false;
  isPostponeClass = false;
  selectedOptionSettings = "";
  isMobile: boolean = false;
  _discounts: DiscountModel[] = [
    { uid: "1", code: "W2xyB", percent: 25, count: 15, type: "Students", date: new Date() },
    {
      uid: "2",
      code: "10x15zy",
      percent: 15,
      count: 10,
      type: "Days",
      date: new Date()
    },
  ];
  selectedDiscounts = [];
  selectedRate: any;
  constructor(
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private toastr: ToastrService,
    private firestorService: FirestoreService,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService
  ) {}

  ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    this.filteredstudents = this.studentCtrl.valueChanges.pipe(
      startWith(null),
      map((student: any | null) =>
        student ? this._filter(student) : this.allStudents.slice()
      )
    );
    this.authService.user.subscribe((usr) => {
      this.firestorService.doc$(`users/${usr.uid}`).subscribe((usr: any) => {
        this.user = usr;
        this.route.params.subscribe((params) => {
          if (params["id"]) {
            this.classId = params["id"];
            console.log(this.classId);
            this.fetchClassData();
          }
        });
      });
    });
  }
  async fetchAllClassesByTeacher() {
    try {
      const allClassesDocSnapshot = await firstValueFrom(
        this.firestore
          .collection("classes", (ref) =>
            ref.where("teacherId", "==", this.classData.teacherId)
          )
          .get()
      );
      const allClasses = allClassesDocSnapshot.docs.map((doc) => doc.data());
      this.allClasses = allClasses.filter((c: any) => c.uid !== this.classId);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  }
  async fetchClassData() {
    try {
      const classDocSnapshot = await firstValueFrom(
        this.firestore.doc(`classes/${this.classId}`).get()
      );
      this.classData = classDocSnapshot.data();
      if (this.classData && this.classData.discounts) {
        this.selectedDiscounts = this._discounts.filter((discount) =>
          this.classData.discounts.some((d) => d.uid === discount.uid)
        );
        console.log(this.selectedDiscounts);
      }
      this.fetchAllStudentsInStudentList();
      this.fetchAllClassesByTeacher();

      this.selectedRate = (
        await firstValueFrom(
          this.firestore
            .doc(`users/${this.user.uid}/rates/${this.classData.rateId}`)
            .get()
        )
      ).data();
      console.log(this.selectedRate);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  }
  supportDialog() {
    this.matDialog.open(RequestGameDialogComponent);
  }
  async fetchAllStudentsInStudentList() {
    this.allStudents = [];
    try {
      await Promise.all(
        this.classData.classStudentsList.map(async (studentId) => {
          const studentDocSnapshot = await firstValueFrom(
            this.firestore.doc(`users/${studentId}`).get()
          );
          const studentData: any = studentDocSnapshot.data();
          this.allStudents.push(studentData);
        })
      );
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  }
  updateSelection(option: string) {
    if (this.selectedOption === option) {
      this.selectedOption = "";
      this.isMoveStudent = false;
      this.isRemoveStudent = false;
    } else {
      this.selectedOption = option;
      this.isMoveStudent = this.selectedOption === "move";
      this.isRemoveStudent = this.selectedOption === "remove";
    }
  }
  async updateClassDetails() {
    if (this.classData.title == "" || this.classData.description == "") {
      return;
    }
    await this.firestorService.update(`classes/${this.classId}`, {
      title: this.classData.title,
      description: this.classData.description,
    });
  }
  deleteClass() {
    if (this.classData.classStudentsList.length > 0) {
      if (this.motivation === "") {
        this.toastr.error("Please enter motivation");
        return;
      }
      const uid = uuidv4();
      this.firestorService
        .set(`support/${uid}`, {
          category: "Delete Class",
          createdAt: firebase.default.firestore.FieldValue.serverTimestamp(),
          fromClass: this.classId,
          classId: this.classId,
          isMaster: true,
          isNew: true,
          isReplied: false,
          message: this.description,
          reportedUserId: "",
          senderEmail: this.user.email,
          senderId: this.user.uid,
          senderID: this.user.uid,

          senderUsername: this.user.nickname ?? this.user.username,
          studentsToRemove: [],
          toClass: "",
          uid: uid,
          updatedAt: firebase.default.firestore.FieldValue.serverTimestamp(),
          wantRefund: false,
        })
        .then(() => {
          this.toastr.success(
            this.translate.instant("Class deletion requested")
          );
          this.router.navigateByUrl("/manager");
          this.motivation = "";
        });

      return;
    }
    this.btnDisabled = true;
    this.firestorService
      .doc(`classes/${this.classId}`)
      .delete()
      .then(() => {
        this.toastr.success(
          this.translate.instant("Class deleted successfully")
        );
        this.btnDisabled = false;
        this.router.navigateByUrl("/manager");
      })
      .catch((err) => {
        this.toastr.error(this.translate.instant("Error deleting class"));
        this.btnDisabled = false;
      });
  }

  updateSelectionSettings(option: string) {
    if (this.selectedOptionSettings === option) {
      this.selectedOptionSettings = "";
      this.isBlockSubscription = false;
      this.isDeleteClass = false;
      this.isPostponeClass = false;
    } else {
      this.selectedOptionSettings = option;
      this.isBlockSubscription = this.selectedOptionSettings === "block";
      this.isDeleteClass = this.selectedOptionSettings === "delete";
      this.isPostponeClass = this.selectedOptionSettings === "postpone";
    }
  }
  sendSupportRequest() {
    if (this.presentStudents.length > 9) {
      this.toastr.error(
        this.translate.instant("You can't add more than 12 students in a class")
      );
      return;
    }
    const uid = uuidv4();
    this.firestorService
      .set(`support/${uid}`, {
        message: this.description,
        senderId: this.user.uid,
        senderID: this.user.uid,
        uid: uid,
        senderUsername: this.user.nickname ?? this.user.username,
        senderEmail: this.user.email,
        isMaster: true,
        isNew: true,
        isReplied: false,
        fromClass: this.classData.uid,
        toClass: this.isMoveStudent ? this.toClass : "",
        reportedUserId: "",
        wantRefund: false,
        category: this.isRemoveStudent ? "Remove Student" : "Move Student",
        studentsToRemove: this.presentStudents,
      })
      .then((v) => {
        this.toastr.success(
          this.translate.instant("Your request has been sent")
        );
        this.description = "";
        this.presentStudents = [];
      })
      .catch((e) => {
        this.toastr.error(this.translate.instant("Error sending request"));
      });
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our student
    if (value) {
      const student = this.allStudents.find((s) => (s.username === value) || s.nickname == value);
      if (student && !this.presentStudents.includes(student.username)) {
        this.presentStudents.push(student.username);
      }
    }

    // Clear the input value
    event.chipInput!.clear();

    this.studentCtrl.setValue(null);
  }

  remove(student: string): void {
    const index = this.presentStudents.indexOf(student);

    if (index >= 0) {
      this.presentStudents.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.presentStudents.includes(event.option.value)) {
      this.presentStudents.push(event.option.value);
    }
    this.studentInput.nativeElement.value = "";
    this.studentCtrl.setValue(null);
  }

  private _filter(value: any): string[] {
    const filterValue = value.toLowerCase();

    return this.allStudents.filter((student) =>
      student.username.toLowerCase().includes(filterValue)
    );
  }

  updateSettingsSendSupportRequest() {
    const uid = uuidv4();
    this.firestorService
      .set(`support/${uid}`, {
        message: this.motivation,
        senderId: this.user.uid,
        senderID: this.user.uid,

        uid: uid,
        senderUsername: this.user.username,
        senderEmail: this.user.email,
        isMaster: true,
        isNew: true,
        isReplied: false,
        fromClass: this.classData.uid,
        toClass: "",
        reportedUserId: "",
        wantRefund: false,
        category:
          this.selectedOptionSettings === "block"
            ? "Block Subscription"
            : this.selectedOptionSettings === "delete"
            ? "Delete Class"
            : "Postpone Class",
        studentsToRemove: [],
        isRefund: false,
        creationDateTS: firebase.default.firestore.FieldValue.serverTimestamp(),
      })
      .then((v) => {
        this.toastr.success(
          this.translate.instant("Your request has been sent")
        );
        this.motivation = "";
      })
      .catch((e) => {
        this.toastr.error(this.translate.instant("Error sending request"));
      });
  }

  doItBasedONCheckBox() {
    if (this.isBlockSubscription) {
      this.updateSettingsSendSupportRequest();
    } else if (this.isDeleteClass) {
      this.deleteClass();
    } else if (this.isPostponeClass) {
      this.updateSettingsSendSupportRequest();
    }
  }
  isRateWithSingleDiscount(): boolean {
    return this.selectedRate && this.selectedRate.discounts?.length >= 1;
  }
  showErrorMessage() {
    alert("This class has a discount already associated with it.");
  }
  discountsUpdated(discounts) {
    if (!this.isRateWithSingleDiscount()) {
      this.selectedDiscounts = discounts;
      discounts.forEach((discount) => {
        // this.discountValue = discount.percent;
        // this.updateAfterDiscountPrice();
      });
    }
  }
  saveDiscounts() {
    this.firestore
      .doc(`classes/${this.classId}`)
      .update({
        discounts: this.selectedDiscounts,
      })
      .then(() => {
        this.toastr.success(
          this.translate.instant("Discounts updated successfully")
        );
      })
      .catch((err) => {
        this.toastr.error(this.translate.instant("Error updating discounts"));
      });
  }
}
