<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.text}}</p>
  <mat-form-field *ngIf="data.inputField" fill="outline">
    <input matInput [(ngModel)]="data.inputFieldValue">
  </mat-form-field>
</div>
<div mat-dialog-actions align="center">
  <button mat-button class="No" (click)="onCancel()">{{"Cancel" | translate}}</button>
  <button mat-button class="Yes" (click)="onConfirm()">{{"Yes" | translate}}</button>
</div>
