import { Component, Input } from '@angular/core';
import { SidenavService } from 'app/shared/sidenav.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() title: string = '';
  @Input() hideBackIcon: boolean = false;
  constructor(private sidenavService: SidenavService) { }

  public onToggleSidenav(){
    this.sidenavService.toggle();
  }
}
