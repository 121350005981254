<div class="main pt-2">
  <div class="achievements-page">
    <div class="container-fluid">
      <h2>{{ "Achievements" | translate }}</h2>

      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 1:
          </h4>
          <span class="unlock-text">{{
            "unlock to create up to 3 group rates" | translate
          }}</span>
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text">{{ content }}</span>
          <div class="progress-bar">
            <div class="bar-green" [style.width.%]="progress"></div>
          </div>
          <span class="total">{{ progress2 }}/{{ total }}</span>
        </div>
      </div>

      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />{{
              " Achievement" | translate
            }}
            2:
          </h4>
          <span class="unlock-text">
            {{
              "possibility of creating up to 5 group rates" | translate
            }}</span
          >
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text"
            >{{
              "Complete 3 courses of at least 1 month with at least 4 members"
                | translate
            }}
          </span>
          <div class="progress-bar">
            <div class="bar-green" [style.width.%]="progressfilter"></div>
          </div>
          <span class="total">{{ progressfilter2 }}/{{ total }}</span>
        </div>
      </div>
      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 3:
          </h4>
          <span class="unlock-text">{{
            "possibility to create up to 10 group rates" | translate
          }}</span>
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text">{{
            "Complete 5 courses of at least 1 month with at least 4 students"
              | translate
          }}</span>
          <div class="progress-bar">
            <div class="bar-green" [style.width.%]="progressfilterfilter"></div>
          </div>
          <span class="total">{{ progressfilterfilter }}/{{ total }}</span>
        </div>
      </div>
      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 4:
          </h4>
          <span class="unlock-text">{{
            "possibility to create up to 3 total classes" | translate
          }}</span>
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text">{{
            "Finish 1 course of at least 1 month with at least 7 students"
              | translate
          }}</span>
          <div class="progress-bar">
            <div class="bar-green" [style.width.%]="acheivement4width"></div>
          </div>
          <span class="total">{{ acheivement4width2 }}/{{ total }}</span>
        </div>
      </div>
      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 5:
          </h4>
          <span class="unlock-text">{{
            "unlock to create up to 5 total classes" | translate
          }}</span>
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text">{{
            "Finish 3 courses of at least 1 month with at least 7 students"
              | translate
          }}</span>
          <div class="progress-bar">
            <div class="bar-green" [style.width.%]="acheivement5width"></div>
          </div>
          <span class="total">{{ acheivement5width2 }}/{{ total }}</span>
        </div>
      </div>
      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 6:
          </h4>
          <span class="unlock-text">{{
            "possibility to create up to 5 classes for each rate" | translate
          }}</span>
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text"
            >{{
              "Complete 5 courses of at least 1 month with at least 7 students"
                | translate
            }}
          </span>
          <div class="progress-bar">
            <div class="bar-green" [style.width.%]="acheivement6width"></div>
          </div>
          <span class="total">{{ acheivement6width2 }}/{{ total }}</span>
        </div>
      </div>
      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 7:
          </h4>
          <span class="unlock-text"
            >→
            {{
              "possibility to create up to 3 private rates" | translate
            }}</span
          >
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text">{{
            "Carry out 3 hours of private lessons" | translate
          }}</span>
          <div class="progress-bar">
            <div class="bar-green w-0"></div>
          </div>
          <span class="total">0/10</span>
        </div>
      </div>
      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 8:
          </h4>
          <span class="unlock-text">{{
            "possibility to create up to 5 private rates" | translate
          }}</span>
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text">{{
            "Carry out 10 hours of private lessons" | translate
          }}</span>
          <div class="progress-bar">
            <div class="bar-green w-0"></div>
          </div>
          <span class="total">0/10</span>
        </div>
      </div>
      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 9:
          </h4>
          <span class="unlock-text">
            {{
              "possibility to create up to 10 private rates" | translate
            }}</span
          >
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text">{{
            "Carry out 30 hours of private lessons" | translate
          }}</span>
          <div class="progress-bar">
            <div class="bar-green w-0"></div>
          </div>
          <span class="total">0/10</span>
        </div>
      </div>
      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 10:
          </h4>
          <span class="unlock-text">{{
            "possibility to block enrollments and postpone the start date of a course independently"
              | translate
          }}</span>
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text">{{
            "Finish 1 course of at least 3 months with at least 5 enrolled"
              | translate
          }}</span>
          <div class="progress-bar">
            <div class="bar-green w-0"></div>
          </div>
          <span class="total">0/10</span>
        </div>
      </div>
      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 11:
          </h4>
          <span class="unlock-text">
            {{
              "possibility to move students between classes independently"
                | translate
            }}</span
          >
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text">{{
            "Finish 3 courses of at least 3 months with at least 5 students"
              | translate
          }}</span>
          <div class="progress-bar">
            <div class="bar-green w-0"></div>
          </div>
          <span class="total">0/10</span>
        </div>
      </div>
      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 12:
          </h4>
          <span class="unlock-text">{{
            "possibility to apply discounts to classes" | translate
          }}</span>
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text">{{
            "Bring 3 active users through Referrals" | translate
          }}</span>
          <div class="progress-bar">
            <div class="bar-green w-0"></div>
          </div>
          <span class="total">0/10</span>
        </div>
      </div>
      <div class="achievement-box">
        <div class="b-head">
          <h4>
            <img src="assets/images/achiev.png" alt="" />
            {{ "Achievement" | translate }} 13:
          </h4>
          <span class="unlock-text">{{
            "“Experienced master” check mark" | translate
          }}</span>
        </div>
        <div class="b-body">
          <i class="fas fa-check-circle"></i>
          <span class="inner-text">{{
            "Carry out 9000 hours of lessons " | translate
          }}</span>
          <div class="progress-bar">
            <div class="bar-green w-0"></div>
          </div>
          <span class="total">0/10</span>
        </div>
      </div>
    </div>
  </div>
</div>
