import { Component, ViewChild, OnInit } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { SidenavService } from "../../sidenav.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { logoutConfirmDialogComponent } from "app/layout/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent {
  @ViewChild("sidenav", { static: false }) public sidenav: MatSidenav;
  dialogOpen: boolean;

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.sidenavService.sideNavToggle().subscribe((a) => {
      console.log(a);
      this.sidenav.toggle();
    });
  }
  navigateTo(route: string) {
    this.router.navigate([`/${route}`]);
    this.sidenav.toggle();
  }
  logout() {
    this.dialogOpen = true;
    this.sidenav.toggle();

    const dialogRef = this.dialog.open(logoutConfirmDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      this.dialogOpen = false;
      if (result) {
      }
    });
    // your logout logic goes here
  }
}
