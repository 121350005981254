import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { DashboardComponent } from "./dashboard.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { InjectionToken } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common"; // Import the DatePipe
import { SharedAppModule } from "app/shared/shared-app.module";
import { TranslateModule } from "@ngx-translate/core";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

export const DATE_FORMATS = new InjectionToken<any>("dateFormats");

const routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
  },
];

@NgModule({
  declarations: [DashboardComponent],
  providers: [DatePipe, { provide: DATE_FORMATS, useValue: MY_DATE_FORMATS }],
  imports: [
    NgbModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SharedAppModule,
    TranslateModule,
  ],
  exports: [CalendarModule],
})
export class DashboardModule {}
