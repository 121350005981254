import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  // Use a Subject to emit the toggle event
  public sidenavToggleSubject: Subject<boolean> = new Subject<boolean>();

  public toggle(): void {
    console.log('toggle 2');
    // Emit an event to all observers
    this.sidenavToggleSubject.next(true);
  }

  sideNavToggle() {
    return this.sidenavToggleSubject.asObservable();
  }
}
