import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";

import { FuseSearchBarComponent } from "./search-bar.component";

@NgModule({
  declarations: [FuseSearchBarComponent],
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule],
  exports: [FuseSearchBarComponent],
})
export class FuseSearchBarModule {}
