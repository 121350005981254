import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Observable, of } from "rxjs";
import { filter, map, shareReplay, switchMap } from "rxjs/operators";
import firebase from "firebase/compat/app";
import { AuthService } from "app/shared/services/auth.service";
import * as urlMetadata from "url-metadata";
import { FirestoreService } from "app/shared/services/firestore.service";
declare var $: any;
import { OwlOptions } from "ngx-owl-carousel-o";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";

interface Game {
  fieldName: string;
  creationDateTS: Date;
  image: string;
  uid: string;
  name: string;

  // other game fields...
}
interface review {
  creationDateTS: firebase.firestore.Timestamp;
  date: firebase.firestore.Timestamp;
  gameAbility: number;
  pleasantness: number;
  qualityPerPrice: number;
  reply: string;

  review: string;
  teachAbility: number;
  uid: string;
  userCountry: string;
  userId: string;
  userImage: string;
  userName: string;
}
interface Product {
  image: string;
  name: string;
  link: string;
}

interface User {
  about: string;
  languages: string[];
  aboutme: string;
  profilePic: string;
  name: string;
  country: string;

  gameIds: string[];
  registerDateTS: firebase.firestore.Timestamp;
  coverPic: string;
  rating: number;

  gameAbility: number;
  qualityPerPrice: number;
  pleasantness: number;
  teachAbility: number;
}

@Component({
  selector: "profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  customOptions: OwlOptions = {
    autoplay:true,
    autoplayTimeout:3000,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ["", ""],
    items: 1,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };
  profilePicture$: Observable<string>;
  rating$: Observable<number>;
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  aboutMe$: Observable<string>;
  name$: Observable<string>;
  country$: Observable<string>;
  aboutMe: string;
  user: firebase.User;
  products$: Observable<Product[]>;
  reviews$: Observable<review[]>;
  gameAbility$: Observable<number>;
  abilitytoteach$: Observable<number>;
  Pleasantness$: Observable<number>;
  QualityPrice$: Observable<number>;
  rates: any[] = [];

  coverPic$: Observable<string>;
  games$: Observable<Game[]>;
  registerDateTS$: Observable<firebase.firestore.Timestamp>;

  languages$: Observable<string[]> = of([]);
  today = new Date();
  rating: number = 0;
  registereddate: Date;
  abilitytogame: number = 0;
  abilitytoteach: number = 0;
  Pleasantness: number = 0;
  QualityPrice: number = 0;
  userData$: Observable<any>;
  userData: any;
  gamesData = [];
  gameIds: any;
  reviews: any = [];
  isMobile: boolean;
  selectedRate: any;
  isRateClicked = false;
  days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ].map((e) => this.translate.instant(e));
  classesForSelectedRate = [];
  showAboutMe = false; // Added for toggle

  toggleAboutMe() {
    this.showAboutMe = !this.showAboutMe;
  }
  constructor(
    private authService: AuthService,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private firestoreService: FirestoreService,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
    private http: HttpClient,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private dynamicLinkService: DynamicLinkService,
    private toastr: ToastrService,
  ) {
    // Get the currently logged-in user
    this.afAuth.authState.subscribe((user) => {
      this.user = user;
      if (user) {
        this.reviews$ = this.firestoreService.col$(`users/${user.uid}/reviews`);
        this.userData$ = this.afs
          .collection("users")
          .doc(user.uid)
          .get()
          .pipe(shareReplay(1));

        this.userData$.subscribe((doc) => {
          if (doc.exists) {
            const data = doc.data();
            this.userData = data;
            console.log(this.userData);
            this.rating = data.rating;
            this.aboutMe$ = of(data.about);
            this.profilePicture$ = of(data.profilePic);
            this.name$ = of(data.nickname ?? data.username);
            this.country$ = of(data.country);
            this.rating$ = of(data.rating);
            this.coverPic$ = of(data.coverPic);
            this.languages$ = of(data.languages);
            this.gameAbility$ = of(data.gameAbility);
            this.abilitytoteach$ = of(data.teachAbility);
            this.Pleasantness$ = of(data.pleasantness);
            this.QualityPrice$ = of(data.qualityPerPrice);
            this.registerDateTS$ = of(data.creationDateTS);
            data.productLinks = (data.productLinks ?? []).filter((p) => !!p);
            
            // data.productLinks.map((p) => {
            //   this.http.get(p).subscribe((e) => {
            //     console.log(e);
            //   })
            //   console.log(p);
            //   return urlMetadata(p, {
            //     // mode: "no-cors",
            //   })
            //     .then((data) => {
            //       console.log(data);
            //     })
            //     .catch((err) => {
            //       console.log(err);
            //     });
            // });
            this.gameIds = (data.gameIds ?? []) as [];
            this.products$ = of((data.productLinks ?? []).filter((p) => !!p));
            this.firestoreService.col$("games").subscribe((games: []) => {
              this.gamesData = games.filter((g: any) =>
                (data.gameIds ?? []).includes(g.uid)
              );
              this.games$ = of(
                games.filter((g: any) => (data.gameIds ?? []).includes(g.uid))
              );
            });
          }
        });
      }
    });
  }
  public ngAfterViewInit(): void {window.dispatchEvent(new Event('resize'));}

  async updateAboutMe() {
    // Get a reference to the Firestore "users" collection
    const usersRef = this.afs.collection("users");

    // Update the "aboutme" field of the logged-in user in Firestore
    await usersRef.doc(this.user.uid).update({ aboutme: this.aboutMe });
  }
  formatUrl(url: string): string {
    if (!url) {
      return ''; // Return an empty string if the URL is not provided
    }

    // Check if the URL starts with 'http://' or 'https://'
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    } else {
      // If not, prepend 'https://' to the URL
      return 'https://' + url;
    }
  }
  gameNames(rate) {
    return rate.gamesForRate ? rate.gamesForRate.map((g) => g.name).join(','): '';
  }
  ngOnInit(): void {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    // Get the currently logged-in user
    this.afAuth.authState
      .pipe(
        filter((user) => !!user),
        switchMap((user) => {
          this.user = user;
          const loggedInUserId = user.uid;

          return this.afs
            .collection("users")
            .doc(loggedInUserId)
            .collection("rates")
            .valueChanges();
        })
      )
      .subscribe((rates) => {
        this.rates = rates;
        this.rates = this.rates.filter((r) => !r.hidden);
        console.log("Rates:", this.rates);
      });
  }
  shareLink() {
    this.dynamicLinkService
      .createDynamicLink(this.user.uid)
      .pipe(map((link: any) => link.shortLink))
      .subscribe((link) => {
        console.log(link);
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(link)
            .then(() => {
              this.toastr.success(
                this.translate.instant("Link copied to clipboard"),
                "Success"
              );
            })
            .catch((err) => {
              console.error("Could not copy text: ", err);
              this.toastr.error(
                this.translate.instant("Failed to copy link"),
                "Error"
              );
            });
        } else {
          // Clipboard API not available
          this.toastr.error(
            this.translate.instant("Clipboard not supported"),
            "Error"
          );
        }
        this.openDialog(link);
      });
  }

  formatTime(time: string): string {
    const [hours, minutes] = time.split(':').map(Number);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
  openDialog(link: string): void {
    this.matDialog.open(LinkDialogComponent, {
      width: "450px",
      data: { link: link },
    });
  }

  replyToReview(review, reply) {
    console.log(review);
    review.reply = reply;
    this.firestoreService
      .update(`users/${this.user.uid}/reviews/${review.uid}`, {
        reply: review.reply,
      })
      .then(() => {
        console.log("Review updated");
      });
  }
  showAbout() {
    this.isRateClicked = false;
  }
  scheduleDayClass(cls) {
    const scheduleDays = cls.scheduleDays;

    if (scheduleDays) {
      const scheduled = scheduleDays.reduce((acc, cur, index) => {
        if (cur) {
          acc.push(this.days[index]);
        }
        return acc;
      }, []);

      const scheduledDaysString = scheduled.join(", ");
      return scheduledDaysString;
    }
    return "";
  }
  chooseRateDialog(rate) {
    this.isRateClicked = true;
    this.selectedRate = rate;
    this.afs
      .collection("classes", (ref) => ref.where("rateId", "==", rate.uid).where('endDate', '>', new Date()))
      .valueChanges()
      .subscribe((classes: any[]) => {
        this.classesForSelectedRate = classes;
        console.log(classes);
      });
    // show list of all classes
    // this.matDialog.open(chooseRateDialogComponent);
  }
  formatURL(link: string): string {
    if (!link) return '';
  
    if (link.startsWith('http://') || link.startsWith('https://')) {
      return link;
    } else if (link.startsWith('www.')) {
      return 'https://' + link;
    } else {
      return 'https://' + 'www.' + link;
    }
  }

  getDiscountedPrice(rate) {
    return parseFloat((rate.discounts.length > 0
      ? rate.price * ((100 - rate.discounts[0].percent) / 100)
      : rate.price).toFixed(2));
  }
  openInfoDialog(classInfo, event: MouseEvent): void {
    event.stopPropagation(); // This prevents the click from bubbling up to the parent
    this.matDialog.open(InfoDialogComponent, {
      width: "350px",
      data: {
        cls: classInfo,
      }, // pass data to the dialog
    });
  }
}

import { Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DynamicLinkService } from "app/shared/services/dynamic-link.service";
import { ToastrService } from "ngx-toastr";
import { LinkDialogComponent } from "../manager/group-class-detail/group-class-detail.component";

@Component({
  selector: "app-info-dialog",
  template: `
    <p mat-dialog-content>
      {{ data.cls.description == "" ? "No Description" : data.cls.description }}
    </p>
    <div mat-dialog-actions>
      <button mat-button color='primary' mat-dialog-close>Close</button>
    </div>
  `,
  styleUrls: [],
})
export class InfoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
