import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { map } from "rxjs/operators";

import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Observable } from "rxjs";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { CalendarView } from "angular-calendar";
import { isSameMonth, isSameDay, startOfMonth, endOfMonth } from "date-fns";
import { AuthService } from "app/shared/services/auth.service";
import { DatePipe } from "@angular/common";
import { FirestoreService } from "app/shared/services/firestore.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";
import { VerifyAccountComponent } from "../settings/verify-account/verify-account.component";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

declare var $: any;

@Component({
  selector: "dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  @ViewChild("dateSelect", { static: false })
  dateSelectRef!: ElementRef<HTMLInputElement>;
  daysOfWeek: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  view: CalendarView = CalendarView.Month;
  calendar: any[] = [];

  viewDate: Date = new Date();

  selectedDate: string = "";

  activeDayIsOpen: boolean = false; // declare the property here

  dropdownOptions = [];

  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  upcomingClasses: Observable<any[]>;
  selectedMonth: Date = new Date();
  daysInMonth: any[] = [];
  blankDays: any[] = [];
  daysWithClasses: any[] = [];
  month: number;
  year: number;
  qaz: Date = new Date();
  nextMonth: Date = new Date();
  monthAfterNext: Date = new Date();
  today: Date = new Date();
  thismonth: Date = new Date();
  selected: number;
  upcomingClassesData: any[] = [];
  tasks = {};
  events = [
    { title: "Event 1", time: "8:00 am", description: "This is event 1" },
    { title: "Event 2", time: "10:00 am", description: "This is event 2" },
    { title: "Event 3", time: "2:00 pm", description: "This is event 3" },
    // ...
  ];
  selectedDayClasses: any[] = [];

  selectedWeek = 1;

  selDate: Date = new Date();
  alldates = [];

  isMobile = false;
  user;

  selectedMonthVal = '';
  constructor(
    private firestore: AngularFirestore,
    private datePipe: DatePipe,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private firestoreService: FirestoreService,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.selectedMonth = new Date();

    const today = new Date();
    this.year = today.getFullYear();
    this.month = today.getMonth();
    this.selectedMonthVal = `${this.year}-${this.month}`
    this.thismonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.selectedMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.nextMonth = new Date(today.getFullYear(), today.getMonth() + 1);
    this.monthAfterNext = new Date(today.getFullYear(), today.getMonth() + 2);
    // of length 12
    const currentYear = new Date().getFullYear();
    const startYear = 2023;
    const endYear = currentYear + 2;
    const totalYears = endYear - startYear + 1;
    const totalMonths = 12 * totalYears;
    let monthIndex = 0;
    this.dropdownOptions = Array.from({ length: totalMonths }, () => ({
      value: "",
      label: "",
  }));
  
  
    for (let year = startYear; year <= endYear; year++) {
        for (let month = 0; month < 12; month++) {
            this.dropdownOptions[monthIndex].label = this.getMonthName(new Date(year, month, 1));
            this.dropdownOptions[monthIndex].value = `${year}-${month}`;
            monthIndex++;
        }
    }
   
  }

  getMonthName(date: Date): string {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].map((e) => this.translate.instant(e))
    return monthNames[date.getMonth()] + " " + date.getFullYear();
  }
  getWeekOfMonth(date) {
    const month = date.getMonth();
    let count = 0;
    for (let i = 1; i < date.getDate(); i++) {
      const temp = new Date(date.getFullYear(), month, i);
      if (temp.getDay() !== 6 || i === 1) {
        count++;
      }
    }
    return Math.ceil(count / 7);
  }
  onDateClick(day: number) {
    const clickedDate = new Date(this.year, this.month, day);
    clickedDate.setHours(0, 0, 0, 0); // Ensure the time part is zeroed out for accurate comparison
    this.selDate = clickedDate;
    this.selectedWeek = this.getWeekOfMonth(clickedDate);
    this.selectedDayClasses = this.upcomingClassesData
      .filter((classData) =>
        classData.scheduledDates.some((timestamp) => {
          const scheduledDate = timestamp.toDate();
          console.log(scheduledDate);
          scheduledDate.setHours(0, 0, 0, 0);
          return scheduledDate.getTime() === clickedDate.getTime();
        })
      )
      .map((classData) => {
        return {
          ...classData,
          startTimeMinutes: this.timeToMinutes(classData.startTime),
          endTimeMinutes: this.timeToMinutes(classData.endTime),
        };
      });
  }

  timeToMinutes(time: string): number {
    const [timePart, period] = time.split(" ");
    const [hours, minutes] = timePart.split(":").map(Number);
    let totalMinutes = hours * 60 + minutes;

    // If it's PM and it's not 12:xx PM, we add 12 hours to shift to 24h format
    // if (period.toUpperCase() === "PM" && hours !== 12) {
    //   totalMinutes += 12 * 60;
    // }
    // // If it's AM and it's 12:xx AM, we subtract 12 hours to shift to 24h format
    // else if (period.toUpperCase() === "AM" && hours === 12) {
    //   totalMinutes -= 12 * 60;
    // }
    return totalMinutes;
  }
  getTimePosition(time: string): number {
    const [hours, minutes] = time.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    return (totalMinutes / 60) * 50; // Assumes each hour is 50 pixels tall
  }
  isClassScheduled(day: number): boolean {
    const date = new Date(this.year, this.month, day);
    const dateKey = this.datePipe.transform(date, "yyyy-MM-dd"); // Format the date as 'yyyy-MM-dd'
    return this.daysWithClasses.includes(dateKey);
  }

  onHourClick(hour: string) {}

  ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
      .subscribe((o) => {
        if (o.matches) {
          this.isMobile = o.matches;
          this._fuseConfigService.config = {
            layout: {
              navbar: {
                hidden: this.isMobile,
              },
              toolbar: {
                hidden: this.isMobile,
              },
              footer: {
                hidden: this.isMobile,
              },
              sidepanel: {
                hidden: this.isMobile,
              },
            },
          };
        }
      });
    this.afAuth.authState.subscribe((user) => {
      if (user == null) return;
      this.firestoreService.doc$(`users/${user.uid}`).subscribe((user: any) => {
        this.user = user;
        // this.user.settings = this.user.settings || {};
        // this.user.settings.selectedLanguage =
        //   this.user.settings.selectedLanguage || "en";
        // this.user.vat = this.user.vat || "";
      });
    });

    // this.generateCalendar();
    this.getUpcomingClasses();
    this.getDaysOfMonth();
  }

  getUpcomingClasses() {
    this.afAuth.authState.subscribe((user) => {
      if (user == null) return;
      const loggedInUserId = user?.uid;
      const today = new Date();
      this.upcomingClasses = this.firestore
        .collection("classes", (ref) =>
          ref
            .where("teacherId", "==", loggedInUserId)
            .where("endDate", ">=", today)
            .orderBy("endDate", "asc")
        )
        .valueChanges();

      this.upcomingClasses.subscribe((classes) => {
        this.upcomingClassesData = classes;
        this.alldates = [];
        console.log(this.upcomingClassesData);
        this.upcomingClassesData.forEach((classData, index) => {
          this.alldates[index] = classData.scheduledDates;
          classData.scheduledDates.forEach((date) => {
            this.daysWithClasses.push(
              this.datePipe.transform(date.toDate(), "yyyy-MM-dd")
            );
          });
        });
        // REMOVE DATE FROM ALLDATES THAT HAS PASSED
        this.alldates.forEach((dateArray, index) => {
          this.alldates[index] = dateArray.filter((date) => {
            return date.toDate() >= today;
          });
        });
      });
    });
  }
  onMonthChange(event) {
    this.month = event.target.value.split("-")[1];
    this.year = event.target.value.split("-")[0]; // get year from the selected month
    this.selectedMonthVal = event.target.value;
    this.getDaysOfMonth();
  }

  getDaysOfMonth() {
    this.daysInMonth = [];
    this.blankDays = [];

    const daysInThisMonth = new Date(this.year, this.month + 1, 0).getDate();
    const firstDayThisMonth = new Date(this.year, this.month, 1).getDay();
    const prevMonthDays = new Date(this.year, this.month, 0).getDate();

    for (let i = 0; i < firstDayThisMonth; i++) {
      this.blankDays.push(prevMonthDays - i);
    }
    this.blankDays.reverse();

    for (let i = 0; i < daysInThisMonth; i++) {
      this.daysInMonth.push(i < 9 ? "0" + (i + 1) : i + 1);
    }
  }
  convertTo12HourFormat(time: string): string {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const hoursIn12HourFormat = hours % 12 || 12;
    return `${hoursIn12HourFormat}:${minutes} ${period}`;
  }

  async verifyAccount() {
    if (this.user.isVerified != 0) {
      return;
    }
    await this.matDialog.open(VerifyAccountComponent, {
      data: {
        user: this.user,
      },
      width: "500px",
    });
  }
}
