import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "../../../shared/services/auth.service";

const BASE_URL = environment.baseUrl;

@Injectable({
  providedIn: "root",
})
export class SignUpService {
  public authService: AuthService;
  constructor(private http: HttpClient) {}

  ngOnInit() {}
}
