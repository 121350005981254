import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { ProductsComponent } from "./products.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";

const routes = [
  {
    path: "products",
    component: ProductsComponent,
  },
];

@NgModule({
  declarations: [ProductsComponent],
  imports: [RouterModule.forChild(routes), FuseSharedModule, MatTabsModule],
})
export class ProductsModule {}
