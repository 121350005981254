<div class="main pt-2">
  <div class="analytics">
    <div class="container-fluid">
      <h2>Analytics</h2>
      <div class="nav-row">
        <h3>Money Earned :</h3>
        <div class="links">
          <a routerLink="/analytics">Profile</a>
          <a class="active">Students</a>
        </div>
      </div>
      <div class="analytics-graph-box">
        <div class="box-head">
          <span class="heading"> Rates </span>
        </div>
        <div class="box-body">
          <div class="graph-area">
            <canvas id="myChart" width="700" height="250"></canvas>
          </div>
        </div>
      </div>

      <div class="sold">
        <div class="head">
          <h3>Rates Subscribed :</h3>
          <h3>Students | <span>0</span></h3>
        </div>
      </div>

      <div class="divider"></div>

      <div class="analytics-graph-box">
        <div class="box-head">
          <span class="heading"> Games </span>
        </div>
        <div class="box-body">
          <div class="graph-area">
            <canvas id="myChart" width="700" height="250"></canvas>
          </div>
        </div>
      </div>

      <div class="sold">
        <div class="head">
          <h3>Game Subscribed :</h3>
          <h3>Students | <span>0</span></h3>
        </div>
      </div>
    </div>
  </div>
</div>
