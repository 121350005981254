import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent {
  isOver18: string = '';
  documentType: string[] = ['ID card', 'Passport', 'Driving License'];
  selectedDocumentType: string;
  fileToUploadUser: File = null;
  fileToUploadParent: File = null;  
  isParentDocument: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<VerifyAccountComponent>,
    private storage: AngularFireStorage,
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  onFileChange(event, uploaderType: 'user' | 'parent') {
    if (uploaderType === 'user') {
      this.fileToUploadUser = event.target.files[0];
    } else {
      this.fileToUploadParent = event.target.files[0];
    }
  
  }
  
  uploadFile() {
    // Common file path prefix
    const basePath = `/user_documents/${this.data.user.uid}`;

    // Handle user's document upload
    const filePathUser = `${basePath}/user_document`;
    const fileRefUser = this.storage.ref(filePathUser);
    const taskUser = this.storage.upload(filePathUser, this.fileToUploadUser);

    const uploadTasks = [taskUser];  // Array of upload tasks, starting with the user's document
    let fileRefParent: AngularFireStorageReference;
    // If the user is under 18, handle the parent's document upload as well
    if (this.isOver18 === 'no') {
        const filePathParent = `${basePath}/parent_document`;
        fileRefParent = this.storage.ref(filePathParent);
        const taskParent = this.storage.upload(filePathParent, this.fileToUploadParent);
        uploadTasks.push(taskParent);
    }

    // Use Promise.all to ensure that all uploads have completed
    Promise.all(uploadTasks).then(() => {
        // Retrieve the download URL for user's document
        fileRefUser.getDownloadURL().subscribe(userUrl => {
            let updateData = {
                documentUrl: userUrl,
                documentType: this.selectedDocumentType,
                isParentDocument: this.isOver18 === 'no',
                isVerified: 1
            };

            // If under 18, retrieve the download URL for parent's document
            if (this.isOver18 === 'no') {
                fileRefParent.getDownloadURL().subscribe(parentUrl => {
                    updateData['parentDocumentUrl'] = parentUrl;
                    this.updateFirestore(updateData);
                });
            } else {
                this.updateFirestore(updateData);
            }
        });
    }).catch(e => {
        this.toastr.error('Error uploading document(s)');
    });
}

// Extracted firestore update for cleanliness
updateFirestore(data) {
    this.firestore
        .collection('users')
        .doc(this.data.user.uid)
        .update(data)
        .then(_ => {
            this.toastr.success('Document(s) uploaded successfully');
            this.dialogRef.close();
        })
        .catch(e => {
            this.toastr.error('Error updating document information');
        });
}


  closeDialog() {
    this.dialogRef.close();
  }
}
