<div id="auth" class="auth gaming-curriculm">
  <div class="container-fluid">
    <img
      class="sweech-logo"
      [ngStyle]="{ position: isMobile ? 'unset' : 'absolute' }"
      src="assets/images/sweech-logo.png"
      alt=""
    />
    <div class="row">
      <div class="col-md-12">
        <div class="auth-box" [ngClass]="{ 'auth-mobile': isMobile }">
          <h1>{{ "Gaming Curriculm" | translate }}</h1>
          <form [formGroup]="curriculumForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label class="font-weight-bold font-size-2" style="font-size: 24px;">{{ "Teaching Program" | translate }} :</label>
              <p class="font-italic">
                {{"Teaching Program Hint" | translate}}
              </p>
              <textarea rows="8" formControlName="teachingProgram" class="form-control" id="" [placeholder]="placeholders.teachingProgram" autocomplete="off"></textarea>
              <!-- Error handling for required -->
              <div *ngIf="curriculumForm.controls['teachingProgram'].errors?.required && curriculumForm.controls['teachingProgram'].touched" class="alert alert-danger mt-1">
                {{ "Teaching Program field is required." | translate }}
              </div>
            </div>
            <div class="form-group">
              <label class="font-weight-bold font-size-2" style="font-size: 24px;">{{ "Teaching Method" | translate }} :</label>
              <p class="font-italic">
                {{"Teaching Method Hint" | translate}}
              </p>
              <textarea  rows="8"formControlName="teachingMethod" class="form-control" id=""[placeholder]="placeholders.teachingMethod" autocomplete="off"></textarea>
              <!-- Error handling for required -->
              <div *ngIf="curriculumForm.controls['teachingMethod'].errors?.required && curriculumForm.controls['teachingMethod'].touched" class="alert alert-danger mt-1">
                {{ "Teaching Method field is required." | translate }}
              </div>
            </div>
            <div class="form-group">
              <label class="font-weight-bold font-size-2" style="font-size: 24px;">{{ "Exercises Examples" | translate }} :</label>
              <p class="font-italic">
                {{"Exercise Examples Hint" | translate}}
              </p>
              <textarea  rows="8" formControlName="exerciseExample" class="form-control" id=""[placeholder]="placeholders.exerciseExample" autocomplete="off"></textarea>
              <!-- Error handling for required -->
              <div *ngIf="curriculumForm.controls['exerciseExample'].errors?.required && curriculumForm.controls['exerciseExample'].touched" class="alert alert-danger mt-1">
                {{ "Exercise Examples field is required." | translate }}
              </div>
            </div>
            <div class="form-group">
              <label class="font-weight-bold">{{ "Awards/Special Notes" | translate }} :</label>
              <input formControlName="awardsSpecialNotes" type="text" class="form-control" id="" placeholder="" autocomplete="off" style="margin-bottom: 0;" />
              <!-- Error handling for required -->
              <div *ngIf="curriculumForm.controls['awardsSpecialNotes'].errors?.required && curriculumForm.controls['awardsSpecialNotes'].touched" class="alert alert-danger mt-1">
                {{ "Awards/Special Notes field is required." | translate }}
              </div>
            </div>
            <div class="form-group">
              <label class="font-weight-bold">{{ "Twitch Channel" | translate }} :</label>
              <input formControlName="twitchChannel" type="text" class="form-control" id="" placeholder="" style="margin-bottom: 0;" pattern="^((https?:\/\/)?(www\.)?twitch\.tv\/.*|twitch\.tv\/.*|(https?:\/\/)?(www\.)?twitch\.com\/.*|twitch\.com\/.*|(https?:\/\/)?(m\.)?twitch\.tv\/.*|m\.twitch\.tv\/.*)" />
              <!-- Error handling for pattern -->
              <div *ngIf="curriculumForm.controls['twitchChannel'].errors?.pattern && curriculumForm.controls['twitchChannel'].touched" class="alert alert-danger mt-1">
                {{ "Please enter a valid twitch link" | translate }}
              </div>
              <!-- Error handling for required -->
              <div *ngIf="curriculumForm.controls['twitchChannel'].errors?.required && curriculumForm.controls['twitchChannel'].touched" class="alert alert-danger mt-1">
                {{ "Twitch Channel field is required." | translate }}
              </div>
            </div>
            <p>
              *{{
                "You also need to have at least 1 hour of continuous gameplay on your Twitch channel, to be evaluated. Without this video your request will be rejected"
                  | translate
              }}
            </p>
            <button type="submit" class="btn btn-primary" [disabled]="loading" style="margin-bottom: 3rem">
              {{ "Submit Request" | translate }}
            </button>
          </form>
          
        </div>
      </div>
      
    </div>
  </div>
</div>
