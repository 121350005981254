<div class="main pt-2">
  <div class="analytics">
    <div class="container-fluid">
      <h2>Analytics</h2>
      <div class="nav-row">
        <h3>Audience :</h3>
        <div class="links">
          <a class="active">Profile</a>
          <a routerLink="/student-analytics">Students</a>
        </div>
      </div>
      <div class="analytics-graph-box">
        <div class="box-head">
          <span class="heading"> Impressions </span>
          <select class="form-control" id="">
            <option>Last 30 days</option>
            <option>Last 15 days</option>
            <option>Last 7 days</option>
          </select>
        </div>
        <div class="box-body">
          <div class="graph-area">
            <canvas id="myChart" width="700" height="250"></canvas>
          </div>
        </div>

        <div class="sold">
          <div class="head">
            <h3>Mostly Sold :</h3>
            <h3>Audience | <span>0</span></h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
