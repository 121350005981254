import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Chart, Colors } from "chart.js";
declare var $: any;

@Component({
  selector: "analytics",
  templateUrl: "./analytics.component.html",
  styleUrls: ["./analytics.component.scss"],
})
export class AnalyticsComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;

  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {
    const canvas = document.getElementById("myChart") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    const chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Impressions",
            data: [0, 0, 0, 0, 0, 0, 0],
            backgroundColor: "#FFFFFF",
            borderColor: "#FFFFFF",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Earnings",
            },
            ticks: {
              color: "#FFFFFF",
            },
          },
          x: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Month",
              color: "#FFFFFF",
            },
            ticks: {
              color: "#FFFFFF",
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "#FFFFFF",
            },
          },
        },
      },
    });
  }
}
