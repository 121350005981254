import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { AuthorizationService } from "../authorization.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [AuthorizationService],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: UntypedFormGroup;
  routeParams: any;
  entityNode = "resetPassword";
  authid: string;
  pageType: String;
  pageMessage: String;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthorizationService,
    private _matSnackBar: MatSnackBar,
    private _route: ActivatedRoute,
    private router: Router
  ) {
    this.authid = this._route.snapshot.paramMap.get("id");
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {}
}
