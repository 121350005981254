
<div mat-dialog-content>
  <div class="add-products-popup">
    <div class="add-products-dialog">
     
      <app-stripe-int></app-stripe-int>
  
      <!-- <h3>Choose Payment Method</h3>
      <div class="select-payment-card">
        <button class="btn" (click)="cardDetail()">
          <img src="assets/images/gpay-button.png" alt="" />
        </button>
        <button class="btn" (click)="cardDetail()">
          <img src="assets/images/paypal-button.png" alt="" />
        </button>
        <button class="btn" (click)="cardDetail()">
          <img src="assets/images/applepay.jpg" alt="" />
        </button>
      </div> -->
    </div>
  </div>
  
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close="true">Close</button>
</div>
