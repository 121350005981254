import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { ManageRatesComponent } from "./manage-rates.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { ManageRatesPrivateComponent } from "./manage-rates-private/manage-rates-private.component";
import { RatesEditComponent } from "./rates-edit/rates-edit.component";
import { privateRatesEditComponent } from "./private-rates-edit/private-rates-edit.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { RateDiscountComponent, UpdateDiscountDialogComponent } from './rate-discount/rate-discount.component';
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import {MatTooltipModule} from '@angular/material/tooltip';
import { SharedAppModule } from "app/shared/shared-app.module";
import { AppAuthGuard } from "app/app.authguard";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const routes: Routes = [
  {
    path: "manage-rates",
    component: ManageRatesComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: "manage-rates-private",
    component: ManageRatesPrivateComponent,
    canActivate: [AppAuthGuard]

  },
  {
    path: "rates-edit",
    component: RatesEditComponent,
    canActivate: [AppAuthGuard]

  },
  {
    path: "rates-edit/:userId/:rateId",
    component: RatesEditComponent,
    canActivate: [AppAuthGuard]

  },
  {
    path: "private-rates-edit",
    component: privateRatesEditComponent,
    canActivate: [AppAuthGuard]

  },
];

@NgModule({
  declarations: [
    ManageRatesComponent,
    ManageRatesPrivateComponent,
    RatesEditComponent,
    privateRatesEditComponent,
    RateDiscountComponent,
    UpdateDiscountDialogComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatTabsModule,
    TranslateModule,
    MatDialogModule, 
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    SharedAppModule,
    MatSlideToggleModule
  ],
})
export class ManageRatesModule {}
