import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Chart, Colors } from "chart.js";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

declare var $: any;

interface User {
  aboutme: string;
  profilePic: string;
  name: string;
  country: string;
  languages: string[];
  gameIds: string[];
  registerDateTS: Date;
}

@Component({
  selector: "earnings",
  templateUrl: "./earnings.component.html",
  styleUrls: ["./earnings.component.scss"],
})
export class EarningsComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  profilePicture$: Observable<string>;
  user: any;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) {
    this.afAuth.authState.subscribe((user) => {
      this.user = user;
      if (user) {
        const usersRef = this.afs.collection<User>("users");
        this.profilePicture$ = usersRef
          .doc(user.uid)
          .get()
          .pipe(map((doc) => doc.data().profilePic));
      }
    });
  }

  ngOnInit(): void {
    const canvas = document.getElementById("myChart") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    const chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Earnings",
            data: [0, 0, 0, 0, 0, 0, 0],
            backgroundColor: "#FFFFFF",
            borderColor: "#FFFFFF",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Earnings",
            },
            ticks: {
              color: "#FFFFFF",
            },
          },
          x: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Month",
              color: "#FFFFFF",
            },
            ticks: {
              color: "#FFFFFF",
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "#FFFFFF",
            },
          },
        },
      },
    });
  }
}
