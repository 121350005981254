import { AppNavigation } from "@fuse/types";

export const navigation: AppNavigation[] = [
  {
    id: "applications",
    title: "Applications",
    type: "group",
    children: [
      {
        id: "sample",
        title: "Sample",
        type: "item",
        icon: "email",
        url: "/dashboard",
        badge: {
          title: "25",
          bg: "#F44336",
          fg: "#FFFFFF",
        },
      },
    ],
  },
];
