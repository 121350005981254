import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { FirestoreService } from "app/shared/services/firestore.service";
import * as firebase from "firebase/compat/app";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-phone-auth",
  templateUrl: "./phone-auth.component.html",
  styleUrls: ["./phone-auth.component.scss"],
})
export class PhoneAuthComponent implements OnInit {
  verificationId: string;
  defaultCountry: string = "";
  isLoading: boolean = false;
  verificationForm: FormGroup;
  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private _fuseConfigService: FuseConfigService,
    private firestore: FirestoreService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  ngOnInit() {
    this.http
      .get("https://ipinfo.io?token=c33841b0e98713")
      .subscribe((resp: any) => {
        if (resp) {
          this.defaultCountry = resp.country.toLowerCase();
          console.log(resp);
        } else {
          this.defaultCountry = 'it'
        }
      });
    this.verificationForm = this.formBuilder.group({
      phone: ["", Validators.required],
      code: [""],
    });
  }

  get phone() {
    return this.verificationForm.get("phone");
  }

  get code() {
    return this.verificationForm.get("code");
  }
  async sendVerificationCode() {
    console.log(this.phone);
    this.isLoading = true;
    try {
      const appVerifier = new firebase.default.auth.RecaptchaVerifier(
        "recaptcha-container"
      );
      this.verificationId = (
        await this.afAuth.signInWithPhoneNumber(
          this.phone.value.e164Number,
          appVerifier
        )
      ).verificationId;
      this.isLoading = false;
    } catch (e) {
      console.log(e);
    }
  }

  async verifyPhoneNumber() {
    try {
      this.isLoading = true;
      const credential = firebase.default.auth.PhoneAuthProvider.credential(
        this.verificationId,
        this.code.value
      );
      // Link phone number to the current user
      let usr = await this.afAuth.currentUser
        .then((user) => user.linkWithCredential(credential))
        .catch((error) => {
          // Handle Errors here.
          return Promise.reject(error);
        });
      console.log("Phone number is verified.");
      this.isLoading = false;
      await this.firestore.update(`users/${usr.user.uid}`, {
        step: 1,
        phone: this.phone.value.e164Number,
      });
      this.router.navigate(["signup-detail"]);
    } catch (e) {
      this.toastr.error(this.translate.instant("This phone number already exists."));
    }
    
  }
}
