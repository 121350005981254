<div class="main">
  <div class="private-class-detail">
    <div class="container-fluid">
      <div class="class-name-etc">
        <span class="class-name">Alex Housten</span>
        <span class="tips">Valorant Rank up Tips</span>
        <span class="remain">Remaining Lessons : 4</span>
      </div>
      <div class="class-cover">
        <img src="assets/images/manager/cover.png" alt="" />
      </div>
      <div class="tabs-area">
        <div class="nav-action-btns">
          <button class="small-btn">
            <i class="fa fa-exclamation-triangle"></i>
          </button>
          <button
            *ngIf="groupBtn"
            class="btn btn-primary"
            routerLink="/group-class-edit"
          >
            Add a Lesson
          </button>
        </div>
        <mat-tab-group
          animationDuration="0ms"
          (selectedTabChange)="TabChanged($event)"
        >
          <mat-tab label="Calendar">
            <div class="calendar-box">
              <div class="row">
                <div class="col-md-6">
                  <div class="d-flex align-items-center">
                    <select name="" id="" class="form-control mr-4">
                      <option value="">Aug, 2022</option>
                      <option value="">Sep, 2022</option>
                      <option value="">Oct, 2022</option>
                    </select>
                    <select name="" id="" class="form-control">
                      <option value="">Week 2</option>
                      <option value="">Week 3</option>
                      <option value="">Week 4</option>
                    </select>
                  </div>
                  <div class="events">
                    <div class="event-time">
                      <ul class="time">
                        <li>12:00 am</li>
                        <li>01:00 pm</li>
                        <li>02:00 pm</li>
                        <li>03:00 pm</li>
                        <li>04:00 pm</li>
                        <li>05:00 pm</li>
                        <li>06:00 pm</li>
                      </ul>
                    </div>
                    <div class="event-list">
                      <div class="box">
                        <img src="assets/images/event-small.png" alt="" />
                        <div>
                          <span class="title">LESSON BOOKED WITH LUCAS </span>
                        </div>
                      </div>
                      <div class="box">
                        <img src="assets/images/event-small.png" alt="" />
                        <div>
                          <span class="title">LESSON BOOKED WITH LUCAS </span>
                        </div>
                      </div>
                      <div class="box">
                        <img src="assets/images/event-small.png" alt="" />
                        <div>
                          <span class="title">LESSON BOOKED WITH LUCAS </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <select name="" id="" class="form-control">
                    <option value="">Aug, 2022</option>
                    <option value="">Sep, 2022</option>
                    <option value="">Oct, 2022</option>
                  </select>
                  <div class="select-date">
                    <ul class="weekdays">
                      <li><span class="active">Mon</span></li>
                      <li>Tue</li>
                      <li>Wed</li>
                      <li>Thu</li>
                      <li>Fri</li>
                      <li>Sat</li>
                      <li>Sun</li>
                    </ul>
                    <ul class="days">
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                      <li>4</li>
                      <li>5</li>
                      <li>6</li>
                      <li>7</li>
                      <li><span class="active">8</span></li>
                      <li>9</li>
                      <li>10</li>
                      <li>11</li>
                      <li>12</li>
                      <li>13</li>
                      <li>14</li>
                      <li>15</li>
                      <li>16</li>
                      <li>17</li>
                      <li>18</li>
                      <li>19</li>
                      <li>20</li>
                      <li>21</li>
                      <li>22</li>
                      <li>23</li>
                      <li>24</li>
                      <li>25</li>
                      <li>26</li>
                      <li>27</li>
                      <li>28</li>
                      <li>29</li>
                      <li>30</li>
                      <li>31</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Register-valorant">
            <ng-template mat-tab-label>
              Register<br /><span>(Valorant)</span>
            </ng-template>
            <div class="lesson-list">
              <a class="box" routerLink="/group-class-edit">
                <div class="row">
                  <div class="col-md-4">
                    <h5>Game Name:</h5>
                    <input type="text" class="form-control" value="Valorant" />
                    <h5>Class Topic (Subject):</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Valorant Rankup Tips & Tricks"
                    />
                    <h5>Date:</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Jul 22, 2022"
                    />
                  </div>
                  <div class="col-md-6 offset-md-2">
                    <h5>Class Activities:</h5>
                    <textarea class="form-control">
In Today’s class we will discuss about how to improve your aim in valorant by looking at the most common mistakes players make how to improve your aim how to improve your aim how to improve your aim </textarea
                    >
                  </div>
                </div>
              </a>
              <a class="box" routerLink="/group-class-edit">
                <div class="row">
                  <div class="col-md-4">
                    <h5>Game Name:</h5>
                    <input type="text" class="form-control" value="Valorant" />
                    <h5>Class Topic (Subject):</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Valorant Rankup Tips & Tricks"
                    />
                    <h5>Date:</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Jul 22, 2022"
                    />
                  </div>
                  <div class="col-md-6 offset-md-2">
                    <h5>Class Activities:</h5>
                    <textarea class="form-control">
In Today’s class we will discuss about how to improve your aim in valorant by looking at the most common mistakes players make how to improve your aim how to improve your aim how to improve your aim </textarea
                    >
                  </div>
                </div>
              </a>
            </div>
          </mat-tab>
          <mat-tab label="Register-fortnite">
            <ng-template mat-tab-label>
              Register<br /><span>(fortnite)</span>
            </ng-template>
            <div class="lesson-list">
              <a class="box" routerLink="/group-class-edit">
                <div class="row">
                  <div class="col-md-4">
                    <h5>Game Name:</h5>
                    <input type="text" class="form-control" value="Fornite" />
                    <h5>Class Topic (Subject):</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Fornite Rankup Tips & Tricks"
                    />
                    <h5>Date:</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Jul 22, 2022"
                    />
                  </div>
                  <div class="col-md-6 offset-md-2">
                    <h5>Class Activities:</h5>
                    <textarea class="form-control">
In Today’s class we will discuss about how to improve your aim in valorant by looking at the most common mistakes players make how to improve your aim how to improve your aim how to improve your aim </textarea
                    >
                  </div>
                </div>
              </a>
              <a class="box" routerLink="/group-class-edit">
                <div class="row">
                  <div class="col-md-4">
                    <h5>Game Name:</h5>
                    <input type="text" class="form-control" value="Fornite" />
                    <h5>Class Topic (Subject):</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Fornite Rankup Tips & Tricks"
                    />
                    <h5>Date:</h5>
                    <input
                      type="text"
                      class="form-control"
                      value="Jul 22, 2022"
                    />
                  </div>
                  <div class="col-md-6 offset-md-2">
                    <h5>Class Activities:</h5>
                    <textarea class="form-control">
In Today’s class we will discuss about how to improve your aim in valorant by looking at the most common mistakes players make how to improve your aim how to improve your aim how to improve your aim </textarea
                    >
                  </div>
                </div>
              </a>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
