import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-connect-stripe",
  templateUrl: "./connect-stripe.component.html",
  styleUrls: ["./connect-stripe.component.scss"],
})
export class ConnectStripeComponent {

  isMobile: boolean;
  private _unsubscribeAll: Subject<any> = new Subject();
  code;

  private observeBreakpoints(): void {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((o) => {
        this.isMobile = o.matches;
        this.updateLayoutForMobile(this.isMobile);
      });
  }

  private updateLayoutForMobile(isMobile: boolean): void {
    this._fuseConfigService.config = {
      layout: {
        navbar: { hidden: isMobile },
        toolbar: { hidden: isMobile },
        footer: { hidden: isMobile },
        sidepanel: { hidden: isMobile },
      },
    };
  }

  constructor(
    private http: HttpClient,
    private router: Router, 
    private fAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
    private toastr: ToastrService
  ) {
    this.observeBreakpoints();
  }

  ngOnInit() {
    this.fAuth.onAuthStateChanged((user) => {
      if (user) {
        this.route.queryParams.subscribe((params) => {
          const code = params["code"];
          if (code) {
            this.code = code;
            this.handleStripeRedirect(code, user.uid);
          }
        });
        console.log("User is signed in");
      } else {
        console.log("No user is signed in");
      }
    });
  }

  handleStripeRedirect(code: string, uid: string) {
    this.http
      .post(
        "https://us-central1-sweech-3ec98.cloudfunctions.net/connectStripeAccount",
        { code, masterID: uid }
      )
      .subscribe(
        (response) => {
          console.log("Stripe connected", response);
          // Redirect the user or show a message based on the response
          this.toastr.success("Stripe account connected successfully");
        },
        (error) => {
          console.error("Error connecting Stripe account:", error);
          // Handle error, show a message to the user
          this.toastr.error("Error connecting Stripe account");
        }
      );
  }

  goToClass() {
    if (this.code) {
      this.router.navigate(["/manager"]);

    } else {
      this.router.navigate(["/settings"]);
    }
  }
}
