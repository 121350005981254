import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AuthService } from "../../../app/shared/services/auth.service";

declare var $: any;

@Component({
  selector: "achievements",
  templateUrl: "./achievements.component.html",
  styleUrls: ["./achievements.component.scss"],
})
export class AchievementsComponent implements OnInit {
  content: string =
    "Finish 1 course of at least 1 month with at least 4 members"; // content of the achievement
  completed: number = 5; // number of completed tasks
  total: number = 10; // total number of tasks
  progress: number = 0;

  users: any;
  isLoggedin: Boolean = false;
  btnDisabled: boolean = false;
  classes: any[] = [];
  classesachivement5: any[] = [];
  classesachivement6: any[] = []; // variable to hold the classes fetched from Firestore
  selectedClass: any;
  selectedClassesfilter: any;
  progress2: number = 0;
  filterclasses: any[] = [];
  progressfilter: number = 0;
  progressfilter2: number = 0;
  progressfilterfilter: number = 0;
  progressfilterfilter2: number = 0;
  progressBarWidth: number = 0;
  acheivement4width: number = 0;
  acheivement4width2: number = 0;
  acheivement5width: number = 0;
  acheivement5width2: number = 0;
  acheivement6width: number = 0;
  acheivement6width2: number = 0;

  constructor(
    private authService: AuthService,
    private afs: AngularFirestore,

    private afAuth: AngularFireAuth
  ) {}

  ngOnInit() {
    // Get the user ID of the currently logged in user
    this.afAuth.authState.subscribe((user) => {
      const loggedInUserId = user?.uid;
      // Retrieve all classes with the teacher ID equal to the logged in user ID
      this.afs
        .collection("classes", (ref) =>
          ref.where("teacherId", "==", loggedInUserId)
        )
        .valueChanges()
        .subscribe((classes) => {
          // Store the retrieved classes in a local variable
          this.classes = classes;
          // Filter the classes to only include those with at least 4 students
          this.classes.filter(
            (classObj: any) =>
              classObj.teacherid === loggedInUserId &&
              classObj.studentids.length >= 4
          );

          for (let i = 0; i < this.classes.length; i += 3) {
            const group = this.classes.slice(i, i + 3);
            this.selectedClassesfilter.push(group);
          }
          const filteredClasses = classes.filter(
            (classObj: any) => classObj.studentids.length >= 4
          );
          this.classesachivement5 = classes.filter(
            (classObj: any) => classObj.studentids.length >= 7
          );
          this.classesachivement6 = classes.filter(
            (classObj: any) => classObj.studentids.length >= 7
          );
          const numFilteredClasses = filteredClasses.length;
          // Find the class with the maximum number of students
          this.selectedClass = this.classes.reduce((prev, current) => {
            return prev.studentids.length > current.studentids.length
              ? prev
              : current;
          });

          // Set the width of the green bar based on the number of students in the selected class
          const numStudents = this.selectedClass.studentids.length;
          let barWidth = 0;
          if (numStudents === 1) {
            this.acheivement4width = 14.28;
            barWidth = 25;
          } else if (numStudents === 2) {
            barWidth = 50;
            this.acheivement4width = 28.56;
          } else if (numStudents === 3) {
            barWidth = 75;
            this.acheivement4width = 42.84;
          } else if (numStudents === 4) {
            barWidth = 100;
            this.acheivement4width = 57;
          } else if (numStudents === 5) {
            this.acheivement4width = 71;
          } else if (numStudents === 6) {
            this.acheivement4width = 85;
          } else if (numStudents === 7) {
            this.acheivement4width = 100;
          }

          const numClasses = this.selectedClassesfilter.length;
          let barWidthfilter = 0;
          if (numClasses === 1) {
            barWidthfilter = 33;
          } else if (numClasses === 2) {
            barWidthfilter = 66;
          } else if (numClasses === 3) {
            barWidthfilter = 100;
          }

          if (numFilteredClasses === 1) {
            this.progressBarWidth = 20;
          } else if (numFilteredClasses === 2) {
            this.progressBarWidth = 40;
          } else if (numFilteredClasses === 3) {
            this.progressBarWidth = 60;
          } else if (numFilteredClasses === 4) {
            this.progressBarWidth = 80;
          } else if (numFilteredClasses === 5) {
            this.progressBarWidth = 100;
          }

          const classachivement5 = this.classesachivement5.length;
          if (classachivement5 === 1) {
            this.acheivement5width = 33;
          } else if (numClasses === 2) {
            this.acheivement5width = 66;
          } else if (numClasses >= 3) {
            this.acheivement5width = 100;
          }

          const classachivement6 = this.classesachivement6.length;
          if (numClasses === 1) {
            this.acheivement6width = 20;
          } else if (numClasses === 2) {
            this.acheivement6width = 40;
          } else if (numClasses === 3) {
            this.acheivement6width = 60;
          } else if (numClasses === 4) {
            this.acheivement6width = 80;
          } else if (numClasses === 5) {
            this.acheivement6width = 100;
          } else {
            this.acheivement6width = 0;
          }

          this.completed = numStudents;
          this.progress = barWidth;
          this.progress2 = (this.progress / 100) * 10;
          this.progressfilter = barWidthfilter;
          this.progressfilter2 = (this.progressfilter / 100) * 10;
          this.progressfilterfilter = this.progressBarWidth;
          this.progressfilterfilter2 = (this.progressBarWidth / 100) * 10;
          this.acheivement4width2 = (this.acheivement4width / 100) * 10;
          this.acheivement5width2 = (this.acheivement5width / 100) * 10;
          this.acheivement6width2 = (this.acheivement6width / 100) * 10;
          // Update the style of the green bar
        });
    });
  }
}
