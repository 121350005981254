import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-discord-callback',
  templateUrl: './discord-callback.component.html',
  styleUrls: ['./discord-callback.component.scss']
})
export class DiscordCallbackComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit(): void {
    // Check for the code parameter in the URL
    this.route.queryParams.subscribe(params => {
      const code = params['code'];
      if (code) {
        // If code exists, call the Firebase Cloud Function
        this.callCloudFunction(code);
      }
    });
  }

  callCloudFunction(code: string): void {
    // Replace with your Firebase Cloud Function URL
    const firebaseFunctionUrl = 'https://us-central1-sweech-3ec98.cloudfunctions.net/discordAuth';

    this.http.get(firebaseFunctionUrl, { params: { code: code } }).subscribe(response => {
      // Handle the response from the Firebase function here
      console.log(response);
      this.router.navigateByUrl('/settings');
    }, error => {
      console.error('Error calling Firebase Cloud Function:', error);
    });
  }
}
