<div class="main pt-2">
  <div class="manage-rates">
    <div class="container-fluid">
      <h2>Manage Rates</h2>
      <div class="nav-row">
        <div class="links">
          <a routerLink="/manage-rates">Group</a>
          <!-- <a class="active">Private</a> -->
        </div>
        <button class="btn btn-primary" routerLink="/private-rates-edit">
          <i class="fa fa-plus-circle"></i> Add New Rate
        </button>
      </div>

      <div class="list" *ngIf="rates.length > 0">
        <ul *ngFor="let rate of rates | async" routerLink="/rates-edit">
          <li class="green">{{ rate.title }}</li>
          <li>{{ rate.duration }}</li>
          <li>{{ rate.daysPerWeek }}</li>
          <li>{{ rate.hrsPerDay }}</li>
          <li>{{ rate.price }}</li>
          <li
            class="available"
            *ngIf="rate.discount.length != 0"
            else
            notavailable
          >
            Promo Code<br />Available
          </li>
        </ul>
        <div class="no-rates" *ngIf="rates.length === 0">
          No rates added yet.
        </div>
        <div class="not-available" *ngIf="rate.discount.length === 0">
          Promo Code<br />Available
        </div>
      </div>
    </div>
  </div>
</div>
