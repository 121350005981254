import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

const BASE_URL = environment.baseUrl;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private router: Router, private http: HttpClient) {}

  isUserLoggedIn(): boolean {
    let token = localStorage.getItem("access_token");
    if (token) {
      return true;
    } else {
      return false;
    }
  }
}
