import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatToolbarModule } from "@angular/material/toolbar";

import { FuseSearchBarModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";

import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,

    FuseSharedModule,
    FuseSearchBarModule,
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
