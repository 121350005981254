import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { environment } from "environments/environment";
import { AuthService } from "../../../shared/services/auth.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

const CLIENT_SECRET = environment.clientSecret;

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
  hide: boolean = false;
  email: string;
  password: string;
  loginForm: FormGroup;
  isMobile: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private _fuseConfigService: FuseConfigService,
    private breakpoint: BreakpointObserver,
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }
  Onsubmit(email: string, password: string) {
    if (email == null || email == undefined || email == "") {
      alert("Enter an Email and password");
    } else {
      this.authService.SignIn(email, password);
    }
  }
  onRememberMeChanged(event: any) {
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    const rememberMe = event.target.checked;

    if (rememberMe) {
      // Store the email and password in localStorage
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    } else {
      // Remove the email and password from localStorage
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
  }
  ngOnInit(): void {
    this.breakpoint
    .observe([Breakpoints.Handset, Breakpoints.Tablet])
    .subscribe((o) => {
      this.isMobile = o.matches;  
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: true,
          },
          toolbar: {
            hidden: true,
          },
          footer: {
            hidden: true,
          },
          sidepanel: {
            hidden: true,
          },
        },
      };    
    });
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  toggleHiddenPassword(e) {
    e.stopPropagation();
    this.hide = !this.hide;
  }
}
