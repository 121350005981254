<app-navbar *ngIf="isMobile" [hideBackIcon]="true"></app-navbar>

<div class="main pt-2" *ngIf="userData" [ngClass]="{ mobile: isMobile }">
  <div class="profile">
    <div class="container-fluid">
      <h2>{{ "Profile" | translate }}</h2>
      <div class="profile-box">
        <ng-container #nocover>
          <ng-template #nocoverPic>
            <div class="cover">
              <button
                class="btn edit-btn"
                routerLink="/profile-edit"
                style="
                  background-color: black;
                  color: white;
                  display: inline-block;
                "
              >
                <i class="fa fa-pen"></i>
              </button>
              <button
                class="small-btn"
                (click)="shareLink()"
                style="margin-right: 0.5rem"
              >
                <i class="fa fa-share-alt"></i>
              </button>

              <div
                style="
                  border-radius: 10%;
                  width: 100%;
                  height: 349px;
                  background-color: #f0f0f0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <p style="font-size: 24px; font-weight: bold">
                  {{ "No Cover Photo" | translate }}
                </p>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <div
          class="cover"
          *ngIf="gamesData != null && gamesData.length > 0; else nocover"
        >
          <button class="btn edit-btn" routerLink="/profile-edit">
            <i class="fa fa-pen"></i>
          </button>
          <button
            class="small-btn btn edit-btn"
            (click)="shareLink()"
            style="margin-right: 2.5rem"
          >
            <i class="fa fa-share-alt"></i>
          </button>

          <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let gD of gamesData; let i = index">
              <ng-template carouselSlide [id]="i">
                <img
                  class="c-image"
                  *ngIf="gD.image; else nocoverPic"
                  src="{{ gD.image }}"
                  alt="Profile Picture"
                />
              </ng-template>
            </ng-container>
          </owl-carousel-o>
          <!-- <button class="btn edit-btn" routerLink="/profile-edit">
            <i class="fa fa-pen"></i>
          </button>

          <img
            *ngFor="let gD of gamesData; else nocoverPic"
            src="{{ gD.image }}"
            alt="Profile Picture"
            style="border-radius: 50%"
          />
          <ng-template #nocoverPic>
            <div class="cover">
              <button
                class="btn edit-btn"
                routerLink="/profile-edit"
                style="
                  background-color: black;
                  color: white;
                  display: inline-block;
                "
              >
                <i class="fa fa-pen"></i>
              </button>

              <div
                style="
                  border-radius: 10%;
                  width: 100%;
                  height: 349px;
                  background-color: #f0f0f0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <p style="font-size: 24px; font-weight: bold">No Cover Photo</p>
              </div>
            </div>
          </ng-template> -->
        </div>
        <div class="profile-body">
          <div class="info">
            <div class="dp-name">
              <div
                [ngClass]="{
                  'd-flex': isMobile,
                  'flex-column': isMobile,
                  'align-items-center': isMobile
                }"
              >
                <img
                  *ngIf="
                    profilePicture$ | async as profilePicture;
                    else noProfilePicture
                  "
                  src="{{ profilePicture }}"
                  alt="Profile Picture"
                  style="border-radius: 50%"
                  class="mat-elevation-z8"
                />
                <ng-template #noProfilePicture>
                  <img
                    src="assets/images/dummy_user.png"
                    class="rounded-circle"
                    alt="Profile Picture"
                  />
                </ng-template>

                <h3>{{ name$ | async }}</h3>
                <span class="country">({{ country$ | async }})</span>
              </div>
              <div class="right-side">
                <div class="box">
                  <div class="text-row">
                    <span class="heading">{{ "Rating :" | translate }} </span>
                    <div class="stars">
                      <i
                        *ngFor="let i of [1, 2, 3, 4, 5]"
                        [ngClass]="i <= rating ? 'fa-star fas' : 'fa-star far'"
                      ></i>
                    </div>
                    <span class="green-text">{{ rating }}</span>
                  </div>
                  <div class="text-row">
                    <span class="heading">{{
                      "Registered :" | translate
                    }}</span>
                    <span>{{
                      (registerDateTS$ | async).toDate()
                        | date : "dd MMM, yyyy" : "" : "it-IT"
                    }}</span>
                  </div>

                  <div class="social">
                    <a
                      [href]="formatURL(userData?.facebookLink)"
                      *ngIf="userData?.facebookLink"
                      target="_blank"
                      ><img src="assets/images/profile/facebook.png" alt=""
                    /></a>
                    <a
                      [href]="formatURL(userData?.instagramLink)"
                      *ngIf="userData?.instagramLink"
                      target="_blank"
                      ><img src="assets/images/profile/instagram.png" alt=""
                    /></a>
                    <a
                      [href]="formatURL(userData?.discordChannel)"
                      *ngIf="userData?.discordChannel"
                      target="_blank"
                      ><img src="assets/images/profile/discord.png" alt=""
                    /></a>
                    <a
                      [href]="formatURL(userData?.twitchLink)"
                      *ngIf="userData?.twitchLink"
                      target="_blank"
                      ><img src="assets/images/profile/twitch.png" alt=""
                    /></a>
                    <a
                      [href]="formatURL(userData?.youtubeLink)"
                      *ngIf="userData?.youtubeLink"
                      target="_blank"
                    >
                      <i
                        class="fab fa-youtube"
                        style="font-size: 35px; color: red"
                      ></i>
                    </a>
                    <a
                      [href]="formatURL(userData?.twitterLink)"
                      *ngIf="userData?.twitterLink"
                      target="_blank"
                    >
                      <i
                        class="fab fa-twitter"
                        style="font-size: 35px; color: rgba(0, 213, 255, 0.92)"
                      ></i>
                    </a>
                    <a
                      [href]="formatURL(userData?.tiktokLink)"
                      *ngIf="userData?.tiktokLink"
                      target="_blank"
                    >
                      <img
                        src="assets/images/profile/tiktok.png"
                        width="35"
                        height="34"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="tabs-area">
              <mat-tab-group animationDuration="0ms">
                <mat-tab
                  [label]="'General' | translate"
                  [ngClass]="{ 'mat-mobile': isMobile }"
                >
                  <div class="about">
                    <h4>{{ "About me:" | translate }}</h4>
                    <p *ngIf="aboutMe$ | async as aboutMe">
                      {{
                        showAboutMe
                          ? aboutMe
                          : aboutMe.length > 300
                          ? aboutMe.substring(0, 300) + "..."
                          : aboutMe
                      }}
                      <span
                        *ngIf="aboutMe.length >= 300 && !showAboutMe"
                        (click)="toggleAboutMe()"
                        style="color: rgb(81, 81, 240); cursor: pointer"
                      >
                        {{ showAboutMe ? "Show Less" : "Show More" }}
                      </span>
                    </p>
                    <!-- <button
                      class="btn"
                      *ngIf="(aboutMe$ | async).length >= 300"
                      (click)="toggleAboutMe()"
                    >
                      {{ showAboutMe ? "Show Less" : "Show More" }}
                    </button> -->
                    <h4 *ngIf="!isRateClicked">{{ "Rates:" | translate }}</h4>
                    <ng-container *ngIf="rates?.length > 0; else loading">
                      <div class="row" *ngIf="!isRateClicked">
                        <div
                          class="col-md-4 col-xs-12 col-sm-6 col-lg-4"
                          *ngFor="let rate of rates"
                        >
                          <div class="box">
                            <h5 *ngIf="rate.discounts.length > 0">
                              €{{ getDiscountedPrice(rate) }}/{{
                                rate.duration.split(" ")[0] +
                                  " " +
                                  (rate.duration.split(" ")[1] | translate)
                              }}
                              <img src="assets/icons/icons/discount.png" />
                            </h5>
                            <h5
                              *ngIf="rate.discounts.length == 0"
                              style="height: 19px"
                            ></h5>
                            <h5 [class.crossed_out]="rate.discounts.length > 0">
                              €{{ rate.price }}/{{
                                rate.duration.split(" ")[0] +
                                  " " +
                                  (rate.duration.split(" ")[1] | translate)
                              }}
                            </h5>
                            <span class="day"
                              >{{ "Days per week:" | translate }}
                              <span class="green">{{
                                rate.daysPerWeek
                              }}</span></span
                            >
                            <span class="hours"
                              >{{ "Hours per day:" | translate }}
                              <span class="green">{{
                                rate.hrsPerDay
                              }}</span></span
                            >
                            <span class="games"
                              >{{ "Titles:" | translate }}
                              <span class="green">{{
                                gameNames(rate)
                              }}</span></span
                            >
                            <button
                              class="btn btn-primary"
                              (click)="chooseRateDialog(rate)"
                            >
                              {{ "Buy" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #loading>{{
                      "No rates added yet." | translate
                    }}</ng-template>
                  </div>
                  <div *ngIf="isRateClicked" class="deals">
                    <div class="haed-text">
                      <span class="heading">{{
                        "Choose your Class" | translate
                      }}</span>
                      <a (click)="showAbout()" class="green">
                        {{ "Go Back" | translate }}
                      </a>
                    </div>
                    <div class="deal-list">
                      <div
                        *ngFor="let cl of classesForSelectedRate"
                        disabled
                        class="box w-100"
                      >
                        <img class="main-img" src="{{ cl.image }}" alt />
                        <div class="text-area w-100">
                          <span
                            *ngIf="
                              selectedRate?.discounts.length > 0 ||
                              (cl.discounts && cl.discounts.length > 0)
                            "
                          >
                            <img
                              style="float: right"
                              src="assets/icons/icons/discount.png"
                            />
                          </span>
                          <span
                            style="float: right"
                            (click)="openInfoDialog(cl, $event)"
                          >
                            <mat-icon>info</mat-icon>
                          </span>
                          <span class="title">
                            {{ cl.title }}
                          </span>

                          <span class="date-time">
                            {{ scheduleDayClass(cl) }}
                          </span>
                          <span class="time overflow-ellipis">
                            {{ formatTime(cl.startTime) }} - {{ formatTime(cl.endTime) }}
                          </span>
                          <span class="text-green font-weight-bold">
                            {{ cl.classStudentsList.length }} /
                            {{ cl.maxNumberOfStudents }}
                            {{ "Students" | translate }}
                          </span>
                          <div class="start">
                            <img
                              *ngIf="cl.endDate.toDate() <= today"
                              src="assets/images/profile/ClassStarted.png"
                              alt
                            />
                            <img
                              *ngIf="cl.startDate.toDate() > today"
                              src="assets/images/profile/ClassYetToStart.png"
                              alt
                            />
                            <div
                              *ngIf="
                                cl.startDate.toDate() > today;
                                else classStarted
                              "
                            >
                              {{ "Class will start on " | translate }} - ({{
                                cl.startDate.toDate()
                                  | date : "dd MMM, yyyy" : "" : "it-IT"
                              }}) - ({{
                                cl.endDate.toDate()
                                  | date : "dd MMM, yyyy" : "" : "it-IT"
                              }})
                            </div>
                            <ng-template #classStarted>
                              {{ "Class Started" | translate }} - ({{
                                cl.startDate.toDate()
                                  | date : "dd MMM, yyyy" : "" : "it-IT"
                              }}) - ({{
                                cl.endDate.toDate()
                                  | date : "dd MMM, yyyy" : "" : "it-IT"
                              }})
                            </ng-template>
                          </div>

                          <div class="tags">
                            <span class="overflow-ellipis">
                              {{ cl.language }}
                            </span>
                            <span class="overflow-ellipis">
                              {{ cl.gamingPlatform }}
                            </span>
                            <span class="ove rflow-ellipis peripherals">
                              {{ cl.peripherals }}
                            </span>
                          </div>

                          <!-- <div class="">
                            <span>PC</span>
                            <span>Xbox</span>
                            <span>Playstation</span>
                            <span>Mouse</span>
                            <span>Keyboard</span>
                            <span>Headphones</span>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab [label]="'Information' | translate">
                  <div class="info-tab" *ngIf="languages$ | async as languages">
                    <h4>{{ "Languages:" | translate }}</h4>
                    <span class="lang" *ngFor="let language of languages">{{
                      language
                    }}</span>
                    <h4 class="mt-3">{{ "Games :" | translate }}</h4>
                    <ul class="tags">
                      <li *ngFor="let game of games$ | async">
                        {{ game.name }}
                      </li>
                    </ul>

                    <h4 class="mt-3 mb-4">
                      {{ "Recommended Products :" | translate }}
                    </h4>
                    <div class="product-area">
                      <div class="row">
                        <div
                          *ngFor="
                            let product of products$ | async;
                            let i = index
                          "
                          class="col-xl-3 col-md-4"
                        >
                          <a [href]="formatUrl(product.link)" target="_blank">
                            <mat-card>
                              <img
                                mat-card-image
                                [src]="product.image"
                                alt="Sweech Logo"
                                style="max-height: 15rem; height: 10rem"
                              />
                              <mat-card-content>
                                {{ product.name }}
                                <!-- {{ "Amazon Link " | translate }} # {{ i + 1 }} -->
                              </mat-card-content>
                            </mat-card>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab [label]="'Review' | translate">
                  <div class="rating-area">
                    <h4>{{ "Ratings & Reviews :" | translate }}</h4>
                    <div class="ratings-average">
                      <div class="rat-row">
                        <span>{{ "Ability in Game :" | translate }} </span>
                        <div class="stars">
                          <i
                            *ngFor="let i of [1, 2, 3, 4, 5]"
                            [ngClass]="
                              i <= userData?.gameAbility
                                ? 'fa-star fas'
                                : 'fa-star far'
                            "
                          ></i>
                        </div>
                      </div>
                      <div class="rat-row">
                        <span>{{ "Ability to Teach :" | translate }}</span>
                        <div class="stars">
                          <i
                            *ngFor="let i of [1, 2, 3, 4, 5]"
                            [ngClass]="
                              i <= userData?.teachAbility
                                ? 'fa-star fas'
                                : 'fa-star far'
                            "
                          ></i>
                        </div>
                      </div>
                      <div class="rat-row">
                        <span>{{ "Pleasantness :" | translate }}</span>
                        <div class="stars">
                          <i
                            *ngFor="let i of [1, 2, 3, 4, 5]"
                            [ngClass]="
                              i <= userData?.pleasantness
                                ? 'fa-star fas'
                                : 'fa-star far'
                            "
                          ></i>
                        </div>
                      </div>
                      <div class="rat-row">
                        <span>{{ "Quality/Price :" | translate }}</span>
                        <div class="stars">
                          <i
                            *ngFor="let i of [1, 2, 3, 4, 5]"
                            [ngClass]="
                              i <= userData?.qualityPerPrice
                                ? 'fa-star fas'
                                : 'fa-star far'
                            "
                          ></i>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="sort-row">
                      <h5>{{ "Sort By :" | translate }}</h5>
                      <button class="btn">{{ "Relevant" | translate }}</button>
                    </div> -->

                    <div class="full-reviews">
                      <div
                        *ngFor="let review of reviews$ | async; let i = index"
                        class="box"
                      >
                        <img src="{{ review.userImage }}" alt="" />
                        <div>
                          <div class="box-head">
                            <span>
                              <span class="name">{{ review.userName }}</span>
                              <div class="stars">
                                <i
                                  *ngFor="let i of [1, 2, 3, 4, 5]"
                                  [ngClass]="
                                    i <=
                                    (review.gameAbility +
                                      review.teachAbility +
                                      review.pleasantness +
                                      review.qualityPerPrice) /
                                      4
                                      ? 'fa-star fas'
                                      : 'fa-star far'
                                  "
                                ></i>
                              </div>
                            </span>
                            <span class="time">{{
                              review.date.toDate()
                                | date : "dd MMM, yyyy" : "" : "it-IT"
                            }}</span>
                          </div>
                          <div class="box-body">
                            <p>{{ review.review }}</p>
                            <div
                              *ngIf="review.reply"
                              class="p-4"
                              style="font-size: 13px"
                            >
                              {{ review.reply }}
                            </div>
                            <textarea
                              type="text"
                              class="form-control mb-2"
                              placeholder="Reply"
                              *ngIf="!review.reply"
                              [(ngModel)]="reviews[i]"
                              name="reply{{ i }}"
                              maxlength="250"
                            ></textarea>
                            <button
                              class="btn"
                              *ngIf="!review.reply"
                              (click)="replyToReview(review, reviews[i])"
                            >
                              {{ "Reply" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  style="
    width: 90vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  "
  *ngIf="!userData"
>
  <app-loader></app-loader>
</div>
