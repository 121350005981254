import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss']
})
export class BottomNavComponent {
  selectedIndex = 0;
  images = [
    { src: 'assets/icons/icons/home.svg', alt: 'Home' },
    { src: 'assets/icons/icons/inbox.svg', alt: 'Inbox' },
    { src: 'assets/icons/icons/manage_classes.svg', alt: 'Manage Classes' }
  ];

  /**
   *
   */
  constructor(private router: Router) {
  
    
  }

  changeTab(index: number) {
    this.selectedIndex = index;

    switch(index) {
      case 0: 
        this.router.navigate(['/dashboard']);
      break;
      case 1:
        this.router.navigate(['/messages']);
      break;
      case 2:
        this.router.navigate(['/manager']);
      break;
    }
  }
}
