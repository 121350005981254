<div class="main pt-3">
  <div class="private-rates-edit">
    <div class="container-fluid">
      <h2>Edit Rates</h2>
      <div class="form-area">
        <form [formGroup]="rateForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-4 offset-md-4">
              <div class="set-availability">
                <div class="form-group" (click)="calendar()">
                  <input
                    type="text"
                    class="form-control"
                    id=""
                    placeholder="Set Availability :"
                    autocomplete="off"
                  />
                  <img src="assets/images/caledar-icon.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-box">
            <div class="general">
              <h3>General : <i class="far fa-eye-slash"></i></h3>
              <div class="form-group">
                <label>Rate Title :</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder=""
                  autocomplete="off"
                  formControlName="title"
                />
              </div>
              <div class="form-group">
                <label>Duration <span>(Months)</span> :</label>
                <select
                  class="form-control"
                  id=""
                  formControlName="noOfLessons"
                >
                  <option value="1">1 Month</option>
                  <option value='2'>3 Month</option>
                  <option value="6">6 Month</option>
                  <option value="'12">1 Year</option>
                </select>
              </div>
              <div class="form-group">
                <label>Days per week :</label>
                <select
                  class="form-control"
                  id=""
                  formControlName="daysPerWeek"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                </select>
              </div>
              <div class="form-group">
                <label>Hours :</label>
                <select
                  class="form-control"
                  id=""
                  formControlName="hoursPerDay"
                >
                  <option value="1">1 Hours</option>
                  <option value="2">2 Hours</option>
                  <option value="3">3 Hours</option>
                  <option value="4">4 Hours</option>
                </select>
              </div>
              <div class="form-group">
                <label>Price :</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  pattern="[0-9]*"
                  placeholder=""
                  autocomplete="off"
                  formControlName="price"
                  style="
                    padding-right: 40px;
                    background-image: url('assets/images/dollar.png');
                    background-repeat: no-repeat;
                    background-position: right center;
                    background-size: 24px 24px;
                  "
                />
                <div
                  class="error-message"
                  *ngIf="
                    rateForm.get('price').errors &&
                    rateForm.get('price').errors.numeric
                  "
                >
                  Only numbers are allowed
                </div>
              </div>
            </div>
            <div class="earnings-preview">
              <h3>Earnings Preview :</h3>
              <div class="form-group">
                <label>Total Lessons :</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  disabled
                  placeholder=""
                  autocomplete="off"
                  formControlName="totalLessons"
                  pattern="[0-9]*"
                />
                <div
                  class="error-message"
                  *ngIf="
                    rateForm.get('price').errors &&
                    rateForm.get('price').errors.pattern
                  "
                >
                  Only numbers are allowed
                </div>
              </div>
              <div class="form-group">
                <label>Service Cost per Student :</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  disabled
                  placeholder=""
                  autocomplete="off"
                  formControlName="serviceCostPerStd"
                  pattern="[0-9]*"
                  style="
                    padding-right: 40px;
                    background-image: url('assets/images/dollar.png');
                    background-repeat: no-repeat;
                    background-position: right center;
                    background-size: 24px 24px;
                  "
                />
                <div
                  class="error-message"
                  *ngIf="
                    rateForm.get('serviceCostPerStd').errors &&
                    rateForm.get('serviceCostPerStd').errors.pattern
                  "
                >
                  Only numbers are allowed
                </div>
              </div>
              <div class="form-group">
                <label>Full Earning <span>(Class)</span> :</label>
                <input
                  type="text"
                  class="form-control"
                  id=""
                  disabled
                  placeholder=""
                  autocomplete="off"
                  formControlName="fullEarning"
                  pattern="[0-9]*"
                  style="
                    padding-right: 40px;
                    background-image: url('assets/images/dollar.png');
                    background-repeat: no-repeat;
                    background-position: right center;
                    background-size: 24px 24px;
                  "
                />
                <div
                  class="error-message"
                  *ngIf="
                    rateForm.get('fullEarning').errors &&
                    rateForm.get('fullEarning').errors.pattern
                  "
                >
                  Only numbers are allowed
                </div>
              </div>
            </div>
          </div>

          <h4 formControlName="description">Description :</h4>
          <textarea class="form-control"></textarea>

          <h4>Discounts :</h4>
          <div class="discount-box">
            <div class="list">
              <span class="text"
                ><u>25%</u> off for the first <u>15</u> students by<br />using
                this promo code
              </span>
              <input type="text" class="form-control" value="W2xyB" />
            </div>
            <div class="list">
              <span class="text"
                ><u>15%</u> off for the first <u>10</u> days of class<br />using
                this promo code
              </span>
              <input type="text" class="form-control" value="10x15zY" />
            </div>
          </div>
          <div class="text-right mt-5">
            <button
              class="btn btn-primary"
              [disabled]="!rateForm.valid"
              (click)="onSubmit()"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
