import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { AchievementsComponent } from "./achievements.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { TranslateModule } from "@ngx-translate/core";

const routes = [
  {
    path: "achievements",
    component: AchievementsComponent,
  },
];

@NgModule({
  declarations: [AchievementsComponent],
  imports: [
    MatProgressBarModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatTabsModule,
    TranslateModule
  ],
  providers: [AngularFireAuth],
})
export class AchievementsModule {}
