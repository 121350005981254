<app-navbar *ngIf="isMobile" [hideBackIcon]="true"></app-navbar>

<div class="main pt-2">
  <div class="settings">
    <div class="container-fluid">
      <h2>{{ "Settings" | translate }}</h2>
      <div
        class="setting-box"
        [ngStyle]="{
          padding: isMobile ? '0' : '50px',
          'margin-bottom': isMobile ? '2rem' : '0px',
          border: isMobile ? 'none' : '1px solid #ccc'
        }"
      >
        <!-- <div class="slide-toggle">
          <span>1 Month Sponsership</span>
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch1"
            />
            <label class="custom-control-label" for="customSwitch1"></label>
          </div>
        </div>
        <div class="slide-toggle">
          <span>Premium Account</span>
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch2"
            />
            <label class="custom-control-label" for="customSwitch2"></label>
          </div>
        </div> -->
        <div class="new-game-row">
          <span>{{ "Teach a New Game" | translate }} </span>
          <button
            class="btn add-btn"
            routerLink="/choose-game"
            [queryParams]="{ settings: true }"
          >
            <i class="far fa-plus-square"></i>
          </button>
        </div>

        <!-- <h3>{{ "Notifications :" | translate }}</h3> -->
        <!-- <div class="Notification-box" *ngIf="user.settings"> -->
        <!-- <div class="set-row">
            <span>{{ "Push Notifications" | translate }} </span>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch3"
                [(ngModel)]="user.settings.pushNotifications"
                name="pushNotifications"
                (change)="setSetting()"
              />
              <label class="custom-control-label" for="customSwitch3"></label>
            </div>
          </div>
          <div class="set-row">
            <span>{{ "SMS Notifications" | translate }} </span>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch4"
                [(ngModel)]="user.settings.smsNotifications"
                name="smsNotifications"
                (change)="setSetting()"
              />
              <label class="custom-control-label" for="customSwitch4"></label>
            </div>
          </div>
          <div class="set-row">
            <span>{{ "Site Notifications" | translate }} </span>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch5"
                [(ngModel)]="user.settings.siteNotifications"
                name="siteNotifications"
                (change)="setSetting()"
              />
              <label class="custom-control-label" for="customSwitch5"></label>
            </div>
          </div> -->
        <!-- <div class="set-row">
            <label for="language">{{ "Language" | translate }}:</label>
            <select
              id="language"
              (change)="setSetting()"
              [(ngModel)]="user.settings.selectedLanguage"
              name="selectedLanguage"
            >
              <option value="en">English</option>
              <option value="it">Italian</option>
            </select>
          </div> -->
        <!-- </div> -->

        <h3>{{ "Payment" | translate }} :</h3>
        <div class="payment-box">
          <!-- <div class="box-head">
            <span>{{ "Add Payment Method" | translate }} </span>
            <button class="btn add-btn" (click)="selectPayment()">
              <i class="far fa-plus-square"></i>
            </button>
          </div>
          <div class="card-input" *ngFor="let card of user.card; let i = index">
            <input
              type="text"
              class="form-control"
              placeholder="0529xxxxxxxxx8989039409"
              [value]="card"
              [disabled]="true"
            />
            <i class="fa fa-times cross" (click)="removeCard(card, i)"></i>
          </div> -->

          <div class="box-head">
            <span
              >{{ "IBAN" | translate }}
              <mat-icon
                class="info-tooltip"
                [matTooltip]="'IBAN Tooltip' | translate"
                >info</mat-icon
              >
            </span>
          </div>
          <form [formGroup]="ibanForm">
            <input type="text" formControlName="iban" class="form-control" />
            <p
              class="alert alert-danger"
              *ngIf="
                ibanForm.get('iban').errors &&
                ibanForm.get('iban').errors.ibanInvalid
              "
            >
              <span
                *ngIf="ibanForm.get('iban').errors.error.countryUnsupported"
              >
                {{ "Country not supported" | translate }}
              </span>
              <span *ngIf="ibanForm.get('iban').errors.error.codeLengthInvalid">
                {{ "Code length is invalid" | translate }}
              </span>
              <span *ngIf="ibanForm.get('iban').errors.error.patternInvalid">
                {{ "Pattern is invalid" | translate }}
              </span>
            </p>
            <button
              type="button"
              class="btn"
              [disabled]="ibanForm.touched && ibanForm.errors?.error"
              (click)="saveIBAN()"
            >
              {{ "Save" | translate }}
            </button>
          </form>
        </div>

        <h3>{{ "Settings VAT" | translate }} :</h3>
        <div class="vat-box">
          <label>{{ "VAT" | translate }} :</label>
          <div class="vat-input">
            <input type="text" class="form-control" [value]="user.vat" #vat />
            <!-- <i class="far fa-edit"></i> -->
          </div>

          <label>{{ "Fiscal Code" | translate }} :</label>
          <div class="vat-input">
            <input
              type="text"
              class="form-control"
              [value]="user.fiscalCode"
              #fiscal
            />
            <!-- <i class="far fa-edit"></i> -->
          </div>
          <button class="btn btn" (click)="setVatFiscal()">
            {{ "Save" | translate }}
          </button>
        </div>

        <h3>{{ "Advertise Products" | translate }} :</h3>
        <div class="new-game-row">
          <span>{{ "Add your Products & earn Money" | translate }} </span>
          <button class="btn add-btn" (click)="addProduct()">
            <i class="far fa-plus-square"></i>
          </button>
        </div>

        <h3>{{ "Connect Stripe" | translate }} :</h3>
        <!-- If stripe_user_id is not null, show green background -->
        <div

          class="new-game-row"
          [ngStyle]="{
            'background-color': user?.stripe_user_id != null
              ? '#6aa84f'
              : '#fc3232'
          }"
        >
          <span>{{ "Connect Stripe" | translate }} </span>
          <button
            class="btn add-btn"
            *ngIf="user?.stripe_user_id == null"
            [ngStyle]="{
              'background-color': user?.stripe_user_id != null
                ? '#6aa84f'
                : '#fc3232'
            }"
            (click)="connectStripe()"
          >
            <i class="far fa-plus-square"></i>
          </button>
        </div>
        <h3>{{ "Privacy" | translate }} :</h3>
        <div class="vat-box">
          <label>{{ "Name" | translate }} :</label>
          <div class="vat-input">
            <input
              type="text"
              class="form-control"
              placeholder="Alex Housten"
              [disabled]="true"
              [value]="user.name"
            />
          </div>

          <label>{{ "Discord" | translate }} :</label>
          <div class="vat-input">
            <input
              type="text"
              class="form-control"
              [value]="user.discordUsername"
              [disabled]="true"
            />
          </div>
        </div>
        <div
          *ngIf="user?.isVerified != 2"
          class="new-game-row verify"
          (click)="verifyAccount()"
          [style.backgroundColor]="
            user?.isVerified == 0 ? '#fc3232' : '#6aa84f !important'
          "
        >
          <span
            >{{
              user?.isVerified == 0
                ? ("Verify Your Account" | translate)
                : ("Your request has been submitted." | translate)
            }}
          </span>
          <button class="btn add-btn">
            <i class="fas fa-exclamation-circle"></i>
          </button>
        </div>
        <div class="new-game-row">
          <span>{{ "Contact Support" | translate }} </span>
          <button class="btn add-btn" (click)="supportDialog()">
            <i class="far fa-envelope"></i>
          </button>
        </div>

        <div class="bottom-btns">
          <!-- <button class="btn discord-btn mb-3" (click)="connectDiscord()">{{"Connect Discord" | translate}}</button> -->
          <br />
          <button class="btn delete-btn" (click)="onDeleteAccount()">
            {{ "Delete Account" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
