import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { BehaviorSubject } from "rxjs";

const BASE_URL = environment.baseUrl;

@Injectable({
  providedIn: "root",
})
export class LoginService {
  isUserLoggedIn: Boolean = false;
  public authenticated = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    let token = localStorage.getItem("access_token");
    if (token) {
      this.isUserLoggedIn = true;
      this.authenticated.next(this.isUserLoggedIn);
    } else {
      this.isUserLoggedIn = false;
      this.authenticated.next(this.isUserLoggedIn);
    }
  }

  ngOnInit() {}
}
