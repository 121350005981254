<div mat-dialog-content>
  <form [formGroup]="form" class="form-container">
    <div
      *ngFor="let controlName of controlNames; let i = index"
      class="d-flex align-items-baseline"
    >
      <!-- Product Name -->
      <mat-form-field appearance="outline">
        <mat-label>{{ "Product Name" | translate }} {{ i + 1 }}</mat-label>
        <input matInput [formControlName]="'productName' + i" />
      </mat-form-field>

      <!-- Amazon Link -->
      <mat-form-field appearance="outline">
        <mat-label>{{ "Amazon Link" | translate }} {{ i + 1 }}</mat-label>
        <input matInput [formControlName]="'productLink' + i" />
      </mat-form-field>

      <!-- Product Image -->
      <div class="file-input-wrapper">
        <button mat-raised-button type="button" (click)="fileInputUser.click()">
          {{ "Choose Your File" | translate }}
        </button>
        <input
          #fileInputUser
          type="file"
          style="display: none"
          (change)="uploadImage($event, i)"
          accept="image/*"
        />
        <mat-spinner *ngIf="uploading[i]" diameter="20"></mat-spinner>

        <!-- <span>{{ fileToUploadUser?.name || "No file chosen" }}</span> -->
      </div>
      <!-- <input type="file" (change)="uploadImage($event, i)" /> -->
      <button
        mat-button
        type="button"
        color="warn"
        (click)="removeProduct(i)"
        *ngIf="
          form.get('productName' + i).value || form.get('productLink' + i).value
        "
      >
        {{ "Remove" | translate }}
      </button>
    </div>
  </form>

  <mat-dialog-actions align="end">
    <mat-spinner *ngIf="isSaving" diameter="30"></mat-spinner>
    <button
      mat-button
      mat-dialog-close
      class="save"
      type="button"
      (click)="save()"
      [disabled]="form.invalid || isUploading() || isSaving"
    >
      {{ "Save" | translate }}
    </button>
  </mat-dialog-actions>
</div>
