<div class="calendar-popup">
  <div class="calendar-dialog">
    <button mat-raised-button class="close" (click)="dialogRef.close(true)">
      X
    </button>
    <h3>Calendar Shared for All Private Rates :</h3>
    <ul class="weekdays">
      <li>
        <label><input type="checkbox" name="days" value="M" /> M</label>
      </li>
      <li>
        <label><input type="checkbox" name="days" value="T" /> T</label>
      </li>
      <li>
        <label><input type="checkbox" name="days" value="W" /> W</label>
      </li>
      <li>
        <label><input type="checkbox" name="days" value="Th" /> Th</label>
      </li>
      <li>
        <label><input type="checkbox" name="days" value="F" /> F</label>
      </li>
      <li>
        <label><input type="checkbox" name="days" value="Sa" /> Sa</label>
      </li>
      <li>
        <label><input type="checkbox" name="days" value="Su" /> Su</label>
      </li>
    </ul>
    <div class="events">
      <div class="event-time">
        <ul class="time">
          <li>12:00 am</li>
          <li>01:00 am</li>
          <li>02:00 am</li>
          <li>03:00 am</li>
          <li>04:00 am</li>
          <li>05:00 am</li>
          <li>06:00 am</li>
          <li>07:00 am</li>
          <li>08:00 am</li>
          <li>09:00 am</li>
          <li>10:00 am</li>
          <li>03:00 am</li>
          <li>04:00 am</li>
        </ul>
      </div>
      <div class="event-list">
        <div class="box">
          <img src="assets/images/event-small.png" alt="" />
          <span class="title">Click to Set Availability</span>
        </div>
        <div class="box active">
          <img src="assets/images/event-small.png" alt="" />
          <span class="title">Available</span>
        </div>
        <div class="box">
          <img src="assets/images/event-small.png" alt="" />
          <span class="title">Click to Set Availability</span>
        </div>
      </div>
    </div>
    <div class="text-right w-100">
      <button class="btn btn-primary">Save</button>
    </div>
  </div>
</div>
