<div mat-dialog-title>{{'Logout'}}</div>
<div mat-dialog-content>{{"Are you sure you want to logout?" | translate}}</div>
<div mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close class="No">{{"Cancel" | translate}}</button>
  <button
    mat-raised-button
    class="Yes"
    [mat-dialog-close]="true"
    (click)="logout()"
  >
    {{"Yes" | translate}}
  </button>
</div>
