<app-navbar
  *ngIf="isMobile"
  [title]="'Class Settings' | translate"
></app-navbar>
<div class="main" *ngIf="classData">
  <div class="class-setting">
    <div class="container-fluid">
      <div class="class-cover">
        <img src="{{ classData.image }}" alt="" />
      </div>
      <div class="setting-area">
        <div class="row">
          <div class="col-md-5">
            <h3>
              {{ "Manage Students" | translate }}
              <mat-icon
                class="info-tooltip"
                [matTooltip]="'Manage Students Tooltip' | translate"
                >info</mat-icon
              >
            </h3>
            <div class="setting-box">
              <form>
                <div class="head-area">
                  <div class="checkbox-area">
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="move"
                        name="settings"
                        [checked]="isMoveStudent"
                        (click)="updateSelection('move')"
                        value="move"
                      />
                      <label class="form-check-label" for="move">{{
                        "Move Students" | translate
                      }}</label>
                    </div>

                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="remove"
                        name="settings"
                        [checked]="isRemoveStudent"
                        (click)="updateSelection('remove')"
                      />
                      <label class="form-check-label" for="remove">{{
                        "Remove Students" | translate
                      }}</label>
                    </div>
                  </div>
                  <div class="right-side">
                    <h4>{{ "Students" | translate }}</h4>
                    <!-- <input type="text" class="form-control" /> -->
                    <mat-form-field
                      *ngIf="allStudents.length > 0"
                      class="example-chip-list w-100"
                      appearance="outline"
                    >
                      <mat-label></mat-label>
                      <mat-chip-grid #chipGrid aria-label="student selection">
                        <mat-chip-row
                          *ngFor="let student of presentStudents"
                          (removed)="remove(student)"
                        >
                          {{ student }}
                          <button
                            matChipRemove
                            [attr.aria-label]="'remove ' + student"
                          >
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </mat-chip-row>
                      </mat-chip-grid>
                      <input
                        placeholder="{{
                          'Type name to select Attendance' | translate
                        }}"
                        #studentInput
                        [formControl]="studentCtrl"
                        [matChipInputFor]="chipGrid"
                        [matAutocomplete]="auto"
                      />
                      <mat-autocomplete
                        #auto="matAutocomplete"
                        (optionSelected)="selected($event)"
                      >
                        <mat-option
                          *ngFor="let student of filteredstudents | async"
                          [value]="student.nickname"
                        >
                          {{ student.nickname ?? student.username ?? 'Student#' + student.uid }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <h4 class="mt-4">{{ "Class" | translate }}</h4>
                    <input
                      type="text"
                      class="form-control"
                      [value]="classData.title"
                      disabled="true"
                    />
                    <span class="to" *ngIf="isMoveStudent">to</span>
                    <select
                      class="form-control"
                      *ngIf="isMoveStudent"
                      [(ngModel)]="toClass"
                      [ngModelOptions]="{ standalone: true }"
                      name="description"
                    >
                      <option selected disabled>
                        {{ "No Class Exist" | translate }}
                      </option>
                      <option *ngFor="let class of allClasses">
                        {{ class.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="description">
                  <h4 class="mb-3">{{ "Description" | translate }}</h4>
                  <textarea
                    class="form-control"
                    [(ngModel)]="description"
                    [ngModelOptions]="{ standalone: true }"
                    name="description"
                    [maxLength]="250"
                  ></textarea>
                </div>
                <div class="text-right">
                  <button
                    class="btn btn-primary"
                    (click)="sendSupportRequest()"
                    [disabled]="
                      (isMoveStudent && !toClass) ||
                      (isRemoveStudent &&
                        presentStudents.length == allStudents.length) ||
                      (isRemoveStudent && presentStudents.length == 0) ||
                      (!isMoveStudent && !isRemoveStudent)
                    "
                  >
                    {{ "Send" | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-5 offset-md-1">
            <h3>
              {{ "Manage Class" | translate }}
              <mat-icon
                class="info-tooltip"
                [matTooltip]="'Manage Class Tooltip' | translate"
                >info</mat-icon
              >
            </h3>
            <div class="setting-box">
              <form>
                <div class="head-area">
                  <div class="checkbox-area">
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="block"
                        [(ngModel)]="isBlockSubscription"
                        name="settings"
                        (click)="updateSelectionSettings('block')"
                      />
                      <label class="form-check-label" for="block">{{
                        "Block Subscriptions" | translate
                      }}</label>
                    </div>
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="delete"
                        [(ngModel)]="isDeleteClass"
                        name="isDeleteClass"
                        (click)="updateSelectionSettings('delete')"
                      />
                      <label
                        class="form-check-label"
                        [ngStyle]="{
                          'margin-bottom': '12px'
                        }"
                        for="delete"
                        >{{ "Delete Class" | translate }}
                        <mat-icon
                          class="info-tooltip"
                          [matTooltip]="'Delete Class Tooltip' | translate"
                          >info</mat-icon
                        >
                      </label>
                      <span
                        *ngIf="
                          isDeleteClass &&
                          classData.classStudentsList.length > 0
                        "
                        class="mat-error d-block alert alert-danger alert-text"
                      >
                        {{
                          "You will send a request to the support" | translate
                        }}
                      </span>
                      <span
                        *ngIf="
                          isDeleteClass &&
                          classData.classStudentsList.length == 0
                        "
                        class="mat-error d-block alert alert-danger alert-text"
                      >
                        {{ "You are going to delete this class" | translate }}
                      </span>
                    </div>
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="postpone"
                        [(ngModel)]="isPostponeClass"
                        name="isPostponeClass"
                        (click)="updateSelectionSettings('postpone')"
                      />
                      <label class="form-check-label" for="postpone">{{
                        "Postpone Start Date" | translate
                      }}</label>
                    </div>
                  </div>
                  <div class="right-side">
                    <div class="motivation">
                      <h4 class="mb-3">{{ "Motivation" | translate }}</h4>
                      <textarea
                        class="form-control"
                        [(ngModel)]="motivation"
                        name="'motivation"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="text-right mt-5">
                  <button
                    class="btn btn-primary"
                    type="button"
                    (click)="doItBasedONCheckBox()"
                  >
                    {{ "Send" | translate }}
                  </button>
                </div>
              </form>
            </div>
            <!-- <div class="discount">
              <h5>Discounts :</h5>
              <div class="discount-box">
                <div class="list">
                  <span class="text"
                    ><u>25%</u> off for the first <u>15</u> students by using
                    this promo code
                  </span>
                  <input type="text" class="form-control" value="W2xyB" />
                </div>
                <div class="list">
                  <span class="text"
                    ><u>15%</u> off for the first <u>10</u> students by using
                    this promo code
                  </span>
                  <input type="text" class="form-control" value="10x15zY" />
                </div>
              </div>
            </div> -->

            <button class="btn support-btn" (click)="supportDialog()">
              {{ "Contact Support" | translate }}
            </button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-5">
            <h3>
              {{ "Update Class Details" | translate }}
              <mat-icon
                class="info-tooltip"
                [matTooltip]="'Update Class Details Tooltip' | translate"
                >info</mat-icon
              >
            </h3>
            <div class="setting-box">
              <form>
                <div>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ "Title" | translate }}</mat-label>
                    <input
                      [(ngModel)]="classData.title"
                      name="title"
                      matInput
                    />
                  </mat-form-field>
                </div>
                <div>
                  <h4 class="mb-3">{{ "Description" | translate }}</h4>
                  <textarea
                    class="form-control"
                    [(ngModel)]="classData.description"
                    [ngModelOptions]="{ standalone: true }"
                    name="description"
                    [maxLength]="250"
                  ></textarea>
                </div>
                <div class="text-right">
                  <button
                    class="btn btn-primary"
                    (click)="updateClassDetails()"
                    [disabled]="!classData.title || !classData.description"
                  >
                    {{ "Save" | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <h3>{{ "Discounts" | translate }}</h3>
            <div
              class="col-md-12"
              [class.disabled]="isRateWithSingleDiscount()"
              (click)="isRateWithSingleDiscount() ? showErrorMessage() : null"
            >
              <app-rate-discount
                (onSave)="discountsUpdated($event)"
                [isDiscountAvailableForClass]="isRateWithSingleDiscount()"
                [discountsList]="_discounts"
                [selectedDiscounts]="selectedDiscounts"
              ></app-rate-discount>
              <div>
                <button class="btn btn-primary" (click)="saveDiscounts()">
                  {{ "Save" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="h-100 w-100 d-flex justify-content-center align-items-center"
  style="height: 100vh"
>
  <app-loader *ngIf="!classData"></app-loader>
</div>
