<app-navbar *ngIf="isMobile" [hideBackIcon]="true"></app-navbar>

<div class="main">
  <div class="dashboard">
    <div class="container-fluid">
      <div
        *ngIf="user?.isVerified != 2"
        class="new-game-row verify"
        (click)="verifyAccount()"
        [style.backgroundColor]="
          user?.isVerified == 0 ? '#fc3232' : '#3e3ef1 !important'
        "
      >
        <span
          >{{
            user?.isVerified == 0
              ? ("Verify Your Account" | translate)
              : ("Your request has been submitted." | translate)
          }}
        </span>
        <button class="btn add-btn">
          <i class="fas fa-exclamation-circle"></i>
        </button>
      </div>

      <div [style.backgroundColor]="'#fc3232'" class="new-game-row verify">
        {{
          "Until Student subscriptions are closed, no one can buy from you. Wait to create your classes!"
            | translate
        }}
      </div>
      <h2>{{ "Next Lessons" | translate }}</h2>
      <div appScrollHorizontal class="row horizontal-scroller hide-scrollbar">
        <ng-container *ngIf="upcomingClassesData.length > 0; else noClasses">
          <div
            class="col-md-4 col-lg-3"
            *ngFor="let class of upcomingClassesData; let i = index"
          >
            <app-gaming-card
              routerLink="/group-class-view/{{ class.uid }}"
              [imageUrl]="class.image"
              [title]="class.title"
              [subtitleGreen]="class.subtitle"
              [startDate]="alldates[i][0]"
              [startTime]="class.startTime"
              [endTime]="class.endTime"
            ></app-gaming-card>
          </div>
        </ng-container>
        <ng-template #noClasses>
          <div class="col-md-12 text-center">
            {{ "No classes scheduled" | translate }}
          </div>
        </ng-template>
      </div>

      <div class="calendar-box mt-5" >
        <div class="row">
          <div class="col-md-6" style="border-right: 1px solid #ccc">
            <div class="row align-items-center">
              <select
                class="form-control"
                #dateSelect
                [(ngModel)]="selectedMonthVal"
                (change)="onMonthChange($event)"
              >
                <option
                  *ngFor="let option of dropdownOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
              <mat-icon [matTooltip]="'Calendar Tooltip' | translate"  class="info-tooltip" >info</mat-icon>
            </div>

            <div class="select-date">
              <ul class="weekdays">
                <li>{{"Sun" | translate}}</li>
                <li>{{"Mon" | translate}}</li>
                <li>{{"Tue" | translate}}</li>
                <li>{{"Wed"| translate}}</li>
                <li>{{"Thu"| translate}}</li>
                <li>{{"Fri"| translate}}</li>
                <li>{{"Sat"| translate}}</li>
              </ul>
              <ul class="days">
                <li *ngFor="let day of blankDays">
                  <span class="inactive">{{ day }}</span>
                </li>
                <li *ngFor="let day of daysInMonth">
                  <span
                    (click)="onDateClick(day)"
                    [ngClass]="{
                      selected: selected === day,
                      'has-class': isClassScheduled(day)
                    }"
                    class="active"
                    >{{ day }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <!-- <select
              name="selectedWeek"
              id=""
              [(ngModel)]="selectedWeek"
              class="form-control"
            >
              <option value="1">Week 1</option>
              <option value="2">Week 2</option>
              <option value="3">Week 3</option>
              <option value="4">Week 4</option>
            </select> -->
            <p style="margin-top: 3vh">
              {{ selDate | date : "EEEE, d": '': 'it-IT' }}
            </p>

            <div class="events">
              <div class="event-time">
                <ul class="time">
                  <li>00:00</li>
                  <li>01:00</li>
                  <li>02:00</li>
                  <li>03:00</li>
                  <li>04:00</li>
                  <li>05:00</li>
                  <li>06:00</li>
                  <li>07:00</li>
                  <li>08:00</li>
                  <li>09:00</li>
                  <li>10:00</li>
                  <li>11:00</li>
                  <li>12:00</li>
                  <li>13:00</li>
                  <li>14:00</li>
                  <li>15:00</li>
                  <li>16:00</li>
                  <li>17:00</li>
                  <li>18:00</li>
                  <li>19:00</li>
                  <li>20:00</li>
                  <li>21:00</li>
                  <li>22:00</li>
                  <li>23:00</li>
                </ul>
              </div>
              <div class="event-list">
                <div
                  *ngFor="let class of selectedDayClasses"
                  [style.top.px]="(class.startTimeMinutes / 60) * 40"
                  [style.height.px]="
                    ((class.endTimeMinutes - class.startTimeMinutes) / 60) * 40
                  "
                  class="event-item box"
                  [ngStyle]="{
                    width: isMobile ? 'auto' : '100%',
                    'font-size': isMobile ? '0.7rem' : 'inherit'
                  }"
                >
                  <img
                    src="{{ class.image }}"
                    alt=""
                    style="object-fit: contain"
                  />

                  <span class="event-name">{{ class.title }}</span>
                  <span class="event-start-time">
                    {{ class.startTime }}
                  </span>
                  <span class="event-end-time">
                    {{ class.endTime }}
                  </span>
                  <!-- <span class="event-name">{{ class.gameTitle }} - </span>
                  <span class="event-start-time">
                    {{ convertTo12HourFormat(class.startTime) }}
                  </span>
                  <span class="event-end-time">
                    {{ convertTo12HourFormat(class.endTime) }}
                  </span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="achievements">
        <div class="section-head">
          <h2>Achievements:</h2>
          <a class="view-all" routerLink="/achievements">View All</a>
        </div>
        <div class="achievement-box">
          <div class="b-head">
            <h4>
              <img src="assets/images/achiev.png" alt="" /> Achievement 1:
            </h4>
            <span class="unlock-text">Unlock Adding Upto 5 Rates</span>
          </div>
          <div class="b-body">
            <i class="fas fa-check-circle"></i>
            <span class="inner-text">Get your first student Onboard</span>
            <div class="progress-bar">
              <div class="bar-green"></div>
            </div>
            <span class="total">0/1</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
