<div class="main pt-2">
  <div class="referrals">
    <div class="container-fluid">
      <h2>Earnings</h2>
      <div class="nav-row">
        <h3>Money Earned :</h3>
        <div class="links">
          <a routerLink="/earnings">General</a>
          <a class="active">Referrals</a>
          <a routerLink="/products">Products</a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <div class="referral-link">
            <div class="head">
              <h4>Referral Link</h4>
              <span class="credit">Credit : </span>
            </div>
            <div class="code">
              <input type="text" class="form-control" [(ngModel)]="value" />
              <i
                class="far fa-copy"
                (click)="openSnackBar()"
                [cdkCopyToClipboard]="value"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Copy Referral Link"
              ></i>
            </div>
            <p>
              Invite anyone using you referral link & You both will get<br />
              a credit of <span>$10</span> after he spends his first $100
            </p>
          </div>

          <div class="transactions">
            <div class="row">
              <div class="col-md-5">
                <h5>Confirmed Transactions</h5>
                <div class="list">
                  <ul>
                    <li class="head">
                      <span class="left-side">Inivited User</span>
                      <span class="right-side">Credit Recieved</span>
                    </li>
                    <li>
                      <span class="left-side"></span>
                      <span class="right-side"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-5 offset-md-2 pending">
                <h5>Pending Transactions</h5>
                <div class="list">
                  <ul>
                    <li class="head">
                      <span class="left-side">Inivited User</span>
                      <span class="right-side">Credit Recieved</span>
                    </li>
                    <li>
                      <span class="left-side"></span>
                      <span class="right-side"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
