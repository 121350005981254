<div id="auth" class="auth">
  <div class="container-fluid">
    <img
      class="sweech-logo"
      [ngStyle]="{ position: isMobile ? 'unset' : 'absolute' }"
      src="assets/images/sweech-logo.png"
      alt=""
    />

    <div class="row">
      <div class="col-md-5">
        <div class="auth-box" [ngClass]="{ 'auth-mobile': isMobile }">
          <h1>{{ "Master Sign up" | translate }}</h1>
          <p>
            {{ "Please fill your details to Create your account." | translate }}
          </p>
          <img
            class="profile-img"
            [src]="
              uploadUrl
                ? uploadUrl
                : userAlreadyExist
                ? profilePicUrl
                : profilePicUrl != null
                ? profilePicUrl
                : defaultImageUrl
            "
            style="
              border-radius: 100%;
              height: 120px;
              width: 120px;
              margin-bottom: 10px;
            "
          />
          <input
            type="file"
            (change)="onselectFile($event)"
            value="{{ profilePic$ | async }}"
            *ngIf="!userAlreadyExist"
          />

          <form [formGroup]="signUpForm">
            <div class="form-group">
              <label>{{ "Full Name" | translate }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'Enter Full Name' | translate }}"
                autocomplete="off"
                formControlName="fullName"
                [disabled]="userAlreadyExist"
              />
              <div
                *ngIf="
                  signUpForm.get('fullName').invalid &&
                  (signUpForm.get('fullName').dirty ||
                    signUpForm.get('fullName').touched)
                "
                class="text-danger"
              ></div>
            </div>

            <div class="form-group">
              <label>{{ "Username" | translate }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'Enter Username' | translate }}"
                autocomplete="off"
                formControlName="userName"
                [disabled]="userAlreadyExist"
              />
              <div
                *ngIf="
                  signUpForm.get('userName').invalid &&
                  (signUpForm.get('userName').dirty ||
                    signUpForm.get('userName').touched)
                "
                class="text-danger"
              >
                <div *ngIf="signUpForm.get('userName').errors.required">
                  {{ "Username is required." | translate }}
                </div>
              </div>
              <div
                *ngIf="
                  signUpForm.get('userName').errors?.taken && !userAlreadyExist
                "
                class="text-danger"
              >
                {{ "Username is already taken." | translate }}
              </div>
            </div>

            <div class="form-group">
              <label>{{ "Connect Discord" | translate }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'Link Your Discord account' | translate }}"
                autocomplete="off"
                formControlName="discordLink"
                (blur)="signUpForm.get('discordLink').markAsTouched()"
                [disabled]="userAlreadyExist"
              />
              <div
                *ngIf="
                  signUpForm.get('discordLink').invalid &&
                  signUpForm.get('discordLink').touched
                "
                class="text-danger"
              >
                <div *ngIf="signUpForm.get('discordLink').errors.required">
                  {{ "Discord Link is required." | translate }}
                </div>
                <div
                  *ngIf="
                    signUpForm.get('discordLink').errors.invalidDiscordLink
                  "
                >
                  {{ "Please provide a valid Discord Link." | translate }}
                </div>
              </div>
              <p class="alert alert-info">
                If you dont have Discord you can create here:
                <a href="https://discord.com/" target="_blank"
                  >https://discord.com/</a
                >
                and link your account
              </p>
            </div>

            <div class="form-group">
              <label>{{ "Country" | translate }}</label>
              <div style="position: relative">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{ 'Enter Country' | translate }}"
                  autocomplete="off"
                  formControlName="country"
                  (blur)="signUpForm.get('country').markAsTouched()"
                  [disabled]="true"
                />
                <!-- <ng-select
                  style="
                    border: 1px solid #d0d5dd;
                    border-radius: 4px;
                    width: 355px;
                    height: 44px;
                    text-align: center;
                    line-height: 40px;
                    padding-left: 8px;
                  "
                  [items]="countries"
                  #countrySelect
                  bindLabel="name"
                  bindValue="code"
                  placeholder="{{ placeholderText | translate }}"
                  formControlName="country"
                  class="custom-dropdown-style"
                  clearable="true"
                  (change)="onCountrySelect($event)"
                >
                </ng-select> -->
                <!-- <span
                  style="
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    padding-right: 12px;
                    cursor: pointer;
                  "
                  (click)="countrySelect.open()"
                >
                  <i class="fas fa-chevron-down"></i>
                </span> -->

                <div
                  *ngIf="
                    signUpForm.get('country').invalid &&
                    (signUpForm.get('country').dirty ||
                      signUpForm.get('country').touched)
                  "
                  class="text-danger"
                >
                  <div *ngIf="signUpForm.get('country').errors.required">
                    {{ "Country is required." | translate }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 
            <div class="form-group">
              <label>{{ "VAT" | translate }}</label>
              <input
                value="{{ vat$ | async }}"
                type="text"
                class="form-control"
                placeholder=""
                autocomplete="off"
                formControlName="vat"
              />
              <div
                *ngIf="
                  signUpForm.get('vat').invalid &&
                  (signUpForm.get('vat').dirty || signUpForm.get('vat').touched)
                "
                class="text-danger"
              >
                <div *ngIf="signUpForm.get('vat').errors.required">
                  {{ "VAT is required." | translate }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ "Fiscal Code" | translate }}</label>
              <input
                value="{{ fiscalCode$ | async }}"
                type="text"
                class="form-control"
                placeholder=""
                autocomplete="off"
                formControlName="fiscalCode"
              />
              <div
                *ngIf="
                  signUpForm.get('fiscalCode').invalid &&
                  (signUpForm.get('fiscalCode').dirty ||
                    signUpForm.get('fiscalCode').touched)
                "
                class="text-danger"
              >
                <div *ngIf="signUpForm.get('fiscalCode').errors.required">
                  {{ "Fiscal Code is required" | translate }}
                </div>
              </div>
            </div> -->
            <button
              type="submit"
              class="btn btn-primary mb-3"
              [disabled]="!signUpForm.valid && !userAlreadyExist"
              (click)="onSubmit()"
              [ngClass]="{ 'mb-4': isMobile }"
            >
              {{ "Next" | translate }}
            </button>
          </form>
        </div>
      </div>
      <div class="col-md-7" *ngIf="!isMobile">
        <img
          class="img-fluid"
          src="/assets/images/login.jpg"
          style="height: 100%; object-fit: fill"
        />
      </div>
    </div>
  </div>
</div>
