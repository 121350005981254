import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { ReferralsComponent } from "./referrals.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";

const routes = [
  {
    path: "referrals",
    component: ReferralsComponent,
  },
];

@NgModule({
  declarations: [ReferralsComponent],
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatTabsModule,
    ClipboardModule,
    MatTooltipModule,
    MatSnackBarModule,
  ],
})
export class ReferralsModule {}
