import { Component, Inject } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Router } from "@angular/router";
import { User } from "firebase/auth";
import * as firebase from "firebase/compat/app";

@Component({
  selector: "request-game-dialog",
  templateUrl: "./request-game-dialog.component.html",
  styleUrls: ["./request-game-dialog.component.scss"],
})
export class RequestGameDialogComponent {
  message: string;
  isLoading: boolean = false;

  constructor(
    private afAuth: AngularFireAuth,
    public dialogRef: MatDialogRef<RequestGameDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    private afs: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  saveMessageToFirestore(message: string) {
    const uid = this.afs.createId();
    const category = "Missing Game Request";
    this.afAuth.authState.subscribe((user: User | null) => {
      if (user) {
        const senderEmailId = user.email;
        const senderID = user.uid;
        this.afs.doc('users/' + senderID).update({supportRequested: firebase.default.firestore.FieldValue.increment(1)}).then(() => {})
        this.afs
          .collection("support")
          .doc(uid)
          .set({
            message: message,
            uid: uid,
            senderEmail: senderEmailId,
            creationDateTS: new Date(),
            category: category,
            isMaster: true,
            isNew: true,
            isReplied: false,
            senderID: senderID,
            isRefund: false,
          })
          .then(() => {
            this.isLoading = true;
            this.matSnackBar.open("Your request has been submitted!", "Close", {
              duration: 3000,
            });
            this.dialogRef.close();
          })
          .catch((error) => {
            console.error(error);
            this.matSnackBar.open(
              "Error submitting request. Please try again.",
              "Close",
              {
                duration: 3000,
              }
            );
          });
      }
    });
  }
}
