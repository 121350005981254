import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "../app/main/authorizations/login/login.component";
import { SignUpComponent } from "../app/main/authorizations/signup/signup.component";
import { DashboardComponent } from "../app/main/dashboard/dashboard.component";
import { ForgetPasswordComponent } from "../app/main/authorizations/forget-password/forget-password.component";
import { AppAuthGuard } from "./app.authguard";
import { DiscordCallbackComponent } from "./discord-callback/discord-callback.component";

const routes: Routes = [
  { path: "sign-in", component: LoginComponent },
  { path: "register-user", component: SignUpComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AppAuthGuard],
  },
  { path: "forgot-password", component: ForgetPasswordComponent },
  {
    path: 'discord/callback',
    component: DiscordCallbackComponent,
  },
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
