import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from "@fuse/components";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { appConfig } from "app/app-config";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { DashboardModule } from "app/main/dashboard/dashboard.module";
import { AuthorizationsModule } from "./main/authorizations/authorizations.module";
import { AppAuthGuard } from "./app.authguard";
import { InterceptorService } from "./helper-services/interceptor.service";
import { ErrorInterceptor } from "./helper-services/error.interceptor";
import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire/compat";
import { VerticalLayout1Module } from "./layout/vertical/layout-1/layout-1.module";
import { MessagesModule } from "./main/messages/messages.module";
import { ManagerModule } from "./main/manager/manager.module";
import { EarningsModule } from "./main/earnings/earnings.module";
import { ReferralsModule } from "./main/referrals/referrals.module";
import { ProductsModule } from "./main/products/products.module";
import { AnalyticsModule } from "./main/analytics/analytics.module";
import { AchievementsModule } from "./main/achievements/achievements.module";
import { ManageRatesModule } from "./main/manage-rates/manage-rates.module";
import { SettingsModule } from "./main/settings/settings.module";
import { ProfileModule } from "./main/profile/profile.module";
import { logoutConfirmDialogModule } from "./layout/confirm-dialog/confirm-dialog.module";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { AuthService } from "./shared/services/auth.service";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { FileUploadModule } from "ng2-file-upload";
import { FormsModule } from "@angular/forms";

import "firebase/auth";
import { SharedAppModule } from "./shared/shared-app.module";
import { AppRoutingModule } from "./app-routing.module";
import { ToastrModule } from "ngx-toastr";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { MatMenuModule } from "@angular/material/menu";
import { ConfirmationModalComponent } from "./shared/components/confirmation-modal/confirmation-modal.component";
import { NgxStripeModule } from "ngx-stripe";
import { CarouselModule } from "ngx-owl-carousel-o";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { BackButtonComponent } from "./shared/components/back-button/back-button.component";
import { SidenavService } from "./shared/sidenav.service";
import { MAT_DATE_LOCALE } from "@angular/material/core";

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import localeITExtra from '@angular/common/locales/extra/it';
import { DiscordCallbackComponent } from './discord-callback/discord-callback.component';

registerLocaleData(localeIT, 'it-IT', localeITExtra);
@NgModule({
  declarations: [AppComponent, DiscordCallbackComponent],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    AppAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    // { provide: LOCALE_ID, useValue: "it-IT" }
  ],
  imports: [
    MatBottomSheetModule,
    FileUploadModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    // Material
    MatMomentDateModule,
    MatButtonModule,
    MatIconModule,
    // Fuse modules
    FuseModule.forRoot(appConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    logoutConfirmDialogModule,
    // App modules
    LayoutModule,
    DashboardModule,
    MessagesModule,
    ManagerModule,
    EarningsModule,
    ReferralsModule,
    ProductsModule,
    AnalyticsModule,
    AchievementsModule,
    ManageRatesModule,
    SettingsModule,
    ProfileModule,
    AuthorizationsModule,
    StoreModule.forRoot({}, {}),
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    VerticalLayout1Module,
    SharedAppModule,
    NgxMatTimepickerModule,
    MatMenuModule,
    CarouselModule,
    NgxIntlTelInputModule,
    NgxStripeModule.forRoot(
      "pk_test_51Lx30nJUHyeh4GjlL50axNyFoqmorwD9HtJAKcgg6AnNbFQREuTBnf0ZiDgKdOXHgb2Dz3aaR0PFWsiLrWIX4gYs00plmdUCb3"
    ),
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
