export const environment = {
  baseUrl: "",
  clientSecret: "",
  hmr: false,
  secure: false,
  production: false,
  version: "",
  firebase: {
    apiKey: "AIzaSyBymBvNlixVLT54VLh6TQY1q8BYrS_Tvrw",
    authDomain: "sweech-3ec98.firebaseapp.com",
    databaseURL:
      "https://sweech-3ec98-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "sweech-3ec98",
    storageBucket: "sweech-3ec98.appspot.com",
    messagingSenderId: "447760769119",
    appId: "1:447760769119:web:4adbfe90f044c50f2dbbea",
    measurementId: "G-SN1WFBJ2ME",
  },

  stripe_account_id: "ca_Pnl1L2Z3d6X2pal9qQgDlQ4rUhoQbYQ6",
  stripe:
    "pk_live_51NEWk5EDKC04WtEHimy35KXVwulpMOxbxxyLcI2ydqxb0SQhkU9cQeDQNKuvd6GYlQ31JNTzxgY6CppA9sbjlHIP00Y2OldNVu",
  stripe_secret:
    "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",
};
