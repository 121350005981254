<ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-static'">
  <ng-container *ngTemplateOutlet="toolbar"></ng-container>
</ng-container>

<div id="main">
  <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'left'">
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  </ng-container>

  <div id="container-2" class="container-2">
    <content></content>
  </div>
</div>

<!-- TOOLBAR -->
<ng-template #toolbar>
  <toolbar
    *ngIf="!isMobile && !fuseConfig.layout.toolbar.hidden"
    [ngClass]="
      fuseConfig.layout.toolbar.customBackgroundColor === true
        ? fuseConfig.layout.toolbar.position +
          ' ' +
          fuseConfig.layout.toolbar.background
        : fuseConfig.layout.toolbar.position
    "
  >
  </toolbar>
</ng-template>
<!-- / TOOLBAR -->
