import { Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FirestoreService } from "app/shared/services/firestore.service";
import { Observable, firstValueFrom, map, startWith } from "rxjs";
declare var $: any;
import { v4 as uuidv4 } from "uuid";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "group-class-edit",
  templateUrl: "./group-class-edit.component.html",
  styleUrls: ["./group-class-edit.component.scss"],
})
export class GroupClassEditComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  btnDisabled: boolean = false;
  classData: any;
  classId: string = "";
  days = "";
  scheduleDays: any = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",

  ].map((e) => this.translate.instant(e));

  form: FormGroup;
  isEdit = false;

  activityId: string = '';
  presentStudents = [];
  allStudents = [];

  // Chips
  separatorKeysCodes: number[] = [ENTER, COMMA];
  studentCtrl = new FormControl('');
  filteredstudents: Observable<string[]>;

  @ViewChild('studentInput') studentInput: ElementRef<HTMLInputElement>;
  isMobile: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private firestore: FirestoreService, 
    private router: Router,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService,
    private translate: TranslateService
  ) {
    this.filteredstudents = this.studentCtrl.valueChanges.pipe(
      startWith(null),
      map((student: any | null) => (student ? this._filter(student) : this.allStudents.slice())),
    );
  }

  ngOnInit() {
    this.breakpoint
    .observe([Breakpoints.Handset, Breakpoints.Tablet])
    .subscribe((o) => {
      this.isMobile = o.matches;

      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: this.isMobile,
          },
          toolbar: {
            hidden: this.isMobile,
          },
          footer: {
            hidden: this.isMobile,
          },
          sidepanel: {
            hidden: this.isMobile,
          },
        },
      };
    });
    this.form = new FormGroup({
      gameName: new FormControl(""),
      topic: new FormControl("", Validators.required),
      date: new FormControl("", Validators.required),
      activities: new FormControl("", Validators.required),
      exercises: new FormControl("", Validators.required),
    });

    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.classId = params["id"];
        console.log(this.classId);
        this.fetchClassData();
        if (params["activityId"]) {
          this.isEdit = true;
          this.activityId = params["activityId"];
          this.fetchActivityData();
        }

    
      }
    });
  }
  async fetchAllStudentsInStudentList() {
    this.allStudents = [];
    try {
      await Promise.all(this.classData.classStudentsList.map(async (studentId) => {
        const studentDocSnapshot = await firstValueFrom(
          this.firestore.doc(`users/${studentId}`).get()
        );
        const studentData: any = studentDocSnapshot.data();
        this.allStudents.push(studentData);
      }));
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  }
  async fetchActivityData() {
    try {
      const activityDocSnapshot = await firstValueFrom(
        this.firestore.doc(`classes/${this.classId}/classActivities/${this.activityId}`).get()
      );
      const activityData: any = activityDocSnapshot.data();
      this.form.patchValue({
        topic: activityData.topic,
        date: activityData.date.toDate(),
        activities: activityData.activities,
        exercises: activityData.exercises
      });
      if(activityData.presentStudents != null && activityData.presentStudents.length > 0) {
        activityData.presentStudents.forEach(studentId => {
          const student = this.allStudents.find(s => s.uid === studentId);
          if(student) {
            console.log(student);
            this.presentStudents.push(student.username);
          }
        })
      }
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  }
  async fetchClassData() {
    try {
      const classDocSnapshot = await firstValueFrom(
        this.firestore.doc(`classes/${this.classId}`).get()
      );
      this.classData = classDocSnapshot.data();
      this.days = this.classData.scheduleDays
        .reduce((acc, d, index) => {
          if (d) {
            acc.push(this.scheduleDays[index]);
          }
          return acc;
        }, [])
        .join(", ");
      const gameTitle = this.classData.gameTitle;
      this.form.patchValue({
        gameName: gameTitle
      });
      await this.fetchAllStudentsInStudentList();
      if (this.isEdit) {
        this.fetchActivityData();
      }
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  }
  async save() {
    console.log(this.form.valid);
    if (this.form.valid) {
      if (this.isEdit && this.activityId) {
        const uidArray = this.presentStudents.map(studentUsername => {
          const student = this.allStudents.find(s => s.username === studentUsername);
          return student ? student.uid : null; 
        }).filter(uid => uid !== null);  
        const formData = this.form.value;
        try {
          await this.firestore.update(
            `classes/${this.classId}/classActivities/${this.activityId}`,
            { ...formData, presentStudents: uidArray}
          );
          // handle successful update
          this.router.navigateByUrl('/group-class-view/' + this.classId);
        } catch (error) {
          // handle update error
        }
        return;
      }
      const formData = this.form.value;
      const uid = uuidv4();
      try {
        let uidArray = []
        if (this.presentStudents && this.presentStudents.length > 0) {
         uidArray = this.presentStudents.map(studentUsername => {
            const student = this.allStudents.find(s => s.username === studentUsername);
            return student ? student.uid : null; 
          }).filter(uid => uid !== null);  
        }
        await this.firestore.set(
          `classes/${this.classId}/classActivities/${uid}`,
          { ...formData, uid, presentStudents: uidArray, creationDateTS: new Date() }
        );
        
        // handle successful update
        this.router.navigateByUrl('/group-class-view/' + this.classId);
      } catch (error) {
        // handle update error
      }
    }
  }

  // Chips


  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
  
    // Add our student
    if (value) {
      const student = this.allStudents.find(s => s.username === value);
      if (student && !this.presentStudents.includes(student.username)) {
        this.presentStudents.push(student.username);
      }
    }
  
    // Clear the input value
    event.chipInput!.clear();
  
    this.studentCtrl.setValue(null);
  }
  


  remove(student: string): void {
    const index = this.presentStudents.indexOf(student);

    if (index >= 0) {
      this.presentStudents.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.presentStudents.includes(event.option.value)) {
      this.presentStudents.push(event.option.value);
    }
    this.studentInput.nativeElement.value = '';
    this.studentCtrl.setValue(null);
  }

  private _filter(value: any): string[] {
    const filterValue = value.toLowerCase();

    return this.allStudents.filter(student => student.username.toLowerCase().includes(filterValue));
  }
}
