import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
   MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { cardDetailDialogComponent } from "../card-detail-dialog/card-detail-dialog.component";

@Component({
  selector: "select-payment-dialog",
  templateUrl: "./select-payment-dialog.component.html",
  styleUrls: ["./select-payment-dialog.component.scss"],
})
export class selectPaymentDialogComponent {
  /**
   * Constructor
   *
   * @param {MatDialogRef<selectPaymentDialog>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<selectPaymentDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  cardDetail() {
    this.dialogRef.close();
    this.matDialog.open(cardDetailDialogComponent);
  }
}
