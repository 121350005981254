<app-navbar
  *ngIf="isMobile"
  [title]="'Manage Rates' | translate"
  [hideBackIcon]="true"
></app-navbar>

<div class="main pt-2">
  <div class="manage-rates">
    <div class="container-fluid">
      <h2>{{ "Manage Rates" | translate }}</h2>
      <div class="nav-row">
        <div class="links">
          <!-- <a class="active">{{ "Group" | translate }}</a> -->
          <!-- <a routerLink="/manage-rates-private">Private</a> -->
        </div>
        <button class="btn btn-primary" routerLink="/rates-edit">
          <i class="fa fa-plus-circle"></i> {{ "Add New Rate" | translate }}
        </button>
      </div>
      <ng-container *ngIf="rates?.length > 0; else loading">
        <div class="list">
          <ul
            *ngFor="let rate of rates"
            [ngClass]="{ 'mobile-item': isMobile }"
          >
            <li class="title">
              <a [routerLink]="['/rates-edit', loggedInUserId, rate.uid]">{{
                rate.title
              }}</a>
            </li>
            <li class="item" [ngClass]="{ 'mobile-item': isMobile }">
              {{ rate.duration.split(' ')[0] + ' ' + (rate.duration.split(' ')[1] | translate) }}

            </li>
            <li class="item" [ngClass]="{ 'mobile-item': isMobile }">
              {{ rate.daysPerWeek }} {{ "Days/Week" | translate }}
            </li>
            <li class="item" [ngClass]="{ 'mobile-item': isMobile }">
              {{ rate.hrsPerDay }} {{ "Hour/Day" | translate }}
            </li>
            <li class="item" [ngClass]="{ 'mobile-item': isMobile }">
              ${{ rate.price }}
            </li>
            <li
              class="item available"
              [ngClass]="{ 'mobile-item': isMobile }"
              *ngIf="rate.discount?.length > 0"
            >
              {{ "Promo Code" | translate }}<br />{{ "Available" | translate }}
            </li>
            <li
              class="item not-available"
              [ngClass]="{ 'mobile-item': isMobile }"
              *ngIf="rate.discount?.length == 0"
            >
              {{ "Promo Code" | translate }}<br />{{
                "Not Available" | translate
              }}
            </li>
            <li class="item">
              <button
                class="btn btn-danger text-white"
                [ngClass]="{
                  'btn-disabled': !rate.isAllowedToDelete,
                  'mobile-btn': isMobile
                }"
                [disabled]="!rate.isAllowedToDelete"
                (click)="deleteRate(rate.uid)"
              >
                <span
                  [style.pointer-events]="'all'"
                  [matTooltip]="
                    !rate.isAllowedToDelete
                      ? ('This rate has a class associated with it. You cannot delete it.' | translate)
                      : ''
                  "
                  >{{ "Delete" | translate }}
                </span>
              </button>
            </li>
          </ul>
          <div class="no-rates" *ngIf="rates?.length === 0">
            {{ "No rates added yet." | translate }}
          </div>
        </div>
      </ng-container>
      <ng-template #loading>{{
        "No rates added yet." | translate
      }}</ng-template>
    </div>
  </div>
</div>
