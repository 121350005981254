import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FuseConfigService } from "@fuse/services/config.service";
import { ConfirmationModalComponent } from "app/shared/components/confirmation-modal/confirmation-modal.component";
import { AuthService } from "app/shared/services/auth.service";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: "manage-rates",
  templateUrl: "./manage-rates.component.html",
  styleUrls: ["./manage-rates.component.scss"],
})
export class ManageRatesComponent implements OnInit {
  rates: any[] = [];
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  loggedInUserId = "";
  isMobile = false;
  constructor(
    private authService: AuthService,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService
  ) {}

  ngOnInit() {
    this.breakpoint
    .observe([Breakpoints.Handset, Breakpoints.Tablet])
    .subscribe((o) => {
      this.isMobile = o.matches;

      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: this.isMobile,
          },
          toolbar: {
            hidden: this.isMobile,
          },
          footer: {
            hidden: this.isMobile,
          },
          sidepanel: {
            hidden: this.isMobile,
          },
        },
      };
    });
    this.afAuth.authState.subscribe((user) => {
      console.log("Logged in user:", user);
      this.loggedInUserId = user?.uid;

      this.afs
        .collection("users")
        .doc(this.loggedInUserId)
        .collection("rates", (ref) => ref.where("isGroup", "==", true))
        .valueChanges()
        .subscribe(async (rates) => {
          // Store the retrieved rates in a local variable
          this.rates = rates;
          for (let rate of this.rates) {
            rate.isAllowedToDelete = false;
            this.afs
              .collection("classes", (ref) =>
                ref.where("rateId", "==", rate.uid)
              )
              .valueChanges()
              .subscribe((classes) => {
                if (classes.length === 0) {
                  // If there's no class with the same rateId, set isAllowedToDelete to true
                  rate.isAllowedToDelete = true;
                } else {
                  // If there is a class with the same rateId, set isAllowedToDelete to false
                  rate.isAllowedToDelete = false;
                }
              });
          }

          console.log("Rates:", this.rates);
          console.log(typeof this.rates);
        });
    });
  }
  deleteRate(uid) {
    console.log("Deleting rate with uid:", uid);
    console.log("Logged in user id:", this.loggedInUserId);
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: "Delete Rate",
          text: "Are you sure you want to delete this rate?",
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.afs
            .collection("users")
            .doc(this.loggedInUserId)
            .collection("rates")
            .doc(uid)
            .delete()
            .then(() => {
              this.toastr.success("Rate deleted successfully!");
              console.log("Rate deleted successfully!");
            })
            .catch((error) => {
              this.toastr.error("Error deleting rate!");
              console.log("Error deleting rate:", error);
            });
        }
      });
  }
}
