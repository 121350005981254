<div class="class-scheduled-popup">
    <div class="class-scheduled-dialog" [ngClass]="{ 'mobile-dialog': isMobile }">
      <img *ngIf="code" class="done-img" src="assets/images/profile/dialog/done.png" alt="" />
      <h3>{{ code ? "Stripe Connected" : 'Stripe Connection Failed' | translate }}</h3>
      <p>
        {{
          code ?  "Now you can create your classes." : 'Please try again.' | translate
        }}
      </p>
      <div class="text-center w-100">
        <div
          class="btn btn-primary"
          [ngClass]="{
            'padding-mobile': !isMobile
          }"
          (click)="goToClass()"
        >
          {{ code ?  "Create New Class" : "Go to settings" | translate }}
        </div>
      </div>
    </div>
  </div>
  