<div id="auth" class="auth">
  <div class="container-fluid" style="position: relative">
    <img
      class="sweech-logo"
      style="position: absolute; top: 20px; left: 20px"
      src="assets/images/sweech-logo.png"
      alt=""
    />

    <div class="row align-items-center">
      <div class="col-md-5 col-sm-12 col-xs-12">
        <div class="auth-box pt-0" [ngClass]="{ 'auth-mobile': isMobile }">
          <h1 class="hq">{{ "Master Login" | translate }}</h1>
          <p>
            {{ "Please fill your detail to access your account." | translate }}
          </p>
          <form>
            <div class="form-group">
              <label>{{ "Email" | translate }}</label>
              <input
                type="email"
                class="form-control"
                id=""
                placeholder="Enter email"
                autocomplete="off"
                #email
                required
              />
            </div>
            <div class="form-group">
              <label>{{ "Password" | translate }}</label>
              <div class="passwordWithIcon">
                <input
                  [type]="hide ? 'text' : 'password'"
                  class="form-control"
                  id=""
                  placeholder="Password"
                  autocomplete="off"
                  #password
                  required
                />
                <mat-icon
                  style="cursor: pointer"
                  matSuffix
                  (click)="toggleHiddenPassword($event)"
                  >{{ hide ? "visibility" : "visibility_off" }}</mat-icon
                >
              </div>
            </div>
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="remember" />
              <label class="form-check-label" for="remember">{{
                "Remember me" | translate
              }}</label>
            </div>
            <button
              type="submit"
              class="btn btn-primary mb-3"
              (click)="Onsubmit(email.value, password.value)"
            >
              {{ "Sign in" | translate }}
            </button>
            <button
              (click)="authService.GoogleLogin()"
              type=""
              class="btn btn-default mb-3"
            >
              <img class="google-icon" src="assets/images/google.png" />
              {{ "Sign in with Google" | translate }}
            </button>
            <p class="auth-bottom-text">
              {{ "Don’t have an account?" | translate }}
              <a routerLink="/signup">{{ "Sign up" | translate }}</a>
            </p>
          </form>
        </div>
      </div>
      <div class="col-md-7" *ngIf="!isMobile">
        <img class="img-fluid" src="/assets/images/login.jpg" />
      </div>
    </div>
  </div>
</div>
