import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { MatDialog } from "@angular/material/dialog";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/compat/firestore";

import { RequestGameDialogComponent } from "./request-game-dialog/request-game-dialog.component";
import { Observable, of } from "rxjs";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { filter, map, switchMap, take } from "rxjs/operators";
import { AuthService } from "app/shared/services/auth.service";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import * as firebase from "firebase/compat/app";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

export interface Game {
  creationDateTS: Date;
  image: string;
  name: string;
  uid: string;
}
interface User {
  vat: string;
  profilePic: string;
  name: string;
  country: string;
  languages: string[];
  gameIds: string[];
  registerDateTS: Date;
  fiscalCode: string;
  discordLink: string;
  uid: string;
}
interface selectedGames {}

@Component({
  selector: "choose-game",
  templateUrl: "./choose-game.component.html",
  styleUrls: ["./choose-game.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ChooseGameComponent implements OnInit {
  gamesCollection: AngularFirestoreCollection<Game>;
  games$: Observable<Game[]>;
  uid$: Observable<string>;
  user: any;
  selectedGames: string[] = [];
  selectedGameName: string;

  selectedGameIds: string[] = [];
  currentPage = 1;
  gamesPerPage = 6;
  private itemsCollection: AngularFirestoreCollection<selectedGames>;
  gamesTeached = [];
  isMobile: any;
  isFromSettings = false;
  selectedGameData: any;
  constructor(
    private router: Router,
    private authService: AuthService,
    private firestore: AngularFirestore,
    private _fuseConfigService: FuseConfigService,
    private matDialog: MatDialog,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private breakpoint: BreakpointObserver,
    private route: ActivatedRoute

  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  onGameSelected(event: MatAutocompleteSelectedEvent) {
    const selectedGameName = event.option.value;
    const query = this.gamesCollection.ref.where(
      "name",
      "==",
      selectedGameName
    );
    query.get().then((querySnapshot) => {
      if (querySnapshot.empty) {
        console.log("No matching documents");
      } else {
        const selectedGame = querySnapshot.docs[0].data() as Game;
        this.selectedGames.push(selectedGame.uid);
        this.gamesTeached.push(selectedGame.name);
      }
    });
    this.selectedGameName = "";
  }

  ngOnInit(): void {
    this.breakpoint
    .observe([Breakpoints.Handset, Breakpoints.Tablet])
    .subscribe((o) => {
      this.isMobile = o.matches;  
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: true,
          },
          toolbar: {
            hidden: true,
          },
          footer: {
            hidden: true,
          },
          sidepanel: {
            hidden: true,
          },
        },
      };    
    });
    this.afAuth.authState
      .pipe(
        take(1),
        switchMap((user) => {
          this.user = user;
          if (user) {
            this.route.queryParams.subscribe((params) => {
              if (params.settings) {
                this.isFromSettings = params.settings;
                if (this.isFromSettings) {
                  this._fuseConfigService.config = {
                    layout: {
                      navbar: {
                        hidden: this.isMobile,
                      },
                      toolbar: {
                        hidden: this.isMobile,
                      },
                      footer: {
                        hidden: this.isMobile,
                      },
                      sidepanel: {
                        hidden: this.isMobile,
                      },
                    },
                  }; 
                }
              }
            });
            // Get a reference to the Firestore "users" collection
            const usersRef = this.afs.collection<User>("users");
            return usersRef
              .doc(user.uid)
              .get()
              .pipe(map((doc) => doc.data()));
          } else {
            // if no user logged in, return an observable that emits null
            return of(null);
          }
        })
      )
      .subscribe((userData) => {
        this.user = userData;
        console.log("user", this.user);
        this.fetchGames();
      });
  }

  // your fetchGames method and other methods...

  fetchGames() {
    this.gamesCollection = this.firestore.collection<Game>("games");
    this.games$ = this.gamesCollection
      .valueChanges()
      .pipe(
        map((games) =>
          games.filter((game) => !(this.user.gameIds ?? []).includes(game.uid))
        )
      );
  }

  requestGame() {
    this.matDialog.open(RequestGameDialogComponent);
  }
  get start(): number {
    return (this.currentPage - 1) * this.gamesPerPage;
  }

  get end(): number {
    return this.start + this.gamesPerPage;
  }

  get totalGames(): number {
    return this.selectedGameIds.length;
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  nextPage(): void {
    this.games$.pipe(map((games) => games.length)).subscribe((totalGames) => {
      if (this.currentPage < Math.ceil(totalGames / this.gamesPerPage)) {
        this.currentPage++;
      }
    });
  }

  onGameClicked(uid: string, game: any): void {
    console.log("clicked");
    if (this.selectedGames.includes(uid)) {
      // if the clicked game is already in the selectedGames array
      this.selectedGames = this.selectedGames.filter(
        (gameId) => gameId !== uid
      ); // remove the clicked game from the selectedGames array
      return;
    }
    this.selectedGames = [uid]; // replace the selectedGames array with a new array containing only the clicked game
    this.selectedGameData = game;
    this.gamesTeached = [game.name];
  }
  async updateUser(): Promise<void> {
    // const currentUser = await this.authService.getCurrentUser(); // get the current user
    // Save the user somewhere. Don't Save it to database here. Save it later on gaming curiculum. 
    localStorage.setItem('user_games', JSON.stringify({
      gameIds: this.selectedGames,
      gamesTeached: this.gamesTeached,
      game: this.selectedGameData
    }))
    const extras: NavigationExtras = {
      queryParams: {
        settings: this.isFromSettings,
      }
    }
    this.router.navigate(["/gaming-curriculm"], extras); // navigate to the next page
  }
}
