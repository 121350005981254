<div id="auth" class="auth">
  <div class="container-fluid">
    <img class="sweech-logo" src="assets/images/sweech-logo.png" alt="" />

    <div class="row" [ngClass]="{ 'mt-4': isMobile }">
      <div class="col-md-5">
        <div
          class="auth-box d-flex flex-column"
          [ngClass]="{ 'auth-mobile': isMobile }"
        >
          <h1 class="qaz">{{ "Master Sign up" | translate }}</h1>
          <p>
            {{ "Please fill your details to Create your account." | translate }}
          </p>
          <form>
            <div class="form-group">
              <label>{{ "Email" | translate }}</label>
              <input
                #email
                type="email"
                class="form-control"
                id=""
                placeholder="Enter email"
                autocomplete="off"
                [disabled]="verificationId"
              />
            </div>
            <div class="form-group">
              <label>{{ "Password" | translate }}</label>
              <div class="passwordWithIcon">
                <input
                  #password
                  [type]="hide ? 'text' : 'password'"
                  class="form-control"
                  id=""
                  placeholder="Password"
                  autocomplete="off"
                  [disabled]="verificationId"
                />
                <mat-icon
                  style="cursor: pointer"
                  matSuffix
                  (click)="toggleHiddenPassword($event)"
                  >{{ hide ? "visibility" : "visibility_off" }}</mat-icon
                >
              </div>
              <div
                *ngIf="
                  signUpForm.controls.password.invalid &&
                  (signUpForm.controls.password.dirty ||
                    signUpForm.controls.password.touched)
                "
              >
                <div *ngIf="signUpForm.controls.password.errors.required">
                  {{ "Password is required." | translate }}
                </div>
                <div *ngIf="passwordRequirements">
                  {{
                    "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 special character. "
                      | translate
                  }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ "Confirm Password" | translate }}</label>
              <div class="passwordWithIcon">
                <input
                  #confirmPassword
                  [type]="hidee ? 'text' : 'password'"
                  class="form-control"
                  id=""
                  placeholder="Confirm Password"
                  autocomplete="off"
                  [disabled]="verificationId"
                />
                <mat-icon
                  style="cursor: pointer"
                  matSuffix
                  (click)="toggleHiddenPassword2($event)"
                  >{{ hidee ? "visibility" : "visibility_off" }}</mat-icon
                >
              </div>
            </div>
            <div class="form-group" *ngIf="!verificationId && defaultCountry">
              <ngx-intl-tel-input
                class="w-100"
                [cssClass]="'form-control w-100 phone-input'"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [phoneValidation]="true"
                name="phone"
                [(ngModel)]="phone"
                [defaultCountry]="defaultCountry"
                [preferredCountries]="[defaultCountry]"
              ></ngx-intl-tel-input>
            </div>

            <div class="form-group" *ngIf="verificationId">
              <div>
                <label for="code">{{
                  "Enter Verification Code" | translate
                }}</label>
                <input
                  [(ngModel)]="code"
                  id="code"
                  type="text"
                  name="code"
                  class="form-control"
                />
                <!-- <button (click)="verifyPhoneNumber()">
                  {{ "Verify Phone Number" | translate }}
                </button> -->
              </div>
            </div>
            <div id="recaptcha-container"></div>
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="remember"
                [checked]="agreeToTerms"
                (change)="onCheckboxChange($event)"
                [disabled]="verificationId"
              />
              <label class="form-check-label" for="remember"
                >{{ "By creating an account you have to agree with" | translate
                }}<a
                  href="https://sweech.gg/termini-generali-e-condizioni-duso/"
                  target="_blank"
                  class="alert-link"
                >
                  {{ "our terms & conditions." | translate }}</a
                ></label
              >
            </div>
            <button
              type="submit"
              class="btn btn-primary mb-3"
              [disabled]="isLoading || !agreeToTerms"
              (click)="verificationId ? verifyPhoneNumber() : onSubmit()"
            >
              <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i>
              {{ !verificationId ? "Sign Up" : ("Confirm Code" | translate) }}
            </button>

            <button
              type="button"
              class="btn btn-default mb-3"
              [disabled]="!agreeToTerms || isBlockGoogleLogin"
              (click)="authService.GoogleSignUp()"
            >
              <img class="google-icon" src="assets/images/google.png" />
              {{ "Sign Up with Google" | translate }}
            </button>

            <div *ngIf="!agreeToTerms" class="alert alert-warning">
              {{
                "Please accept the terms and conditions before signing up."
                  | translate
              }}
            </div>

            <p class="auth-bottom-text" style="margin-bottom: 3rem">
              {{ "Already have an account?" | translate }}
              <a routerLink="/login">{{ "Sign In" | translate }}</a>
            </p>
          </form>
        </div>
      </div>
      <div class="col-md-7" *ngIf="!isMobile">
        <img class="img-fluid" src="/assets/images/login.jpg" />
      </div>
    </div>
  </div>
</div>
