import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, of } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { navigation } from "app/navigation/navigation";
import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationTurkish } from "app/navigation/i18n/tr";
import { LoginService } from "./main/authorizations/login/login.service";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FirestoreService } from "./shared/services/firestore.service";
import {
  BreakpointObserver,
  Breakpoints,
  MediaMatcher,
} from "@angular/cdk/layout";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { BottomNavComponent } from "./shared/components/bottom-nav/bottom-nav.component";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  userFirestore: any;
  private _unsubscribeAll: Subject<any>;
  isMobile: boolean = false;
  user;
  public userFirestore$: Observable<any>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    public LoginService: LoginService,
    private translate: TranslateService,
    private firestoreService: FirestoreService,
    private afAuth: AngularFireAuth,
    private bottomSheet: MatBottomSheet,
    media: MediaMatcher,
    private breakpoint: BreakpointObserver,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.navigation = navigation;
    this._fuseNavigationService.register("main", this.navigation);
    this._fuseNavigationService.setCurrentNavigation("main");
    // this._translateService.addLangs(["en", "tr"]);
    // this._translateService.setDefaultLang("en");
    // this._fuseTranslationLoaderService.loadTranslations(
    //   navigationEnglish,
    //   navigationTurkish
    // );
    // this._translateService.use("en");

    translate.addLangs(["en", "it"]);
    translate.setDefaultLang('it');
    const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|it/) ? browserLang : "en");
    translate.use('it');

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add("is-mobile");
    }
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Small])
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.userFirestore$ = this.afAuth.authState.pipe(
      takeUntil(this._unsubscribeAll),
      switchMap((user) => {
        if (user) {
          return this.firestoreService.doc$("users/" + user.uid);
        } else {
          return of(null);
        }
      })
    );
    this.afAuth.authState
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user) => {
        console.log(user);
        if (user) {
          this.firestoreService
            .doc$("users/" + user.uid)
            .subscribe((user: any) => {
              this.userFirestore = user;
              if (user && user?.enabled == false) {
                this.afAuth.signOut();
                return;
              }
              console.log(user.settings.selectedLanguage);
              this.translate.use("it");
            });
        } else {
          this.userFirestore = null;
        }
      });
  }

  ngOnInit() {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === "boxed") {
          this.document.body.classList.add("boxed");
        } else {
          this.document.body.classList.remove("boxed");
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith("theme-")) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
