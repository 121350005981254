<h2 mat-dialog-title>{{ "Verify Your Account" | translate }}</h2>
<div
  mat-dialog-content
  class="mat-typography d-flex flex-column align-items-center style-verify-account"
>
  <mat-label>
    {{ "Are you over 18?" | translate }}
  </mat-label>
  <mat-radio-group [(ngModel)]="isOver18">
    <mat-radio-button value="yes">Yes</mat-radio-button>
    <mat-radio-button value="no">No</mat-radio-button>
  </mat-radio-group>

  <ng-container *ngIf="isOver18 === 'yes' || isOver18 === 'no'">
    <mat-form-field appearance="outline">
      <mat-label>Type of document</mat-label>
      <mat-select [(ngModel)]="selectedDocumentType">
        <mat-option *ngFor="let doc of documentType" [value]="doc">{{
          doc
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="isOver18 === 'no'">
    <h4>{{ "Upload your document" | translate }}</h4>
    <div class="file-input-wrapper">
      <button mat-raised-button type="button" (click)="fileInputUser.click()">
        {{ "Choose Your File" | translate }}
      </button>
      <input
        #fileInputUser
        type="file"
        style="display: none"
        (change)="onFileChange($event, 'user')"
        accept="image/*, application/pdf"
      />
      <span>{{ fileToUploadUser?.name || "No file chosen" }}</span>
    </div>
    <h4>{{ "Upload parent's documents" | translate }}</h4>
    <div class="file-input-wrapper">
      <button mat-raised-button type="button" (click)="fileInputParent.click()">
        {{ "Choose Parent's File" | translate }}
      </button>
      <input
        #fileInputParent
        type="file"
        style="display: none"
        (change)="onFileChange($event, 'parent')"
        accept="image/*, application/pdf"
      />
      <span>{{ fileToUploadParent?.name || "No parent's file chosen" }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="isOver18 == 'yes'">
    <h4>{{ "Upload your document" | translate }}</h4>

    <div class="file-input-wrapper">
      <button mat-raised-button type="button" (click)="fileInput.click()">
        {{ "Choose a File" | translate }}
      </button>
      <input
        #fileInput
        type="file"
        style="display: none"
        (change)="onFileChange($event, 'user')"
        accept="image/*, application/pdf"
      />
      <span>{{( (fileToUploadUser?.name)?.substring(0,20) || "No file chosen" ) }}</span>
    </div>
  </ng-container>
</div>
<mat-dialog-actions align="end">
  <button
    mat-button
    *ngIf="
      (isOver18 === 'yes' && fileToUploadUser != null) ||
      (isOver18 === 'no' &&
        fileToUploadUser != null &&
        fileToUploadParent != null)
    "
    (click)="isParentDocument = isOver18 === 'no'; uploadFile()"
  >
    {{ "Upload" | translate }}
  </button>
  <button mat-button mat-dialog-close>{{ "Cancel" | translate }}</button>
</mat-dialog-actions>
