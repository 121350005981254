import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ElementRef, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { Subject } from "rxjs";
import {
  catchError,
  filter,
  map,
} from "rxjs/operators";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { navigation } from "app/navigation/navigation";
import { LoginService } from "app/main/authorizations/login/login.service";
import { toolbarService } from "./toolbar.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { NavigationEnd, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { logoutConfirmDialogComponent } from "app/layout/confirm-dialog/confirm-dialog.component";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";

interface User {
  aboutme: string;
  profilePic: string;
  name: string;
  country: string;
  languages: string[];
  gameIds: string[];
  registerDateTS: Date;
}

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit {
  dialogOpen = false;
  profilePicture$: Observable<string>;
  content: any;
  users: any;
  myDetail: any;
  policyContent: any;
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  user: any;
  noRecord: boolean = false;
  searchUser: boolean = false;
  isSearching: boolean = false;
  isFocus: boolean = false;
  searchSubscribed: boolean = false;
  searchText: string = "";
  type: "PRIVACY";

  @ViewChild("SearchInput") SearchInput: ElementRef;

  // isUserLoggedIn: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;
  currentRoute$: Observable<string>;
  unreadMessagesCount$: Observable<number>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private store: Store<any>,
    public loginService: LoginService,
    public toolbarService: toolbarService,
    private _matSnackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.afAuth.authState.subscribe((user) => {
      this.user = user;
      if (user) {
        const usersRef = this.afs.collection<User>("users");
        this.unreadMessagesCount$ = this.toolbarService.getUnreadMessagesCount(this.user.uid).pipe(catchError((err) => {
          console.error(err);
          return [];
        }));

        this.profilePicture$ = usersRef
          .doc(user.uid)
          .get()
          .pipe(map((doc) => doc.data().profilePic));
      }
    });
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.currentRoute$ = router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e: NavigationEnd) => e.url)
    );
  }

  ngOnInit() {

  }

  openDialog() {
    this.dialogOpen = true;
    const dialogRef = this.dialog.open(logoutConfirmDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      this.dialogOpen = false;
      if (result) {
      }
    });
  }
}
