import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";

export class DiscountModel {
  uid: string = "";
  code = "";
  percent = 0;
  count = 0;
  type = "";
  date: Date = new Date();
}

@Component({
  selector: "app-rate-discount",
  templateUrl: "./rate-discount.component.html",
  styleUrls: ["./rate-discount.component.scss"],
})
export class RateDiscountComponent {
  @Input() discountsList: DiscountModel[] = [];
  @Input() selectedDiscounts: DiscountModel[] = [];
  @Input() isDiscountAvailableForClass: boolean = false;
  @Output() onSave: EventEmitter<DiscountModel[]> = new EventEmitter();
  @Output() error: EventEmitter<string> = new EventEmitter();

  isMaster = true; // I assumed a boolean, replace with the correct type.

  constructor(private dialog: MatDialog) {}
  updateDiscountDialog(discount: DiscountModel): void {
    const isCurrentlySelected = this.isSelected(discount);

    const dialogRef = this.dialog.open(UpdateDiscountDialogComponent, {
      width: "500px",
      data: discount,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Find and update the original discount object
        const index = this.discountsList.findIndex((d) => d.uid === result.uid);
        // console.log(this.selectedDiscounts);
        if (index !== -1) {
          this.discountsList[index] = result;

          if (isCurrentlySelected) {
            this.selectedDiscounts = this.selectedDiscounts.map((d) =>
              d.uid === result.uid ? result : d
            );
            this.onSave.emit([...this.selectedDiscounts]);
          } // Emit a copy of the updated list
        }
      }
    });
  }

  toggleDiscount(discount: DiscountModel, val: boolean): void {
    if (val) {
      if (this.isMaster && this.selectedDiscounts.length > 0) {
        // Prevent toggling on if another discount is already selected
        this.error.emit(
          "You can't toggle on this discount because another discount is already selected"
        );
        return;
      }
      this.selectedDiscounts = [discount];
    } else {
      this.selectedDiscounts = this.selectedDiscounts.filter(
        (d) => d.uid !== discount.uid
      );
    }
    console.log(this.selectedDiscounts);
    this.onSave.emit(this.selectedDiscounts);
  }

  isSelected(discount: DiscountModel) {
    return this.selectedDiscounts.some((ele) => ele.uid === discount.uid);
  }
  canToggle(discount: DiscountModel): boolean {
    if (!this.isMaster) {
      return true;
    }
    if (this.isDiscountAvailableForClass) {
      return false;
    }
    // If no discounts are selected, or the current discount is already selected or isDiscount not available for class, allow toggling
    return (
      this.selectedDiscounts.length === 0 ||
      this.isSelected(discount) ||
      this.isDiscountAvailableForClass
    );
  }
}

@Component({
  selector: "app-update-discount-dialog",
  templateUrl: "./update-discount-dialog.component.html",
  styles: [
    `
      :host mat-form-field {
        margin-top: 1rem;
        margin-right: 0.5rem;
      }
    `,
  ],
})
export class UpdateDiscountDialogComponent {
  types = ["Students", "Days"];
  countMin = 1;
  countMax = 50;

  constructor(
    public dialogRef: MatDialogRef<UpdateDiscountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DiscountModel
  ) {
    this.updateCountRange(); // Initialize count range based on the type
  }

  updateCountRange(): void {
    this.countMax = this.data.type === "Students" ? 50 : 30;
    // this.validateCount(); // Validate count whenever the type changes
  }

  validatePercentage(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = parseInt(inputElement.value, 10);

    if (inputElement.value === "") {
      // If input is empty, do not adjust
      return;
    }

    if (isNaN(value)) {
      // If input is not a number, reset to minimum
      inputElement.value = "1";
    } else {
      // Adjust value within range
      inputElement.value = Math.min(Math.max(value, 1), 50).toString();
    }

    // Update model
    this.data.percent = parseInt(inputElement.value, 10);
  }

  validateCount(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = parseInt(inputElement.value, 10);

    if (inputElement.value === "") {
      // If input is empty, do not adjust
      return;
    }

    if (isNaN(value)) {
      // If input is not a number, reset to minimum count
      inputElement.value = this.countMin.toString();
    } else {
      // Adjust value within range
      inputElement.value = Math.min(
        Math.max(value, this.countMin),
        this.countMax
      ).toString();
    }

    // Update model
    this.data.count = parseInt(inputElement.value, 10);
  }

  private adjustInputValue(event: Event, min: number, max: number): void {
    let input = event.target as HTMLInputElement;
    let value = parseInt(input.value, 10);

    if (isNaN(value)) {
      input.value = min.toString();
    } else {
      input.value = Math.min(Math.max(value, min), max).toString();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save(): void {
    // Values are already constrained by min and max in the template
    this.dialogRef.close(this.data);
  }
}
