<div class="card">
    <img class="card-image" [src]="imageUrl" alt="Card image">
    <div class="card-overlay">
      <div class="card-title">{{ title }}</div>
      <div class="card-subtitle">
        <!-- <p class="white-section">{{ subtitleGreen }}</p> -->
        <p class="white-section">
          
            <span class="green-section">{{startDate?.toDate() | date: 'EEEE': '': 'it-IT' }} </span>  ({{
              formatTime(startTime) }} - {{ formatTime(endTime) }})</p>
      </div>
    </div>
  </div>
  