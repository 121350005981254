import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import Stripe from "stripe";
const stripe = new Stripe(environment.stripe_secret, { apiVersion: "2022-11-15" });
@Injectable({
  providedIn: "root",
})
export class StripeWService {
  constructor() {}

  async createCustomer(email: string, name: string) {
    return await stripe.customers.create({
      email: email,
      name: name,
    });
  }

  async getCustomer(custId: string) {
    return await stripe.customers.retrieve(custId);
  }

  async createCardToken(
    card: Stripe.TokenCreateParams.Card,
    customerId: string
  ) {
    return await stripe.tokens.create({
      card: card,
      customer: customerId,
    });
  }

  createPaymentMethod(card: Stripe.PaymentMethodCreateParams.Card1) {
    return stripe.paymentMethods.create({
      type: "card",
      card: card,
    });
  }

  checkIfPaymentMethodAlreadyExist() {
    return stripe.paymentMethods.list({
      customer: "cus_J4fU0R9qQ9YzGj",
      type: "card",
    });
  }
  checkIfPaymentMethodAlreadyExistUsingCard(card: any) {
    return stripe.paymentMethods.list({
      customer: "cus_J4fU0R9qQ9YzGj",
      type: "card",
    
    });
  }
  async attachPaymentMethodtoCustomer(paymentMethodId: string, customerId: string) {
    return await stripe.paymentMethods.attach(paymentMethodId, {customer: customerId});
  }

  async createCharge(amount: number, token: string, currency: string = "usd", description: string = "") {
    return await stripe.charges.create({
      amount,
      currency,
      source: token,
      description,
    });
  }
  

  async detachCard(paymethodMethodId: string) {
    return await stripe.paymentMethods.detach(paymethodMethodId);
  }
}
