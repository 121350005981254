import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { RequestGameDialogComponent } from "../authorizations/choose-game/request-game-dialog/request-game-dialog.component";
import { addProductDialogComponent } from "./add-product-dialog/add-product-dialog.component";
import { cardDetailDialogComponent } from "./card-detail-dialog/card-detail-dialog.component";
import { deleteConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { selectPaymentDialogComponent } from "./select-payment-dialog/select-payment-dialog.component";
import { AuthService } from "app/shared/services/auth.service";
import { FirestoreService } from "app/shared/services/firestore.service";
import { ConfirmationModalComponent } from "app/shared/components/confirmation-modal/confirmation-modal.component";
import { StripeWService } from "app/shared/services/stripe-w.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
declare var $: any;
import { validateIBAN } from "ngx-iban-validator";
import { FuseConfigService } from "@fuse/services/config.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { VerifyAccountComponent } from "./verify-account/verify-account.component";
import { environment } from "environments/environment";

@Component({
  selector: "settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  public isVisible = false;
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  userId: string = "";
  user: any = {};

  ibanForm: FormGroup;
  isMobile: boolean = false;

  toggleVisibility() {
    this.isVisible = !this.isVisible;
  }

  constructor(
    private matDialog: MatDialog,
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private stripeService: StripeWService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService
  ) {}

  ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    this.ibanForm = this.formBuilder.group({
      iban: ["", [Validators.required, validateIBAN]],
    });
    this.authService.user.subscribe((usr) => {
      this.userId = usr.uid;
      this.firestoreService.doc$(`users/${this.userId}`).subscribe((user) => {
        this.user = user;
        this.user.settings = this.user.settings || {};
        this.user.settings.selectedLanguage =
          this.user.settings.selectedLanguage || "en";
        this.user.vat = this.user.vat || "";
        this.ibanForm.patchValue({
          iban: this.user.iban || "",
        });
      });
    });
  }

  addProduct() {
    this.matDialog.open(addProductDialogComponent, {
      data: { userId: this.userId },
      width: this.isMobile ? "350px" : "700px",
    });
  }
  connectDiscord() {
    this.connectWithDiscord();
  }
  connectWithDiscord() {
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=1147463387748499538&redirect_uri=http://localhost:4200/discord/callback&response_type=code&scope=identify%20guilds.join`;
  }
  selectPayment() {
    this.matDialog.open(cardDetailDialogComponent);
  }

  supportDialog() {
    this.matDialog.open(RequestGameDialogComponent);
  }
  connectStripe() {
    const clientId = environment.stripe_account_id;
    const redirectUri = "https://master.sweech.gg/settings/connect-stripe";
    window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${redirectUri}`;
  }
  onDeleteAccount() {
    const dialogRef = this.matDialog.open(deleteConfirmDialogComponent, {
      width: "350px",
      data: {
        message: this.translate.instant(
          "Are you sure you want to delete your account?"
        ),
        user: this.user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Delete user data from Firebase here
        this.firestoreService.update(`users/${this.userId}`, {
          enabled: false,
        });
      }
    });
  }

  saveIBAN() {
    this.firestoreService
      .update(`users/${this.userId}`, {
        iban: this.ibanForm.value.iban,
      })
      .then(() => {
        this.toastr.success(
          this.translate.instant("Settings updated successfully")
        );
      })
      .catch(() => {
        this.toastr.error(this.translate.instant("Something went wrong"));
      });
  }
  removeCard(card: string, i: number) {
    const dialogRef = this.matDialog.open(ConfirmationModalComponent, {
      data: {
        title: "Remove Card",
        text: "Are you sure you want to delete this card?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Delete card from Stripe here
        this.user.card.splice(i, 1);
        this.user.stripePaymentMethodId.splice(i, 1);
        this.user.fingerprints.splice(i, 1);
        this.firestoreService
          .update(`users/${this.userId}`, {
            card: this.user.card,
            stripePaymentMethodId: this.user.stripePaymentMethodId,
            fingerprints: this.user.fingerprints,
          })
          .then(() => {
            this.stripeService.detachCard(this.user.stripePaymentMethodId[i]);
          });
      }
    });
  }
  async verifyAccount() {
    if (this.user.isVerified != 0) {
      return;
    }
    await this.matDialog.open(VerifyAccountComponent, {
      data: {
        user: this.user,
      },
      width: "500px",
    });
  }

  async setSetting() {
    try {
      await this.firestoreService.update(`users/${this.userId}`, {
        settings: this.user.settings,
      });
      this.translate.use(this.user.settings.language);
      this.toastr.success(
        this.translate.instant("Settings updated successfully")
      );
    } catch (e) {
      this.toastr.error(this.translate.instant("Something went wrong"));
    }
  }

  async setVatFiscal() {
    try {
      await this.firestoreService.update(`users/${this.userId}`, {
        vat: this.user.vat,
        fiscalCode: this.user.fiscalCode,
      });
      this.toastr.success(
        this.translate.instant("Settings updated successfully")
      );
    } catch (e) {
      this.toastr.error(this.translate.instant("Something went wrong"));
    }
  }
}
