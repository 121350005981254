import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
} from "@angular/material/legacy-snack-bar";
declare var $: any;

@Component({
  selector: "referrals",
  templateUrl: "./referrals.component.html",
  styleUrls: ["./referrals.component.scss"],
})
export class ReferralsComponent implements OnInit {
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  value = "";

  constructor(private _snackBar: MatSnackBar) {}

  ngOnInit() {}

  openSnackBar() {
    this._snackBar.open("Copied to clipboard", "Ok", {
      duration: 3000,
    });
  }
}
