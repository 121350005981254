import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
declare var $: any;

@Component({
  selector: "private-class-detail",
  templateUrl: "./private-class-detail.component.html",
  styleUrls: ["./private-class-detail.component.scss"],
})
export class PrivateClassDetailComponent implements OnInit {
  groupBtn: Boolean = false;

  constructor() {}

  ngOnInit() {}

  TabChanged(tab = { index: 0 }) {
    if (tab.index !== 0) {
      this.groupBtn = true;
    } else {
      this.groupBtn = false;
    }
  }
}
