import {
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { environment } from "environments/environment";
import { AuthService } from "../../../shared/services/auth.service";
import * as firebase from "firebase/compat/app";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { TranslateService } from "@ngx-translate/core";

const CLIENT_SECRET = environment.clientSecret;

@Component({
  selector: "signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
  // encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class SignUpComponent implements OnInit {
  passwordsMatchError: string = "";

  @ViewChild("password") password: ElementRef;
  @ViewChild("confirmPassword") confirmPassword: ElementRef;
  @ViewChild("email") email: ElementRef;
  hide: boolean = false;
  hidee: boolean = false;
  signUpForm: FormGroup;
  isSubmitting: boolean = false;

  submitted = false;
  agreeToTerms: boolean = false;
  isLoading: boolean = false;
  phone: any;
  verificationId: string;
  code: string;
  isBlockGoogleLogin = false;
  defaultCountry: string = "";
  isMobile: boolean = false;

  constructor(
    public authService: AuthService,
    private _fuseConfigService: FuseConfigService,
    private breakpoint: BreakpointObserver,
    private formBuilder: FormBuilder,
    private afAuth: AngularFireAuth,
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }
  onCheckboxChange(event) {
    this.agreeToTerms = event.target.checked;
  }

  ngOnInit(): void {
    this.breakpoint
    .observe([Breakpoints.Handset, Breakpoints.Tablet])
    .subscribe((o) => {
      this.isMobile = o.matches;

      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: true,
          },
          toolbar: {
            hidden: true,
          },
          footer: {
            hidden: true,
          },
          sidepanel: {
            hidden: true,
          },
        },
      };
    });
    this.signUpForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          this.passwordRequirementsValidator(),
        ],
      ],
      confirmPassword: ["", [Validators.required]],
    });
    this.http
      .get("https://ipinfo.io?token=c33841b0e98713")
      .subscribe((resp: any) => {
        if (resp) {
          this.defaultCountry = resp.country.toLowerCase();
          console.log(resp);
        }
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.signUpForm.controls;
  }
  private passwordRequirementsValidator() {
    return (control: FormControl) => {
      const password = control.value;
      const hasMinimumLength = password && password.length >= 8;
      const hasUppercaseLetter = /[A-Z]/.test(password);
      const hasLowercaseLetter = /[a-z]/.test(password);
      const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
        password
      );

      if (
        hasMinimumLength &&
        hasUppercaseLetter &&
        hasLowercaseLetter &&
        hasSpecialCharacter
      ) {
        return null;
      } else {
        return { passwordRequirements: true };
      }
    };
  }

  onSubmit() {
    this.isBlockGoogleLogin = true;
    const emailValue = this.email.nativeElement.value;
    const passwordValue = this.password.nativeElement.value;
    const confirmPasswordValue = this.confirmPassword.nativeElement.value;
    const hasMinimumLength = passwordValue && passwordValue.length >= 8;
    const hasUppercaseLetter = /[A-Z]/.test(passwordValue);
    const hasLowercaseLetter = /[a-z]/.test(passwordValue);
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
      passwordValue
    );
    if (this.phone) this.submitted = true;
    if (emailValue == undefined || emailValue == null || emailValue == "") {
      alert("Enter an Email and password");
      this.isLoading = false;
    } else {
      if (
        hasMinimumLength &&
        hasUppercaseLetter &&
        hasLowercaseLetter &&
        hasSpecialCharacter
      ) {
        if (passwordValue !== confirmPasswordValue) {
          this.passwordsMatchError = "Passwords do not match";
          alert(this.passwordsMatchError);
          return;
        } else {
          this.isLoading = true;
          console.log(this.phone);
          this.authService
            .SignUp(emailValue, passwordValue, this.phone.e164Number)
            .then((v) => {
              this.sendVerificationCode();
            }).catch((err) => {
              this.isLoading = false;
            })
          return;
        }
      } else {
        alert(
         this.translate.instant("Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 special character.")
        );
      }

      // stop here if form is invalid
      if (this.signUpForm.invalid) {
        this.isLoading = false;
        return;
      }
    }

    // call your API to register the user
    console.log(this.signUpForm.value);
  }
  async sendVerificationCode() {
    try {
      const appVerifier = new firebase.default.auth.RecaptchaVerifier(
        "recaptcha-container"
      );
      this.verificationId = (
        await this.afAuth.signInWithPhoneNumber(this.phone.e164Number, appVerifier)
      ).verificationId;
      this.isLoading = false;
    } catch (e) {
      console.log(e);
    }
  }

  async verifyPhoneNumber() {
    this.isLoading = true;
    const credential = firebase.default.auth.PhoneAuthProvider.credential(
      this.verificationId,
      this.code
    );
    // Link phone number to the current user
    await this.afAuth.currentUser
      .then((user) => user.linkWithCredential(credential))
      .catch((error) => {
        // Handle Errors here.
        console.log(error.message);
        this.isBlockGoogleLogin = false;
      });
    console.log("Phone number is verified.");
    this.isLoading = false;
    this.router.navigate(["signup-detail"]);
  }
  toggleHiddenPassword(e) {
    e.stopPropagation();
    this.hide = !this.hide;
  }

  toggleHiddenPassword2(e) {
    e.stopPropagation();
    this.hidee = !this.hidee;
  }
}
