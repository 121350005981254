<h2 mat-dialog-title>{{"Update Discount" | translate}}</h2>
<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>{{"Percentage" | translate}}:</mat-label>
    <input matInput [(ngModel)]="data.percent" (input)="validatePercentage($event)" type="number">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{"Count" | translate}}:</mat-label>
    <input matInput [(ngModel)]="data.count" (input)="validateCount($event)" type="number">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{"Type" | translate}}:</mat-label>
    <mat-select [disabled]="true" [(value)]="data.type" (selectionChange)="updateCountRange()">
      <mat-option *ngFor="let type of types" [value]="type">{{type}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">{{"Cancel" | translate}}</button>
  <button mat-button (click)="save()">{{"Save" | translate}}</button>
</div>
