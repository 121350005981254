import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appScrollHorizontal]'
})
export class ScrollHorizontalDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('wheel', ['$event'])
  onWheelEvent(e: WheelEvent) {
    e.preventDefault(); // prevent default vertical scroll

    const delta = e.deltaY;
    let scrollLeft = this.el.nativeElement.scrollLeft;
    scrollLeft = scrollLeft + delta;
    this.renderer.setProperty(this.el.nativeElement, 'scrollLeft', scrollLeft);
  }
}
