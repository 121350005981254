import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { EarningsComponent } from "./earnings.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";

const routes = [
  {
    path: "earnings",
    component: EarningsComponent,
  },
];

@NgModule({
  declarations: [EarningsComponent],
  imports: [RouterModule.forChild(routes), FuseSharedModule, MatTabsModule],
})
export class EarningsModule {}
