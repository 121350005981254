import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/compat/firestore";
import {
  FormsModule,
  FormBuilder,
  UntypedFormGroup,
  Validators,
  FormGroup,
  FormControl,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { v4 as uuidv4 } from "uuid";

import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Observable,
  Subject,
  distinctUntilChanged,
  forkJoin,
  mergeMap,
  of,
  takeUntil,
} from "rxjs";
import { calendarDialogComponent } from "./calendar/calendar-dialog.component";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { DiscountModel } from "../rate-discount/rate-discount.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";
declare var $: any;

function numericValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isNumeric = /^\d+(\.\d{1,2})?$/.test(control.value);
    return isNumeric ? null : { numeric: { value: control.value } };
  };
}
function validateDecimalPlaces(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = /^\d{0,5}(\.\d{0,2})?$/.test(control.value);
    return valid ? null : { invalidDecimal: { value: control.value } };
  };
}
function validatePriceRange(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = parseFloat(control.value);
    if (value === 0 || (value >= 19.99 && value <= 10000)) {
      return null;
    }
    return { invalidPriceRange: true };
  };
}

@Component({
  selector: "rates-edit",
  templateUrl: "./rates-edit.component.html",
  styleUrls: ["./rates-edit.component.scss"],
})
export class RatesEditComponent implements OnInit, OnDestroy {
  public rateForm: FormGroup;
  ratesCollection: any;

  content: any;
  users: any;
  isLoggedin: Boolean = false;
  btnDisabled: boolean = false;
  loggedInUserId: string;
  isHidden: boolean = false;
  // to calculate
  totalLessons = 0;
  serviceCostPerStd = 0;
  hourlyPricePerStd = 0;
  hourlyPricePerStdAfterDate = 0;
  pricePerClass = 0.0;
  vat = "22.0";
  fullEarning = 0.0;
  isGroup = true;
  disabled = false;

  rateId: string = "";
  userId: string = "";
  isEdit = false;

  _discounts: DiscountModel[] = [
    { uid: "1", code: "W2xyB", percent: 25, count: 15, type: "Students", date: new Date() },
    {
      uid: "2",
      code: "10x15zy",
      percent: 15,
      count: 10,
      type: "Days",
      date: new Date()
    },
  ];
  discounts = [];
  selectedDiscounts = [];
  isMobile: boolean = false;
  games$ = new Subject<any>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  discountValue: number | null = null;
  classes = [];
  isDiscountAvailableForClass = false;
  constructor(
    private afs: AngularFirestore,
    private matDialog: MatDialog,
    private afAuth: AngularFireAuth,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService
  ) {
    this.discounts = Array.from({ length: 10 }, (_, i) => ({
      name: `${(i + 1) * 5}%`,
      value: (i + 1) * 5,
    }));
  }
  discountUpdated() {
    console.log("Selected Discount:", this.discountValue);
    // Add your logic here
    this.updateAfterDiscountPrice();
  }

  ngOnDestroy() {
    this.destroy$.next(false);
    this.destroy$.unsubscribe();
  }
  toggleVisibility() {
    this.isHidden = !this.isHidden;
    if (this.isEdit) {
      this.afs
        .doc(`users/${this.loggedInUserId}/rates/${this.rateId}`)
        .update({
          hidden: this.isHidden,
        })
        .then(() => {
          this.toastr.success(
            this.translate.instant("Rate hidden successfully")
          );
        });
    }
  }
  discountsUpdated(discounts: DiscountModel[]) {
    // Clear the current discount value
    this.discountValue = 0;
    this.selectedDiscounts = discounts;
    console.log(this.selectedDiscounts);
    if (discounts.length === 1) {
      // Assuming there's only one discount selected
      const selectedDiscount = discounts[0];
      this.discountValue = selectedDiscount.percent;
      this.updateAfterDiscountPrice();
    }
  }

  onSubmit() {
    try {
      this.disabled = true;
      const data = this.rateForm.getRawValue();
      console.log(data);
      const uid = this.isEdit ? this.rateId : uuidv4();
      this.afs
        .collection("users")
        .doc(this.loggedInUserId)
        .collection("rates")
        .doc(uid)
        .set({
          creationDateTS: new Date(),
          description: data.title,
          duration: data.duration + " Months",
          fullEarning: Number(data.fullEarning),
          hourlyPricePerStd: Number(data.pricePerStudent),
          hrsPerDay: Number(data.hoursPerDay[0]),
          isGroup: true,
          months: Number(data.duration),
          noOfLessons: Number(data.totalLessons),
          price: Number(data.price),
          pricePerClass: Number(data.pricePerClass),
          pricePerStdAfterDate: Number(data.pricePerStudentAfter),
          serviceCostPerStd: Number(data.serviceCost),
          title: data.title,
          daysPerWeek: Number(data.daysPerWeek),
          hoursPerDay: data.hoursPerDay,
          totalLessons: Number(data.totalLessons),
          hidden: this.isHidden,
          uid: uid,
          discounts: this.selectedDiscounts,
          gamesForRate: data.gamesForRate,
          discount: this.discountValue,
          isDiscountActive: data.isDiscountActive,
          afterDiscountPrice: data.afterDiscountPrice,
        })
        .then(() => {
          this.disabled = false;
          this.router.navigate(["/manage-rates"]);
        })
        .catch((e) => {
          this.disabled = false;
          this.toastr.error(e.message);
        });
    } catch (e) {
      this.disabled = false;

      this.toastr.error(this.translate.instant("Some error occured"));
    }
  }

  ngOnInit() {
    // this.ratesCollection = this.afs
    //   .collection("users")
    //   .doc(this.loggedInUserId)
    //   .collection("rates")
    //   .valueChanges();
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .pipe(takeUntil(this.destroy$))
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    this.rateForm = this.fb.group({
      title: [{ value: "", disabled: false }, Validators.required],
      duration: [{ value: "" }, Validators.required],
      daysPerWeek: [{ value: "" }, Validators.required],
      hoursPerDay: [{ value: "" }, Validators.required],
      gamesForRate: [this.fb.array([])],
      price: [
        { value: "", disabled: false },
        [
          Validators.required,
          validatePriceRange(),
          validateDecimalPlaces(),
          numericValidator(),
        ],
      ],
      totalLessons: [{ value: "", disabled: true }, []],
      serviceCost: [{ value: "", disabled: true }, []],
      pricePerStudent: [{ value: "", disabled: true }, []],
      pricePerStudentAfter: [{ value: "", disabled: true }, []],
      pricePerClass: [{ value: "", disabled: true }, []],
      fullEarning: [{ value: "", disabled: true }, []],
      noOfLessons: [{ value: "", disabled: false }, []],
      maxNumOfStudents: [{ value: "12", disabled: false }, []],
      afterDiscountPrice: [{ value: "", disabled: true }],
      discount: [{ value: null, disabled: false }, []],
      pricePerClassHourly: [{ value: "", disabled: true }],
      isDiscountActive: [false], // Initialize with false or true based on your requirement
    });
    this.rateForm
      .get("maxNumOfStudents")
      .valueChanges.subscribe((value) =>
        this.updateDisabledFields("maxNumOfStudents", value)
      );
    this.rateForm
      .get("title")
      .valueChanges.subscribe((value) =>
        this.updateDisabledFields("title", value)
      );
    this.rateForm
      .get("duration")
      .valueChanges.subscribe((value) =>
        this.updateDisabledFields("duration", value)
      );
    this.rateForm
      .get("daysPerWeek")
      .valueChanges.subscribe((value) =>
        this.updateDisabledFields("daysPerWeek", value)
      );
    this.rateForm
      .get("hoursPerDay")
      .valueChanges.subscribe((value) =>
        this.updateDisabledFields("hoursPerDay", value)
      );
    this.rateForm
      .get("price")
      .valueChanges.subscribe((value) =>
        this.updateDisabledFields("price", value)
      );
    this.rateForm.get("discount").valueChanges.subscribe((value) => {
      this.updateDisabledFields("discount", value);
    });

    this.afAuth.authState.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.loggedInUserId = user.uid;

        const gamesMap = {};
        const gamesArray = [];

        this.afs
          .collection("users")
          .doc<any>(this.loggedInUserId)
          .valueChanges()
          .pipe(takeUntil(this.destroy$))
          .subscribe((usr) => {
            const gameIds = usr.gameIds || [];

            gameIds.forEach((gameId) => {
              this.afs
                .collection("games")
                .doc(gameId)
                .valueChanges()
                .pipe(takeUntil(this.destroy$))
                .subscribe((game: any) => {
                  if (game && !gamesMap[game.uid]) {
                    gamesMap[game.uid] = game; // Add game to the map
                    gamesArray.push(game); // Add game to the array
                    this.games$.next(gamesArray); // Emit the updated array
                  }
                });
            });
          });

        this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
          this.rateId = params["rateId"];
          this.userId = params["userId"];
          if (this.rateId) {
            this.isEdit = true;
            // make  duration, daysPerWeek,hoursPerDay, disabled

            this.afs
              .collection("users")
              .doc(this.userId)
              .collection("rates")
              .doc(this.rateId)
              .valueChanges()
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                if (res == undefined) {
                  this.toastr.error(this.translate.instant("Rate not found"));
                  this.router.navigate(["/manage-rates"]);
                  return;
                }
                res["duration"] = res.duration?.split(" ")[0];
                if (res["gamesForRate"] == undefined) {
                  res["gamesForRate"] = [];
                }
                console.log(res["gamesForRate"]);
                if (Array.isArray(res["gamesForRate"])) {
                  this.rateForm.controls["gamesForRate"].patchValue(
                    res["gamesForRate"]
                  );
                } else {
                  this.rateForm.controls["gamesForRate"].patchValue([]);
                }
                this.selectedDiscounts = res["discounts"] || [];
                this.rateForm.patchValue({
                  ...res,
                  totalLessons: res.noOfLessons,
                });
                this.selectedDiscounts = res["discounts"] || [];
                if (this.selectedDiscounts.length > 0) {
                  let ind = this._discounts.findIndex(
                    (v) => v.uid == this.selectedDiscounts[0].uid
                  );
                  this._discounts[ind] = this.selectedDiscounts[0];
                  this.discountValue = this.selectedDiscounts[0]?.percent ?? 0;
                  this.updateAfterDiscountPrice();
                }
               
                this.isHidden = res["hidden"];
                this.rateForm.get("duration").disable();
                this.rateForm.get("daysPerWeek").disable();
                this.rateForm.get("hoursPerDay").disable();

                this.afs
                  .collection(`classes`, (ref) =>
                    ref.where("rateId", "==", this.rateId)
                  )

                  .valueChanges()
                  .pipe(takeUntil(this.destroy$))
                  .subscribe((classes: any[]) => {
                    this.classes = classes;
                    console.log(classes);
                    this.isDiscountAvailableForClass = classes.some(
                      (c) => c.discounts && c.discounts.length > 0
                    );
                    console.log(this.isDiscountAvailableForClass);
                  });
              });
          } else {
            this.rateForm.get("duration").enable();
            this.rateForm.get("daysPerWeek").enable();
            this.rateForm.get("hoursPerDay").enable();
          }
        });
      }
    });
  }
  compareGames(game1, game2) {
    return game1 && game2 ? game1.uid === game2.uid : game1 === game2;
  }

  updateDisabledFields(changedControl: string, changedValue: any) {
    const data = { ...this.rateForm.value, [changedControl]: changedValue };
    if (
      (data.duration != null && data.duration.length == 0) ||
      (data.daysPerWeek && data.daysPerWeek.length == 0) ||
      (data.price != null && data.price.length == 0) ||
      (data.hoursPerDay && data.hoursPerDay.length == 0)
    )
      return;
    if (typeof data.daysPerWeek == "object") {
      data.daysPerWeek = data.daysPerWeek.value;
    }
    if (typeof data.duration == "object") {
      data.duration = data.duration.value;
    }
    if (typeof data.hoursPerDay == "object") {
      data.hoursPerDay = data.hoursPerDay.value;
    }
    console.log(data);
    this.getTotalLessons(data.duration, data.daysPerWeek);
    this.calculateServicePricePerStd(data.price);
    this.getHourlyPricePerStudent(
      data.price,
      data.hoursPerDay,
      this.totalLessons.toString()
    );
    this.getHourlyPricePerStudentAfterDate();
    this.getPricePerClass(data.price, data.maxNumOfStudents);
    this.getVat(data, data.maxNumOfStudents);
    this.getFullEarning(data.maxNumOfStudents);
    this.updateAfterDiscountPrice();
    this.updatePricePerClassHourly();
  }

  getTotalLessons(duration: string, daysPerWeek: string) {
    if (duration == undefined) {
      return "0";
    }
    if (
      (duration != null && duration.length == 0) ||
      (daysPerWeek && daysPerWeek.length == 0) ||
      daysPerWeek == undefined
    ) {
      return "0";
    }
    console.log(daysPerWeek);
    console.log(duration);
    var durationInMonths = parseInt(duration.split(" ")[0] ?? "0");
    var daysPerWeekInt = parseInt(daysPerWeek) ?? 0;

    console.log("Days per week", daysPerWeekInt);
    this.totalLessons = Math.round(durationInMonths * 4.34 * daysPerWeekInt);

    this.rateForm.patchValue({
      totalLessons: this.totalLessons.toString(),
    });
    return this.totalLessons.toString();
  }

  calculateServicePricePerStd(price) {
    if (price.length == 0) {
      return "0";
    }

    this.serviceCostPerStd = parseFloat(price) * 0.05;
    this.rateForm.patchValue({
      serviceCost: this.serviceCostPerStd.toFixed(1),
    });
    return this.serviceCostPerStd.toFixed(1);
  }

  getHourlyPricePerStudent(price, hoursPerDay, totalLessons) {
    if (
      price?.length == 0 ||
      totalLessons?.length == 0 ||
      hoursPerDay?.length == 0
    ) {
      return "0";
    }

    // price/(hours per day / total lessons)
    this.hourlyPricePerStd =
      parseFloat(price) / (parseInt(totalLessons) * parseInt(hoursPerDay));
    this.rateForm.patchValue({
      pricePerStudent: this.hourlyPricePerStd.toFixed(2),
    });

    return this.hourlyPricePerStd.toString(2);
  }

  getHourlyPricePerStudentAfterDate() {
    this.hourlyPricePerStdAfterDate = Math.fround(this.hourlyPricePerStd * 1.3);
    this.rateForm.patchValue({
      pricePerStudentAfter: this.hourlyPricePerStdAfterDate.toFixed(2),
    });
    return this.hourlyPricePerStdAfterDate.toFixed(2);
  }

  getPricePerClass(price, maxNumOfStudents) {
    if (price.length == 0) {
      return "0";
    }
    const multiplier = parseInt(maxNumOfStudents);
    this.pricePerClass = parseFloat(price) * (this.isGroup ? multiplier : 1);
    console.log(this.pricePerClass);
    this.rateForm.patchValue({
      pricePerClass: this.pricePerClass.toFixed(2),
    });
    return this.pricePerClass.toFixed(2);
  }

  getVat(userData, maxNumOfStudents) {
    //  This value should change based on the country the master pays VAT to. In Italy it is 22%.
    // if (!this.isGroup) {
    //   this.getPricePerClass(this.rateForm.value["price"], maxNumOfStudents);
    // }

    this.vat = (
      (this.pricePerClass - this.serviceCostPerStd * maxNumOfStudents) *
      (22 / 100)
    ).toFixed(2);

    return this.vat;
  }

  getFullEarning(maxNumOfStudents) {
    if (
      !this.isGroup &&
      (this.rateForm.value["price"].length == 0 ||
        this.rateForm.value["duration"].length == 0 ||
        this.rateForm.value["totalLessons"].length == 0)
    ) {
      return "0";
    }

    let price = this.isGroup ? this.pricePerClass : this.pricePerClass;
    // double.parse(getHourlyPricePerStudent(priceController!.text,
    //     durationController!.text, int.parse(noOfLessonsContrller!.text)));

    if (
      this.rateForm.value["price"].length == 0 ||
      parseFloat(this.vat) == 0 ||
      price == 0
    ) {
      this.fullEarning = 0;
      this.rateForm.patchValue({
        fullEarning: "0",
      });
      return "0";
    }
    if (!this.isGroup) {
      price = price - this.serviceCostPerStd;
    }
    const multiplier = parseInt(maxNumOfStudents);
    this.fullEarning =
      price == 0
        ? price
        : price - this.serviceCostPerStd * (this.isGroup ? multiplier : 1);
    // -parseFloat(this.vat);
    this.rateForm.patchValue({
      fullEarning: this.fullEarning.toFixed(1),
    });
    return this.fullEarning.toFixed(1);
  }

  updateAfterDiscountPrice() {
    const price = this.rateForm.get("price").value; // Assuming this is the base price
    if (price == null) {
      return;
    }
    const discount = this.discountValue; // Assuming this is the selected discount percentage
    if (price && discount) {
      const discountedPrice = price - (price * discount) / 100;
      this.rateForm.patchValue({
        afterDiscountPrice: discountedPrice.toFixed(2),
      });
    }
  }

  handleError(errorMessage: string): void {
    // Logic to display the error message
    console.error(errorMessage); // Or use a more user-friendly notification system
  }
  updatePricePerClassHourly() {
    const pricePerStudent = this.rateForm.get("pricePerStudent").value; // Assuming this is the hourly price per student
    const numOfStudents = this.rateForm.get("maxNumOfStudents").value; // Assuming this is the expected number of students

    if (pricePerStudent && numOfStudents) {
      const pricePerClass = pricePerStudent * numOfStudents;
      this.rateForm.patchValue({
        pricePerClassHourly: pricePerClass.toFixed(2),
      });
    }
  }
}
