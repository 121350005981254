import { NgModule } from "@angular/core";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";

import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";

@NgModule({
  declarations: [FuseConfirmDialogComponent],
  imports: [MatDialogModule, MatButtonModule],
})
export class FuseConfirmDialogModule {}
