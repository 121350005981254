import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { AnalyticsComponent } from "./analytics.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { StudentAnalyticsComponent } from "./student-analytics/student-analytics.component";

const routes = [
  {
    path: "analytics",
    component: AnalyticsComponent,
  },
  {
    path: "student-analytics",
    component: StudentAnalyticsComponent,
  },
];

@NgModule({
  declarations: [AnalyticsComponent, StudentAnalyticsComponent],
  imports: [RouterModule.forChild(routes), FuseSharedModule, MatTabsModule],
})
export class AnalyticsModule {}
