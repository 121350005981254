import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _matSnackBar: MatSnackBar, private _router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // // Auto logout if status is 401
          // // location.reload(true);
          // this._matSnackBar.open('Unauthorize access not allowed', 'OK', {
          //     verticalPosition: 'bottom',
          //     duration: 2000
          // });
          // localStorage.removeItem('access_token');
          // this._router.navigateByUrl('/dashboard');
        } else if (
          err.status === 409 ||
          err.status === 400 ||
          err.status === 0
        ) {
          if (err && err.error && err.error.message) {
            this._matSnackBar.open(err.error.message, "OK", {
              verticalPosition: "bottom",
              duration: 3000,
            });
          }
        }

        console.log(err);
        return throwError(err);
      })
    );
  }
}
