import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "app/shared/services/auth.service";

@Component({
  selector: "logout-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class logoutConfirmDialogComponent {
  public confirmMessage: string;

  /**
   * Constructor
   *
   * @param {MatDialogRef<logoutConfirmDialogComponent>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<logoutConfirmDialogComponent>,
    private _router: Router, 
    private authService: AuthService
  ) {}

  logout() {
    this.authService.SignOut();
    this.dialogRef.close();
    this._router.navigate(["/login"]);
  }
}
