import {
  Component,
  OnChanges,
  OnInit,
  ViewChild,
  SimpleChanges,
  ElementRef,
} from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { firstValueFrom, forkJoin, merge, Observable, of, Subject } from "rxjs";
import firebase from "firebase/compat/app";
import { finalize, map } from "rxjs/operators";
import { Router } from "@angular/router";
declare var $: any;
import { switchMap } from "rxjs/operators";
import { FirestoreService } from "app/shared/services/firestore.service";
import { Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OwlOptions } from "ngx-owl-carousel-o";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";
interface User {
  discordLink: any;
  facebookLink: any;
  instagramLink: any;
  twitchLink: any;
  languages: string[];
  aboutme: string;
  profilePic: string;
  name: string;
  country: string;

  about: string;

  coverPic: string;
}

@Component({
  selector: "profile-edit",
  templateUrl: "./profile-edit.component.html",
  styleUrls: ["./profile-edit.component.scss"],
})
export class ProfileEditComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ["", ""],
    autoplay:true,
    autoplayTimeout:3000,
    items: 1,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };
  aboutMe$: Observable<string>;
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  aboutMe: string;
  user: firebase.User;
  selectedFile$: Observable<string>;
  profilePicture$: Observable<string>;
  name$: Observable<string>;
  country$: Observable<string>;
  about$: Observable<string>;
  twitchLink$: Observable<any>;
  instagramLink$: Observable<any>;
  facebookLink$: Observable<any>;
  discordLink$: Observable<any>;
  languages$: Observable<string[]>;
  languagesString: string;
  country: string;
  about: string;
  twitchLink: string;
  tiktokLink: string;
  instagramLink: string;
  facebookLink: string;
  discordLink: string;
  twitterLink: string;youtubeLink: string;
  name: string;
  userDoc: import("@angular/fire/compat/firestore").AngularFirestoreDocument<unknown>;
  coverPic$: Observable<string>;
  profilePicture: string;
  @ViewChild("fileInput") fileInput: ElementRef;
  private fileSubject = new Subject<File>();
  languagesList = ["English", "Italian", "German", "Spanish"];
  languages = [];
  isMobile: boolean;
  countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia (formerly Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  private _subscriptions = new Subscription();
  private _destroyed$ = new Subject();
  gamesData: any[] = [];
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private firestoreService: FirestoreService,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService
  ) {
    this.selectedFile$ = this.fileSubject.pipe(
      switchMap((file: File) => this.readFile(file))
    );
  }
  async updateAboutMe() {
    // Get a reference to the Firestore "users" collection
    const usersRef = this.afs.collection("users");

    // Update the "aboutme" field of the logged-in user in Firestore
    await usersRef.doc(this.user.uid).update({ aboutme: this.aboutMe });
  }

  ngOnInit() {
    this.breakpoint
    .observe([Breakpoints.Handset, Breakpoints.Tablet])
    .subscribe((o) => {
      this.isMobile = o.matches;

      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: this.isMobile,
          },
          toolbar: {
            hidden: this.isMobile,
          },
          footer: {
            hidden: this.isMobile,
          },
          sidepanel: {
            hidden: this.isMobile,
          },
        },
      };
    });
    this.afAuth.authState
      .pipe(takeUntil(this._destroyed$))
      .subscribe((user) => {
        if (!user) return;
        this.user = user;
        this.userDoc = this.afs.doc(`users/${user.uid}`);
        if (user) {
          const userDocument$ = this.afs
            .collection("users")
            .doc<any>(user.uid)
            .get();

          this.profilePicture$ = merge(
            this.selectedFile$,
            userDocument$.pipe(map((doc) => doc.data().profilePic))
          );
          userDocument$.subscribe((doc) => {
            this.profilePicture = doc.data().profilePic;
            this.youtubeLink = doc.data().youtubeLink;
            this.tiktokLink = doc.data().tiktokLink;
            this.twitterLink = doc.data().twitterLink;
            const data = doc.data();
            this.firestoreService
              .col$("games")
              .pipe(takeUntil(this._destroyed$))
              .subscribe((games: any[]) => {
                this.gamesData = games.filter((g: any) =>
                  data.gameIds.includes(g.uid)
                );
              });
          });

          this.name$ = userDocument$.pipe(map((doc) => doc.data().nickname ?? doc.data().username ));
          this.country$ = userDocument$.pipe(map((doc) => doc.data().country));
          this.about$ = userDocument$.pipe(map((doc) => doc.data().about));
          this.twitchLink$ = userDocument$.pipe(
            map((doc) => doc.data().twitchLink)
          );
          this.instagramLink$ = userDocument$.pipe(
            map((doc) => doc.data().instagramLink)
          );
          this.facebookLink$ = userDocument$.pipe(
            map((doc) => doc.data().facebookLink)
          );
          this.discordLink$ = userDocument$.pipe(
            map((doc) => doc.data().discordChannel)
          );
          this.languages$ = userDocument$.pipe(
            map((doc) => doc.data().languages ?? [])
          );
          this.coverPic$ = userDocument$.pipe(
            map((doc) => doc.data().coverPic)
          );

          this.country$.subscribe((result) => {
            this.country = result;
          });
          this.about$.subscribe((result) => {
            this.about = result;
          });
          this.twitchLink$.subscribe((result) => {
            this.twitchLink = result;
          });
          this.instagramLink$.subscribe((result) => {
            this.instagramLink = result;
          });
          this.facebookLink$.subscribe((result) => {
            this.facebookLink = result;
          });
          this.discordLink$.subscribe((result) => {
            this.discordLink = result;
          });
          this.name$.subscribe((result) => {
            this.name = result;
          });
          this.languages$.subscribe((result) => {
            console.log(result);
            this.languages = result ?? [];
          });
      

          // this.languages$
          //   .pipe(map((languages) => (languages ? languages.join(", ") : "")))
          //   .subscribe((languagesString) => {
          //     this.languagesString = languagesString; // Outputs the comma-separated string
          //   });
        }
      });
  }

  ngOnDestroy() {
    this._destroyed$.next(null);
    this._destroyed$.complete();
  }
  onUploadProfilePicture(fileInput: any): void {
    fileInput.click();
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];

    if (file) {
      this.fileSubject.next(file);
      console.log(file);
    }
  }

  private readFile(file: File): Observable<string> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        observer.next(e.target.result as string);
        observer.complete();
      };

      reader.onerror = (error) => {
        observer.error(error);
      };

      reader.readAsDataURL(file);
    });
  }

  onSave() {
    if (
      this.fileInput.nativeElement.files == null ||
      this.fileInput.nativeElement.files.length == 0
    ) {
      this.updateOnlyOtherData();
      return;
    }

    this.firestoreService.uploadImage(
      this.fileInput.nativeElement.files[0],
      this.user.uid,
      "profilePic"
    );
    this.firestoreService.task
      .snapshotChanges()
      .pipe(
        finalize(async () => {
          const downloadURL = await firstValueFrom(
            this.firestoreService.storage
              .ref(
                (
                  await this.firestoreService.task.snapshotChanges().toPromise()
                ).metadata.fullPath
              )
              .getDownloadURL()
          );
          this.updateOnlyOtherData(downloadURL);
        })
      )
      .subscribe();
  }

  updateOnlyOtherData(downloadURL?: string) {
    const userProfileData = {
      username: this.name ?? "",
      nickname: this.name ?? "",
      country: this.country ?? "",
      about: this.about ?? "",
      twitchLink: this.twitchLink ?? "",
      instagramLink: this.instagramLink ?? "",
      facebookLink: this.facebookLink ?? "",
      discordChannel: this.discordLink ?? "",
      profilePic: downloadURL ?? this.profilePicture, // use this.user.profilePicture
      languages: this.languages ?? [],
      youtubeLink: this.youtubeLink ?? "",
      twitterLink: this.twitterLink ?? "",
      tiktokLink: this.tiktokLink ?? ""
    };
    this.userDoc
      .update(userProfileData)
      .then(() => {
        console.log("Profile updated successfully");
        this.router.navigate(["/profile"]);
      })
      .catch((error) => console.log("Error updating profile:", error));
  }
}
