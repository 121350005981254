<!-- bottom-nav.component.html -->

<div class="bottom-nav">
  <a
    *ngFor="let image of images; let i = index"
    mat-icon-button
    [class.selected]="i === selectedIndex"
    (click)="changeTab(i)"
  >
    <img src="{{ image.src }}" aria-label="{{ image.alt }}" />
  </a>
</div>
