import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { StripeService, StripeCardNumberComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  PaymentIntent,
  Appearance,
} from '@stripe/stripe-js';


@Component({
  selector: "app-stripe-int",
  templateUrl: "./stripe-int.component.html",
  styleUrls: ["./stripe-int.component.scss"],
})
export class StripeIntComponent implements OnInit {
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  cardOptions: StripeCardElementOptions = {
    iconStyle: 'solid',
    classes: {
      base: 'form-control',

    },
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
        
      },
      invalid: {
        iconColor: '#FFC7EE',
        color: '#FFC7EE',
      },
    },
  };
  appearance: Appearance = { theme: 'stripe' };
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: this.appearance,
  };

  stripeTest: FormGroup;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private stripeService: StripeService
  ) {}

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['Angular v15', [Validators.required]],
      amount: [1001, [Validators.required, Validators.pattern(/d+/)]],
    });
  }

  pay(): void {
    // if (this.stripeTest.valid) {
      this.createPaymentIntent(1001)
        .pipe(
          switchMap((pi) =>
            this.stripeService.confirmCardPayment(pi.paymentIntent, {
              payment_method: {
                card: this.card.element,
                billing_details: {
                  name: this.stripeTest.get('name').value,
                },
              },
            })
          )
        )
        .subscribe((result) => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer
            }
          }
        });
    // } else {
    //   console.log(this.stripeTest);
    // }
  }

  createPaymentIntent(amount: number): Observable<{
    paymentIntent: string,
    ephemeralKey: string,
    customer: string,
    success: boolean,
  }> {
    return this.http.post<any>(
      `https://us-central1-sweech-3ec98.cloudfunctions.net/stripePaymentSheet`,
      { amount: amount.toString(), email: 'master@gmail.com' }
    );
  }
}
