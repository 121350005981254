<app-navbar *ngIf="isMobile" [title]="'Class Activity' | translate"></app-navbar>

<div class="main" *ngIf="classData"
[ngClass]="{ desktop: !isMobile, mobile: isMobile }"
>
  <div class="group-class-edit">
    <div class="container-fluid">
      <div class="class-date-time" *ngIf='!isMobile'>
        <span class="class-name"
          >{{ classData.title }} {{ classData.subtitle }}</span
        >
        <span class="time">{{ days }} ({{ classData.startTime }}) ({{ classData.endTime }})</span>

        <span class="date"
          >({{ classData.startDate.toDate() | date : "MMM, dd yyyy" }}) - ({{
            classData.endDate.toDate() | date : "MMM, dd yyyy"
          }})</span
        >
        <span class="students"
          >{{ classData.classStudentsList.length }}
          {{ "Students" | translate }}</span
        >
      </div>
      <div class="class-date-time" *ngIf="isMobile">
        <div class="class-image">
          <img src="{{ classData.image }}" alt="Class image" />
        </div>
        <div class="class-info">
          <span class="class-name"
            >{{ classData.title }} {{ classData.subtitle }}</span
          >
          <span class="time">
            {{ days }}
          </span>
          <span class="time"
            > ({{ classData.startTime }}) ({{
              classData.endTime
            }})</span
          >
          <span class="date"
            >({{ classData.startDate.toDate() | date : "MMM, dd yyyy" }}) - ({{
              classData.endDate.toDate() | date : "MMM, dd yyyy"
            }})</span
          >
          <span class="students"
            >{{ classData.classStudentsList.length }}
            {{ "Students" | translate }}</span
          >
        </div>
      </div>
      <div class="class-cover" *ngIf="!isMobile">
        <img src="{{ classData.image }}" alt="Class Image" />
      </div>
      <div class="btns-area">
        <div class="nav-action-btns">
          <!-- <button class="small-btn" routerLink="/class-setting">
            <i class="fa fa-cog"></i>
          </button> -->
          <!-- <button class="small-btn"><i class="fa fa-share-alt"></i></button> -->
        </div>
      </div>
      <div class="edit-lesson" [formGroup]="form">
        <div class="box">
          <h4>{{"Manage your class activities and" | translate}}<br />{{"students attendance" | translate}}</h4>
          <div class="row">
            <div class="col-md">
              <h5>{{"Game Name:" | translate}}</h5>
              <mat-form-field appearance="outline" class="w-100" disabled>
                <input
                  matInput
                  formControlName="gameName"
                  placeholder="Valorant"
                  disabled="true"
                />
              </mat-form-field>
              <h5>{{"Class Topic (Subject):" | translate}}</h5>
              <mat-form-field appearance="outline" class="w-100">
                <input
                  matInput
                  formControlName="topic"
                  [placeholder]="'Valorant Rankup Tips & Tricks' | translate"
                />
              </mat-form-field>
              <h5>{{"Date:"  | translate}}</h5>
              <mat-form-field appearance="outline" class="w-100">
                <input
                  matInput
                  [matDatepicker]="datePicker"
                  [min]="classData.startDate.toDate()"
                  [max]="classData.endDate.toDate()"
                  formControlName="date"
                  (focus)="datePicker.open()"
                />

                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="datePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker class="qwe" #datePicker></mat-datepicker>
              </mat-form-field>

              <h5>{{"Exercises:"  | translate}}</h5>
              <mat-form-field appearance="outline" class="w-100">
                <textarea matInput formControlName="exercises"></textarea>
              </mat-form-field>
            </div>
            <div class="col-md-5">
              <h5>{{'Class Activities:'  | translate}}</h5>
              <mat-form-field appearance="outline" class="w-100">
                <textarea matInput formControlName="activities"></textarea>
              </mat-form-field>

              <h5 ></h5>
              <mat-form-field *ngIf="allStudents.length > 0" class="example-chip-list w-100" appearance="outline">
                <mat-label>{{'Attendance:' | translate}}</mat-label>
                <mat-chip-grid #chipGrid aria-label="student selection">
                  <mat-chip-row *ngFor="let student of presentStudents" (removed)="remove(student)">
                    {{student}}
                    <button matChipRemove [attr.aria-label]="'remove ' + student">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                </mat-chip-grid>
                <input [placeholder]="'Type name to select Attendance' | translate" #studentInput [formControl]="studentCtrl"
                  [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                  />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let student of filteredstudents | async" [value]="student.username">
                    {{student.username}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              

            </div>
          </div>
          <div class="form-action-btns">
            <button
              class="btn btn-default"
              routerLink="/group-class-view/{{ classId }}"
            >
              {{"Back" | translate}}
            </button>
            <button
              class="btn btn-primary"
              (click)="save()"
              [disabled]="form.invalid"
            >
              {{"Save"  | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
