<div id="auth" class="auth choose-game">
  <div class="container-fluid">
    <img
      class="sweech-logo"
      [ngStyle]="{ position: isMobile ? 'unset' : 'absolute' }"
      src="assets/images/sweech-logo.png"
      alt=""
    />
    <div class="row align-items-center">
      <div class="col-md-5">
        <div
          class="auth-box pt-0 text-center"
          [ngClass]="{ 'auth-mobile': isMobile }"
        >
          <h1>{{ "Games you'll teach" | translate }}</h1>
          <p>
            {{
              "Choose the Games that you will be giving Classes for" | translate
            }}
          </p>
          <div class="row games" *ngIf="games$ | async as games">
            <div
              (click)="onGameClicked(game.uid, game)"
              class="col-4"
              *ngFor="let game of games | slice : start : end"
            >
              <img
                [style.border]="
                  selectedGames.includes(game.uid) ? '3px solid green' : 'none'
                "
                src="{{ game.image }}"
                alt="{{ game.name }}"
              />

              <h5>{{ game.name }}</h5>
            </div>
            <div
              class="pagination-container text-center"
              *ngIf="games.length > 3"
            >
              <div class="pagination">
                <button (click)="previousPage()" [disabled]="currentPage <= 1">
                  {{ "Previous" | translate }}
                </button>
                <span>{{ currentPage }}</span>
                <button (click)="nextPage()" *ngIf="currentPage >= 0">
                  {{ "Next" | translate }}
                </button>
              </div>
            </div>
          </div>

          <button class="btn btn-default" (click)="requestGame()">
            {{ "Ask for a Missing Game" | translate }}</button
          ><br />
          <button
            class="btn btn-primary"
            [disabled]="selectedGames.length === 0"
            (click)="updateUser()"
            routerLink="/gaming-curriculm"
            style="margin-bottom: 3rem"
          >
            {{ "Next" | translate }}
          </button>
        </div>
      </div>
      <div class="col-md-7" *ngIf="!isMobile">
        <img class="img-fluid" src="/assets/images/login.jpg" />
      </div>
    </div>
  </div>
</div>
