import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { environment } from "environments/environment";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import * as firebase from "firebase/compat/app";
import { TranslateService } from "@ngx-translate/core";

const CLIENT_SECRET = environment.clientSecret;

@Component({
  selector: "gaming-curriculm",
  templateUrl: "./gaming-curriculm.component.html",
  styleUrls: ["./gaming-curriculm.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class GamingCurriculmComponent implements OnInit {
  curriculumForm = new FormGroup({
    teachingProgram: new FormControl("", { validators: [Validators.required] }),
    teachingMethod: new FormControl("", { validators: [Validators.required] }),
    awardsSpecialNotes: new FormControl("", {
      validators: [Validators.required],
    }),
    twitchChannel: new FormControl("", { validators: [Validators.required] }),
    exerciseExample: new FormControl("", { validators: [Validators.required] }),
  });

  user: firebase.default.User | null = null;
  userData: any;
  isMobile: boolean;
  user_games: {
    gameIds: [];
    gamesTeached: [];
    game;
  };
  isFromSettings: boolean = false;;
  loading = false;
   // 0 = Teaching Program, 1 = Teaching Method, 2 = Exercise Example
   valuesEnglish = [
    `INITIAL APPROACH TO THE GAME:
  - familiarity with the joystick: handle (classic, scuf, claw) and relative advantages, scheme controls (tactical, bumper jumper, etc)
  - settings: graphics, audio, visual sensitivity, game features: tactical shooting, use/recharge functionality, etc
  - ……
  
  AUDIO:
  - introduction to the importance of audio in gaming
  - Audio Insight: Understand enemy location and intentions based on audio sounds
  - ……`,
    `The courses will begin with a brief introduction to the game, explaining its methods and objectives and checking that all settings are configured correctly, then proceed gradually towards increasingly complex topics, such as the mechanics of the fight.

  Each lesson should start with a short introduction to the topic of the day and related theoretical explanation, which will be followed by a practical demonstration (made by me or by one of my students). Afterwards, each Student will share his screen to Discord while training on the topic of the day, so that I can observe them one by one and give useful tips to improve and correct mistakes.
  
  Many workouts will take place in a controlled environment, such as in the case of Warzone in a private lobby against bots or against classmates, or by exploiting features of the map (such as silhouettes and targets).
  
  Specific exercises will be assigned for each technique. For PC players, the possibility of training on AimLab will be included.
  After having correctly learned the technique of interest, the Students will be able to test it in public match.`,
    `- Recoil control training: private lobby, no bots, take point of reference on the wall and shoot all the magazine trying to keep the shortlist strokes as close as possible;

  - Headshot training: private match, 4 enemy bots on normal difficulty, 0 allied bots, headshots only, enemy health 100, aim assist on;
  
  - Centering training: private match, 4 enemy bots on normal difficulty, 0 allied bots, enemy health 100, aim assist off;
  
  - Tracking training: private match, 4 enemy bots on normal difficulty, 0 allied bots, enemy health 250, aim assist off;
  
  - ……`,
  ];
  valuesItalian = [
    `APPROCCIO INIZIALE AL GIOCO:
    - confidenza col joystick: impugnatura (classica, scuf, claw) e relativi vantaggi, schema comandi (tattico, bumper jumper, ecc)
    - impostazioni: grafica, audio, sensibilità visuale, funzionalità di gioco: scatto tattico, funzionalità usa/ricarica, ecc
    - ……
    
    AUDIO:
    - introduzione sull'importanza dell’audio nel gioco
    - intuito legato all’audio: comprendere posizione e intenzioni del nemico in base ai suoni
    - ……`,
    `I corsi inizieranno con una breve introduzione sul gioco, spiegandone le modalità, gli obiettivi e controllando che tutte le impostazioni siano configurate correttamente, per poi procedere via via verso argomenti sempre più complessi, come le meccaniche del fight.

    Ogni lezione dovrebbe iniziare con una breve introduzione sull’argomento del giorno e relativa spiegazione teorica, alla quale seguirà una dimostrazione pratica (fatta da me o da uno degli Studenti). Successivamente, ogni Studente condividerà il suo schermo su Discord mentre si allena sull’argomento del giorno, così che io possa osservarli uno ad uno e dare degli utili consigli per migliorare e correggere gli errori.
    
    Molti allenamenti avverranno in un ambiente controllato, ad esempio nel caso di Warzone in lobby privata contro i bot o contro i compagni di classe, oppure sfruttando le caratteristiche della mappa (come sagome e bersagli).
    
    Verranno assegnati esercizi specifici per ogni tecnica.
    Per i giocatori da pc sarà inclusa la possibilità di allenarsi su AimLab.
    Dopo aver appreso correttamente la tecnica di interesse, gli Studenti potranno testarla in partita pubblica.`,

    `- Allenamento controllo rinculo: lobby privata, nessun bot, prendere punto di riferimento sul muro e sparare tutto il caricatore cercando di mantenere la rosa dei colpi il più stretta possibile;

    - Allenamento headshot: partita privata, 4 bot nemici difficoltà normale, 0 bot alleati, solo colpi alla testa, salute nemico 100, aim assist on;
    
    - Allenamento centering: partita privata, 4 bot nemici difficoltà normale, 0 bot alleati, salute nemico 100, aim assist off;
    
    - Allenamento tracking: partita privata, 4 bot nemici difficoltà normale, 0 bot alleati, salute nemico 250, aim assist off;
    
    - ……`,
  ];

  placeholders = {
    teachingProgram: '',
    teachingMethod: '',
    exerciseExample: '',
  };

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private _fuseConfigService: FuseConfigService,
    private breakpoint: BreakpointObserver,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  async ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe((o) => {
        this.isMobile = o.matches;
        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: true,
            },
            toolbar: {
              hidden: true,
            },
            footer: {
              hidden: true,
            },
            sidepanel: {
              hidden: true,
            },
          },
        };
      });
    this.afAuth.authState.subscribe((user) => {
      if (user == null) return;
      this.user = user;
      this.route.queryParams.subscribe((params) => {
        if (params.settings) {
          this.isFromSettings = params.settings == 'true';
          if (this.isFromSettings) {
            console.log(this.isFromSettings);
            this._fuseConfigService.config = {
              layout: {
                navbar: {
                  hidden: this.isMobile && !this.isFromSettings,
                },
                toolbar: {
                  hidden: this.isMobile && !this.isFromSettings,
                },
                footer: {
                  hidden: this.isMobile && !this.isFromSettings,
                },
                sidepanel: {
                  hidden: this.isMobile && !this.isFromSettings,
                },
              },
            }; 
          }
        }
      });
      this.afs
        .doc(`users/${user.uid}`)
        .valueChanges()
        .subscribe((userData: any) => {
          this.userData = userData;
          let user_games = JSON.parse(localStorage.getItem("user_games"));
          if (user_games) {
            this.user_games = user_games;
          }
          // if (userData.step !== 3) {
          //   switch (userData.step) {
          //     case 1:
          //       this.router.navigate(["/signup-detail"]);
          //       break;
          //     case 2:
          //       this.router.navigate(["/choose-game"]);
          //       break;

          //     case 4:
          //       this.router.navigate(["/dashboard"]);
          //   }
          // }
        });
    });

    const currentLang = this.translate.currentLang;
    if (currentLang == "it") {
      this.placeholders.teachingProgram =  this.valuesItalian[0];
      this.placeholders.teachingMethod = this.valuesItalian[1];
      this.placeholders.exerciseExample = this.valuesItalian[2];
  } else{
    this.placeholders.teachingProgram =  this.valuesEnglish[0];
    this.placeholders.teachingMethod = this.valuesEnglish[1];
    this.placeholders.exerciseExample = this.valuesEnglish[2];
  }
  }
  async onSubmit() {
    if (this.curriculumForm.invalid) {
      this.curriculumForm.markAllAsTouched();
      return;
    }
    this.loading = true;
    const value = this.curriculumForm.value;

    if (this.user) {
      this.userData.gameEnabled = this.userData.gameEnabled ?? [];
      this.userData.gameEnabled.push(false);
      console.log(this.userData.gameEnabled);
      this.userData.gameIds = this.userData.gameIds ?? [];
      this.userData.gameIds.push(...this.user_games.gameIds);

      this.userData.gamesTeached = this.userData.gamesTeached ?? [];
      this.userData.gamesTeached.push(...this.user_games.gamesTeached);

      await this.afs.doc(`users/${this.user.uid}`).update({
        gameIds: this.userData.gameIds,
        gamesTeached: this.userData.gamesTeached,
        step: 4,
        isCompleted: true,
        productLinks: [],
        gameEnabled: this.userData.gameEnabled,
      });
      // await this.afs.doc(`email-sent/${this.user.uid}`).set({
      //   toEmail: this.user.email,
      // subject: `Gaming Curriculum approvato`,
      // message: `Ciao ${this.userData.username},
      //   <br/>
      // Abbiamo ricevuto la tua richiesta di insegnare ${this.user_games.gamesTeached.join(',')}. Valuteremo il tuo Gaming Curriculum al più presto e ti faremo sapere!
      // <br/>
      // Team Sweech`,
      // creationDate: new Date(),

      // })
      this.user_games.gameIds.forEach((element) => {
        const selectedgamesRef = this.afs
          .collection("users")
          .doc(this.user.uid)
          .collection("selectedGames")
          .add({
            gameId: element,
            uid: null, // set the initial value of uid to null
            creationDate: new Date(),
            ...value,
            enabled: false,
            userID: this.user.uid,
            gameName: this.user_games.game.name,
          })
          .then((docRef) => {
            // add the docRef parameter to the callback function
            // Update the uid field with the newly created document's ID
            this.loading = false;
            docRef.update({ uid: docRef.id }).then(() => {
              this.router.navigate(["/dashboard"]); // navigate to the next page
            });
          }).catch((e) => {
            console.log(e);
            this.loading = false;
          })
      });
      // await this.afs
      //   .doc(`users/${this.user.uid}`)
      //   .update({
      //     ...value,
      //     step: 4,
      //     isCompleted: true,
      //     productLinks: [],
      //   })
      //   .then(() => this.router.navigate(["/dashboard"]))
      //   .catch((error) => console.log(error));
    }
  }
}
