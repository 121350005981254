import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { MessagesComponent } from "./messages.component";
import {  MatTabsModule } from "@angular/material/tabs";
import { RelativeTimePipe } from "./message.pipe";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { SharedAppModule } from "app/shared/shared-app.module";
import { SummaryPipe } from "./summary.pipe";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { AppAuthGuard } from "app/app.authguard";

const routes: Routes = [
  {
    path: "messages/:id",
    component: MessagesComponent,
    canActivate: [AppAuthGuard]

  },
  {
    path: "messages",
    component: MessagesComponent,
    canActivate: [AppAuthGuard]

  },
];

@NgModule({
  declarations: [MessagesComponent, RelativeTimePipe, SummaryPipe],
  imports: [
    PickerModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatTabsModule,
    SharedAppModule,
    MatMenuModule, 
    MatIconModule,
    MatButtonModule, 
    TranslateModule
  ],
  exports: [SummaryPipe]
})
export class MessagesModule {}
