import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "relativeTime",
})
export class RelativeTimePipe implements PipeTransform {
  transform(date: Date): string {
    const now = new Date();
    const diff = (now.getTime() - date.getTime()) / 1000;

    if (diff < 60) {
      return "just now";
    } else if (diff < 3600) {
      const minutes = Math.floor(diff / 60);
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else if (diff < 86400) {
      const hours = Math.floor(diff / 3600);
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (diff < 604800) {
      const days = Math.floor(diff / 86400);
      return `${days} day${days === 1 ? "" : "s"} ago`;
    } else if (diff < 2592000) {
      const weeks = Math.floor(diff / 604800);
      return `${weeks} week${weeks === 1 ? "" : "s"} ago`;
    } else {
      const months = Math.floor(diff / 2592000);
      return `${months} month${months === 1 ? "" : "s"} ago`;
    }
  }
}
