import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  /**
   * Constructor
   */
  constructor(private router: Router) {}

  goTopolicyContent(searchText) {
    console.log(searchText);
    if (searchText) {
      this.router.navigateByUrl(`/content/${searchText}`);
      console.log("avx");
    } else {
    }
  }
}
