<app-navbar *ngIf="isMobile" [hideBackIcon]="false"></app-navbar>

<div class="main pt-2" [ngClass]="{ mobile: isMobile }">
  <div class="profile-edit">
    <div class="container-fluid">
      <h2>{{ "Edit Profile" | translate }}</h2>
      <div class="profile-box">
        <div
          class="cover"
          *ngIf="gamesData != null && gamesData.length > 0; else nocoverPic"
        >
          <button class="btn edit-btn" routerLink="/profile-edit">
            <i class="fa fa-pen"></i>
          </button>
          <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let gD of gamesData; let i = index">
              <ng-template carouselSlide [id]="i">
                <img
                  class="c-image"
                  *ngIf="gD.image; else nocoverPic"
                  src="{{ gD.image }}"
                  alt="Profile Picture"
                />
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>

        <ng-template #nocoverPic>
          <div class="cover">
            <button
              class="btn edit-btn"
              routerLink="/profile-edit"
              style="
                background-color: black;
                color: white;
                display: inline-block;
              "
            >
              <i class="fa fa-pen"></i>
            </button>

            <div
              style="
                border-radius: 10%;
                width: 100%;
                height: 349px;
                background-color: #f0f0f0;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <p style="font-size: 24px; font-weight: bold">
                {{ "No Cover Photo" | translate }}
              </p>
            </div>
          </div>
        </ng-template>
        <div class="profile-body">
          <div class="info">
            <div class="dp-name">
              <input
                type="file"
                style="display: none"
                #fileInput
                (change)="onFileSelected($event)"
              />

              <img
                (click)="onUploadProfilePicture(fileInput)"
                *ngIf="
                  profilePicture$ | async as profilePicture;
                  else noProfilePicture
                "
                src="{{ profilePicture }}"
                alt="Profile Picture"
                style="border-radius: 50%"
              />

              <ng-template #noProfilePicture>
                <div
                  (click)="onUploadProfilePicture(fileInput)"
                  style="
                    display: inline-block;
                    border-radius: 50%;
                    width: 140px;
                    height: 140px;
                    background-color: gray;
                  "
                ></div>
              </ng-template>
              <h3>{{ name$ | async }}</h3>
              <span class="country">({{ country$ | async }})</span>
            </div>

            <div class="profile-edit-section">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="box">
                      <h4>{{ "Nick name :" | translate }}</h4>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Hazel Housten"
                        value="{{ name }}"
                        [(ngModel)]="name"
                        [max]="30"
                        [ngModelOptions]="{ standalone: true }"
                      />
                      <h4>{{ "Country :" | translate }}</h4>

                      <mat-form-field appearance="outline">
                        <mat-label>{{
                          "Select a Country" | translate
                        }}</mat-label>
                        <mat-select [(value)]="country">
                          <mat-option
                            *ngFor="let country of countries"
                            [value]="country"
                          >
                            {{ country }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <h4>{{ "Description :" | translate }}</h4>
                      <textarea
                        class="form-control"
                        value="{{ about }}"
                        [(ngModel)]="about"
                        [maxLength]="1500"
                        [ngModelOptions]="{ standalone: true }"
                        >{{ about }}</textarea
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="box">
                      <h4>{{ "Languages :" | translate }}</h4>
                      <!-- <input
                        type="text"
                        class="form-control"
                        placeholder="English, Italian "
                        value="{{ languagesString }}"
                        [(ngModel)]="languagesString"
                        [ngModelOptions]="{ standalone: true }"
                      /> -->
                      <mat-form-field appearance="outline">
                        <mat-label>{{
                          "Select a Language" | translate
                        }}</mat-label>
                        <mat-select multiple [(value)]="languages">
                          <mat-option
                            *ngFor="let lang of languagesList"
                            [value]="lang"
                          >
                            {{ lang }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <h4>{{ "Social Media Links :" | translate }}</h4>
                      <div class="form-group">
                        <i class="fab fa-instagram"></i>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="https://instagram.com/hazelteaches"
                          value="{{ instagramLink }}"
                          #instagramLinkInput="ngModel"
                          [(ngModel)]="instagramLink"
                          [ngModelOptions]="{ standalone: true }"
                          pattern="^((https?:\/\/)?(www\.)?instagram\.com\/.*|instagram\.com\/.*)"
                        />
                        <div
                          *ngIf="
                            instagramLinkInput.errors?.pattern &&
                            instagramLinkInput.touched
                          "
                          class="alert alert-danger"
                        >
                          {{
                            "Please enter a valid instagram link" | translate
                          }}
                        </div>
                      </div>
                      <div class="form-group">
                        <i class="fab fa-facebook-f"></i>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="https://facebook.com/hazelteaches"
                          value="{{ facebookLink }}"
                          #facebookLinkInput="ngModel"
                          [(ngModel)]="facebookLink"
                          [ngModelOptions]="{ standalone: true }"
                          pattern="^((https?:\/\/)?(www\.)?facebook\.com\/.*|facebook\.com\/.*|(https?:\/\/)?(m\.)?facebook\.com\/.*|m\.facebook\.com\/.*|(https?:\/\/)?fb\.me\/.*|fb\.me\/.*)"
                        />
                        <div
                          *ngIf="
                            facebookLinkInput.errors?.pattern &&
                            facebookLinkInput.touched
                          "
                          class="alert alert-danger"
                        >
                          {{ "Please enter a valid facebook link" | translate }}
                        </div>
                      </div>
                      <div class="form-group">
                        <i class="fab fa-twitch"></i>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="https://twitch.tv/hazelteaches"
                          value="{{ twitchLink }}"
                          [(ngModel)]="twitchLink"
                          #twitchLinkInput="ngModel"
                          [ngModelOptions]="{ standalone: true }"
                          pattern="^((https?:\/\/)?(www\.)?twitch\.tv\/.*|twitch\.tv\/.*|(https?:\/\/)?(www\.)?twitch\.com\/.*|twitch\.com\/.*|(https?:\/\/)?(m\.)?twitch\.tv\/.*|m\.twitch\.tv\/.*)"
                        />
                        <!-- Error handling for pattern -->
                        <div
                          *ngIf="
                            twitchLinkInput.errors?.pattern &&
                            twitchLinkInput.touched
                          "
                          class="alert alert-danger"
                        >
                          {{ "Please enter a valid twitch link" | translate }}
                        </div>
                      </div>
                      <div class="form-group">
                        <i class="fab fa-discord"></i>
                        <input
                        type="text"
                        class="form-control"
                        placeholder="https://discord.gg/hazelteaches"
                        value="{{ discordLink }}"
                        [(ngModel)]="discordLink"
                        [ngModelOptions]="{ standalone: true }"
                        pattern="^((https?:\/\/)?(www\.)?discord\.com\/.*|discord\.com\/.*|(https?:\/\/)?discord\.gg\/.*)"
                        #discordLinkInput="ngModel"
                      />                      
                        <!-- Error handling for pattern -->
                        <div
                          *ngIf="
                            discordLinkInput.errors?.pattern &&
                            discordLinkInput.touched
                          "
                          class="alert alert-danger"
                        >
                          {{ "Please enter a valid discord link" | translate }}
                        </div>
                      </div>
                      <div class="form-group">
                        <i class="fab fa-twitter"></i>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="https://twitter.com/hazelteaches"
                          value="{{ twitterLink }}"
                          [(ngModel)]="twitterLink"
                          [ngModelOptions]="{ standalone: true }"
                          pattern="^((https?:\/\/)?(www\.)?twitter\.com\/.*|twitter\.com\/.*|(https?:\/\/)?t\.co\/.*|t\.co\/.*)"
                          #twitterLinkInput="ngModel"
                        />
                        <!-- Error handling for pattern -->
                        <div
                          *ngIf="
                            twitterLinkInput.errors?.pattern &&
                            twitterLinkInput.touched
                          "
                          class="alert alert-danger"
                        >
                          {{ "Please enter a valid twitter link" | translate }}
                        </div>
                      </div>
                      <div class="form-group">
                        <i class="fab fa-youtube"></i>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="https://youtube.com/hazelteaches"
                          value="{{ youtubeLink }}"
                          [(ngModel)]="youtubeLink"
                          [ngModelOptions]="{ standalone: true }"
                          pattern="^((https?:\/\/)?(www\.)?youtube\.com\/.*|youtube\.com\/.*|(https?:\/\/)?(www\.)?youtube\.it\/.*|youtube\.it\/.*|(https?:\/\/)?(m\.)?youtube\.com\/.*|m\.youtube\.com\/.*|(https?:\/\/)?youtu\.be\/.*|youtu\.be\/.*)"
                          #youtubeLinkInput="ngModel"
                        />
                        <!-- Error handling for pattern -->
                        <div
                          *ngIf="
                            youtubeLinkInput.errors?.pattern &&
                            youtubeLinkInput.touched
                          "
                          class="alert alert-danger"
                        >
                          {{ "Please enter a valid Youtube link" | translate }}
                        </div>
                      </div>

                      <div class="form-group">
                        <img
                          src="assets/images/profile/tiktok.png"
                          width="16"
                          height="16"
                          alt=""
                        />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="https://tiktok.com/hazelteaches"
                          value="{{ tiktokLink }}"
                          [(ngModel)]="tiktokLink"
                          [ngModelOptions]="{ standalone: true }"
                          pattern="^((https?:\/\/)?(www\.)?tiktok\.com\/.*|tiktok\.com\/.*|(https?:\/\/)?vm\.tiktok\.com\/.*|vm\.tiktok\.com\/.*)"
                          #tiktokLinkInput="ngModel"
                        />
                        <!-- Error handling for pattern -->
                        <div
                          *ngIf="
                            tiktokLinkInput.errors?.pattern &&
                            tiktokLinkInput.touched
                          "
                          class="alert alert-danger"
                        >
                          {{ "Please enter a valid Tiktok link" | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-12 text-right"
                    [ngClass]="{
                      'd-flex': isMobile
                    }"
                  >
                    <button class="btn btn-default" routerLink="/profile">
                      {{ "Cancel" | translate }}
                    </button>
                    <button
                      class="btn btn-primary"
                      (click)="onSave()"
                      [disabled]="
                        name == '' ||
                        languages.length == 0 ||
                        country == '' ||
                        (instagramLinkInput.errors?.pattern &&
                          instagramLinkInput.touched) ||
                        (discordLinkInput.errors?.pattern &&
                          discordLinkInput.touched) ||
                        (twitchLinkInput.errors?.pattern &&
                          twitchLinkInput.touched) ||
                        (facebookLinkInput.errors?.pattern &&
                          facebookLinkInput.touched)
                      "
                    >
                      {{ "Save" | translate }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
