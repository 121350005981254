import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {
  FormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { FirestoreService } from "app/shared/services/firestore.service";
declare var $: any;
import firebase from "firebase/compat/app";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

import { ClassModel } from "./../class.model";

import { v4 as uuidv4 } from "uuid";
import { ToastrService } from "ngx-toastr";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FuseConfigService } from "@fuse/services/config.service";
import { DiscountModel } from "../rate-discount/rate-discount.component";

@Component({
  selector: "add-class",
  templateUrl: "./add-class.component.html",
  styleUrls: ["./add-class.component.scss"],
})
export class AddClassComponent implements OnInit {
  selectedForTime = "";

  selectedToTime = "";
  ForTime: string;
  ToTime: string;
  rates: any[] = [];
  gameIDs: string[] = [];
  gameIDs$: Observable<string[]>;
  gameEnabled$: Observable<boolean[]>;

  language$: Observable<string[]>;
  games: any[] = []; // Array to store the retrieved games from Firestore
  selectedGame: any;
  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  user: firebase.User;
  totime: string;
  languages: string[] = [
    "Italian",
    "English",
    "Spanish",
    "German",
    "French",
    "Portuguese",
  ];
  date = new FormControl();
  keepDropdownOpen = false;
  isCalendarOpen = false;
  minDate: Date;
  selectedClassTime = "";
  menuConfig = {
    closeOnInteraction: false,
  };

  gamePlatforms = ["PC", "Xbox", "PS", "Mobile"];

  peripherals = ["None", "MK", "Joypad", "Driving sim", "VR"];
  daysArray: Array<boolean> = Array.from({ length: 7 }, () => false);
  days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((e) =>
    this.translate.instant(e)
  );
  selectedRate: any;
  selectedDate: any;
  classData: any = {
    image: "",
    gameTitle: "",
    subtitle: "",
    scheduleDays: this.daysArray,
    startTime: this.selectedForTime,
    endTime: this.selectedToTime,
    startDate: this.date.value,
    endDate: this.date.value,
    isGroupClass: true,
    studentName: "",
    remainingLessons: 0,
    uid: "",
    teacherId: "",
    language: "",
    gamingPlatform: "",
    peripherals: "",
    classActivitiesCount: 0,
    classStudentsList: [],
    description: "",
    isBlocked: false,
    isPostponed: false,
    subscriptionPrice: 0,
    notice: "",
    scheduledDates: [],
    maxNumberOfStudents: 12,
  };
  loggedInUserId: string;
  _discounts: DiscountModel[] = [
    { uid: "1", code: "W2xyB", percent: 25, count: 15, type: "Students" , date: new Date()},
    {
      uid: "2",
      code: "10x15zy",
      percent: 15,
      count: 10,
      type: "Days",
      date: new Date()
    },
  ];
  discounts = [];
  selectedDiscounts = [];
  @ViewChild("trigger") trigger: MatMenuTrigger;
  menuDisabled = true; // or false, depending on your logic
  isMobile: boolean = false;
  isDiscountAvailableForClass = false;

  onSave() {
    this.ForTime = this.selectedForTime;
    this.totime = this.selectedToTime;
    this.selectedClassTime = `${this.ForTime} - ${this.totime}`;
    // do something with the timeString, such as save it to a database
  }

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private firestore: FirestoreService,
    private toastr: ToastrService,
    private router: Router,
    private translate: TranslateService,
    private breakpoint: BreakpointObserver,
    private _fuseConfigService: FuseConfigService
  ) {
    // const previousDay = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
    // 2nd jan 2024
    this.minDate = new Date(2024, 0, 2, 0, 0, 0);
  }

  ngOnInit(): void {
    this.breakpoint
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe((o) => {
        this.isMobile = o.matches;

        this._fuseConfigService.config = {
          layout: {
            navbar: {
              hidden: this.isMobile,
            },
            toolbar: {
              hidden: this.isMobile,
            },
            footer: {
              hidden: this.isMobile,
            },
            sidepanel: {
              hidden: this.isMobile,
            },
          },
        };
      });
    this.afAuth.authState.subscribe((user) => {
      console.log("Logged in user:", user);
      this.loggedInUserId = user?.uid;

      if (user) {
        // Get a reference to the Firestore "users" collection
        const usersRef = this.afs.collection<any>("users");
        // Get the current user's document ID
        const userID = user.uid;
        this.gameEnabled$ = usersRef
          .doc(userID)
          .valueChanges()
          .pipe(map((doc) => doc.gameEnabled));
        // Get the user's game IDs and languages from their document
        this.gameIDs$ = usersRef
          .doc(userID)
          .get()
          .pipe(map((doc) => doc.data().gameIds));

        this.language$ = usersRef
          .doc(userID)
          .get()
          .pipe(map((doc) => doc.data().languages));

        // Get the user's rates
        this.afs
          .collection("users")
          .doc(this.loggedInUserId)
          .collection("rates", (ref) => ref.where("isGroup", "==", true))
          .valueChanges()
          .subscribe((rates) => {
            // Store the retrieved rates in a local variable
            this.rates = rates;
          });

        combineLatest([this.gameIDs$, this.gameEnabled$]).subscribe(
          ([gameIDs, gameEnabled]) => {
            // filter gameIDs for which gameEnabled is true
            const enabledGameIDs =
              gameIDs.filter((_, index) => gameEnabled[index]) ?? [];
            this.games = [];
            // Query the games collection based on the enabledGameIDs array in the user document
            this.afs
              .collection("games", (ref) =>
                ref.where("uid", "in", enabledGameIDs)
              )
              .get()
              .subscribe((querySnapshot) => {
                // Loop through the retrieved documents and push them into the games array
                querySnapshot.forEach((doc) => {
                  this.games.push(doc.data());
                });
              });
          }
        );
      }
    });
  }
  discountsUpdated(discounts) {
    this.selectedDiscounts = discounts;
    discounts.forEach((discount) => {
      // this.discountValue = discount.percent;
      // this.updateAfterDiscountPrice();
    });
  }
  updateDaysArray(day: number) {
    console.log(day);
    this.daysArray[day] = !this.daysArray[day];
  }

  getIfDaysEqualToSelectedRate() {
    return (
      this.selectedRate.daysPerWeek == this.daysArray.filter((v) => v).length
    );
  }

  openMenu() {
    // if (this.selectedRate == null) {
    //   this.toastr.error("Rate must be selected before opening the menu.");
    //   this.menuDisabled = true;

    // } else {
    //   this.menuDisabled = false;
    this.trigger.openMenu(); // Assuming 'trigger' is the MatMenuTrigger reference

    // }
  }
  isRateWithSingleDiscount(): boolean {
    return this.selectedRate && this.selectedRate.discounts.length === 1;
  }

  showErrorMessage() {
    alert("This class has a discount already associated with it.");
  }
  async saveClass() {
    if (!this.isFormValid()) {
      this.toastr.error("Please fill in all the required fields");
      return;
    }
    try {
      if (
        this.selectedRate.daysPerWeek != this.daysArray.filter((v) => v).length
      ) {
        this.toastr.error(
          `Please select the correct number of days ${this.selectedRate.daysPerWeek} per week`
        );
        return;
      }
      console.log(this.selectedRate);

      let durationInMonths: string = this.selectedRate?.duration ?? "1";

      let subscriptionPrice: number = this.selectedRate?.price;

      // Calculate total hours between startDate, then add duration(months) to it, and calculate total hours between them

      if (durationInMonths.includes(" ")) {
        durationInMonths = durationInMonths.split(" ")[0];
      }

      let durationInMonthsNumber: number = parseInt(durationInMonths, 10);

      // return;
      const classData: ClassModel = {
        ...this.classData,
        image: this.selectedGame.image,
        gameTitle: this.selectedGame.name,
        scheduleDays: this.daysArray,
        startTime: this.selectedForTime,
        endTime: this.selectedToTime,
        startDate: firebase.firestore.Timestamp.fromDate(
          moment(this.selectedDate).utc().toDate()
        ),
        endDate: firebase.firestore.Timestamp.fromDate(
          moment(this.selectedDate)
            .add(durationInMonthsNumber, "months")
            .utc()
            .toDate()
        ),
        isGroupClass: true,
        teacherId: this.loggedInUserId,
        subscriptionPrice: subscriptionPrice,
        uid: uuidv4(),
        rateId: this.selectedRate.uid,
        discounts: this.selectedDiscounts,
      };
      console.log(classData);
      await this.firestore.set(`classes/${classData.uid}`, { ...classData });
      this.toastr.success(this.translate.instant("Class created successfully"));
      this.router.navigateByUrl("/manager");
    } catch (e) {
      this.toastr.error(e.message);
    }
  }

  selectionChange(key: string, value: string) {
    this.classData[key] = value;
  }

  selectNewGame(game) {
    this.selectedGame = game;
  }
  handleError(errorMessage: string): void {
    // Logic to display the error message
    console.error(errorMessage); // Or use a more user-friendly notification system
  }

  startTimeSelected() {
    console.log(this.selectedForTime); // e.g., "03:15"

    // Parse the hours and minutes from the start time
    let [startHours, startMinutes]: string[] = this.selectedForTime.split(":");

    // Create a new Date object for the start time
    let startDate: Date = new Date();
    startDate.setHours(parseInt(startHours), parseInt(startMinutes));

    // Add the duration in hours to the start time
    let hoursToAdd: number = this.selectedRate.hrsPerDay;
    startDate.setHours(startDate.getHours() + hoursToAdd);

    // Format the new time in 24-hour format
    let endHours: string = startDate.getHours().toString();
    let endMinutes: string = startDate.getMinutes().toString();

    endMinutes = +endMinutes < 10 ? "0" + endMinutes : endMinutes;
    endHours = +endHours < 10 ? "0" + endHours : endHours;

    // The resulting time
    this.selectedToTime = `${endHours}:${endMinutes}`;
    console.log(this.selectedToTime);
  }

  isFormValid() {
    return (
      this.classData &&
      this.classData.title &&
      this.selectedGame &&
      this.selectedRate &&
      this.selectedDate &&
      this.selectedForTime &&
      this.selectedToTime &&
      this.daysArray.filter((v) => v).length == this.selectedRate.daysPerWeek &&
      this.classData.peripherals &&
      this.classData.peripherals.length > 0 &&
      this.classData.gamingPlatform &&
      this.classData.gamingPlatform.length > 0 &&
      this.classData.language &&
      this.classData.language.length > 0 &&
      this.classData.description
    );
  }

  isNotTouched() {
    return this.daysArray.filter((v) => v).length > 0;
  }
}
