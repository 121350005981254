import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { calendarDialogComponent } from "./calendar/calendar-dialog.component";
declare var $: any;
function numericValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isNumeric = /^\d+$/.test(control.value);
    return isNumeric ? null : { numeric: { value: control.value } };
  };
}
@Component({
  selector: "private-rates-edit",
  templateUrl: "./private-rates-edit.component.html",
  styleUrls: ["./private-rates-edit.component.scss"],
})
export class privateRatesEditComponent implements OnInit {
  rateForm = new FormGroup({
    description: new FormControl(""),
    title: new FormControl(""),
    duration: new FormControl(""),
    daysPerWeek: new FormControl(""),
    hoursPerDay: new FormControl(""),
    noOfLessons: new FormControl(""),
    price: new FormControl("", [numericValidator()]),
    totalLessons: new FormControl("", [numericValidator()]),

    pricePerStudent: new FormControl("", [numericValidator()]),
    serviceCostPerStd: new FormControl("", [numericValidator()]),
    pricePerClass: new FormControl("", [numericValidator()]),
    fullEarning: new FormControl("", [numericValidator()]),
  });
  ratesCollection: any;

  content: any;
  users: any;
  isLoggedin: Boolean = false;
  form: UntypedFormGroup;
  btnDisabled: boolean = false;
  loggedInUserId: string;
  // to calculate
  totalLessons = 0;
  serviceCostPerStd = 0;
  hourlyPricePerStd = 0;
  hourlyPricePerStdAfterDate = 0;
  pricePerClass = 0.0;
  vat = 22.0;
  fullEarning = 0.0;
  isGroup = false;
  constructor(
    private afs: AngularFirestore,
    private matDialog: MatDialog,
    private afAuth: AngularFireAuth,
    private router: Router
  ) {
    this.afAuth.authState.subscribe((user) => {
      this.loggedInUserId = user?.uid;
    });

    this.ratesCollection = this.afs
      .collection("users")
      .doc(this.loggedInUserId)
      .collection("rates").valueChanges;
  }

  ngOnInit() {

    this.form.valueChanges.subscribe((data) => {
      
      this.getTotalLessons(data.duration, data.daysPerWeek);
      this.calculateServicePricePerStd(data.price);
      this.getHourlyPricePerStudent(
          data.price, data.hoursPerDay, data.totalLessons);
      this.getHourlyPricePerStudentAfterDate();
      this.getPricePerClass(data.price);
      this.getVat(data);
      this.getFullEarning();
    });

  }
  onSubmit() {
    const data = this.rateForm.value;
    console.log(data);
    this.afs
      .collection("users")
      .doc(this.loggedInUserId)
      .collection("rates")
      .add({
        creationDateTS: new Date(),
        description: data.description,
        duration: data.duration,
        fullEarning: Number(data.fullEarning),
        hourlyPricePerStd: Number(data.pricePerStudent),
        hrsPerDay: Number(data.hoursPerDay[0]),
        isGroup: false,

        noOfLessons: Number(data.noOfLessons),
        price: Number(data.price),
        pricePerClass: Number(data.pricePerClass),

        serviceCostPerStd: Number(data.serviceCostPerStd),
        title: data.title,
        daysPerWeek: Number(data.daysPerWeek),

        totalLessons: Number(data.totalLessons),
      })
      .then(() => {
        this.router.navigate(["/manage-rates"]);
      });
  }

  calendar() {
    this.matDialog.open(calendarDialogComponent);
  }

  getTotalLessons(duration: string, daysPerWeek: string) {
    if (duration.length == 0 || daysPerWeek.length == 0) {
      return "0";
    }
    var durationInMonths = parseInt(duration.split(" ")[0]);
    var daysPerWeekInt = parseInt(daysPerWeek);

    this.totalLessons = Math.round((durationInMonths * 4.34 * daysPerWeekInt));

    return this.totalLessons.toString();
  }

  calculateServicePricePerStd(price) {
    if (price.length == 0) {
      return '0';
    }
  
      this.serviceCostPerStd = (parseFloat(price) * 0.05);
 
    return this.serviceCostPerStd.toPrecision(1);
  }

  getHourlyPricePerStudent(
      price, hoursPerDay, totalLessons) {
    if (price.length == 0 || totalLessons == 0 || hoursPerDay.length == 0) {
      return '0';
    }
    
      // price/(hours per day / total lessons)
      this.hourlyPricePerStd =
          (parseFloat(price) / (totalLessons * parseInt(hoursPerDay)))
              ;
    
    return this.hourlyPricePerStd.toString(2);
  }

  getHourlyPricePerStudentAfterDate() {
    
    this.hourlyPricePerStdAfterDate = Math.fround(this.hourlyPricePerStd * 1.3);
    return this.hourlyPricePerStdAfterDate.toPrecision(2);
  }

  getPricePerClass(price) {
    if (price.length == 0) {
      return '0';
    }
   
      this.pricePerClass = (parseFloat(price) * (this.isGroup ? 9 : 1));

    return this.pricePerClass.toPrecision(2);
  }

  getVat(userData) {
    //  This value should change based on the country the master pays VAT to. In Italy it is 22%.
    if (!this.isGroup) {
      this.getPricePerClass(this.form.value['price']);
    }
    
      this.vat = (this.pricePerClass * (22 / 100));
   
    return this.vat.toPrecision(2);
  }

  getFullEarning() {
    if (!this.isGroup &&
        (this.form.value['price'].length == 0 ||
        this.form.value['duration'].length == 0 ||
        this.form.value['totalLessons'].length == 0)) {
      return '0';
    }
    
      let price = this.isGroup ? this.pricePerClass : this.pricePerClass;
    // double.parse(getHourlyPricePerStudent(priceController!.text,
    //     durationController!.text, int.parse(noOfLessonsContrller!.text)));

    if (this.form.value['price'].length == 0 || this.vat == 0 || price == 0) {
      return '0';
    }
    if (!this.isGroup) {
      price = price - this.serviceCostPerStd;
    }

 
      this.fullEarning = price -
          // (serviceCostPerStd * (this.isGroup ? 9 : 1)) -
          this.vat;
    
    return this.fullEarning.toPrecision(1);
  }

}
