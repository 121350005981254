import { Component, Inject, OnInit } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FirestoreService } from "app/shared/services/firestore.service";
import * as firebase from "firebase/compat";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs";

@Component({
  selector: "add-product-dialog",
  templateUrl: "./add-product-dialog.component.html",
  styleUrls: ["./add-product-dialog.component.scss"],
})
export class addProductDialogComponent implements OnInit {
  /**
   * Constructor
   *
   * @param {MatDialogRef<addProductDialog>} dialogRef
   */
  form: FormGroup;
  controlNames = Array.from({ length: 5 }, (_, i) => i);
  imageUrls: string[] = Array(5).fill("");
  uploading: boolean[] = Array(5).fill(false);
  isSaving = false; // New property to track save state

  constructor(
    public dialogRef: MatDialogRef<addProductDialogComponent>,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private firestore: FirestoreService,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const group: { [key: string]: any[] } = {};

    for (let i = 0; i < this.controlNames.length; i++) {
      group[`productName${i}`] = [""];
      group[`productLink${i}`] = ["", this.validateAmazonUrl];
    }

    this.form = this.fb.group(group, {
      validator: this.atLeastOneUrlValidator,
    });

    // Fetch existing product data
    this.firestore.doc$(`users/${this.data.userId}`).subscribe((user: any) => {
      if (user.productLinks) {
        user.productLinks.forEach((product, index) => {
          this.form.patchValue({
            [`productName${index}`]: product.name ?? "",
            [`productLink${index}`]: product.link ?? "",
          });
          this.imageUrls[index] = product.image ?? "";
        });
      }
    });
  }

  validateAmazonUrl(control: FormControl) {
    if (control.value == "") return null;
    // The regex checks if the URL starts with "https://www.amazon.com/" or "www.amazon.com/" or "amazon.com/"
    let urlPattern =
      /^((https?:\/\/)?(www\.)?amazon\.(com|it|co\.uk|de|fr|es|ca|in|com\.au|com\.br|com\.mx)\/).*/i;
    if (!urlPattern.test(control.value)) {
      return { "Invalid URL": true };
    }
    return null;
  }
  atLeastOneUrlValidator(group: FormGroup): any {
    let isAtLeastOne = false;
    Object.keys(group.controls).forEach((field) => {
      // Check if the field is a productLink
      if (field.includes("productLink")) {
        const control = group.get(field);
        if (control.value !== "") {
          isAtLeastOne = true;
        }
      }
    });

    return isAtLeastOne ? null : { notValid: true };
  }

  save() {
    if (this.form.valid) {
      this.isSaving = true; // Start saving
      const formData = this.form.value;
      const uid = this.data.userId;

      const products = this.controlNames.map((i) => {
        // Check if any of the values are empty
        if (
          !formData[`productName${i}`] ||
          !formData[`productLink${i}`] ||
          !this.imageUrls[i]
        ) {
          return null; // Return null if any value is empty
        }

        return {
          name: formData[`productName${i}`],
          link: formData[`productLink${i}`],
          image: this.imageUrls[i],
        };
      });

      this.firestore
        .update(`users/${uid}`, { productLinks: products })
        .then(() => {
          this.isSaving = false; // Done saving
          this.toastr.success(
            this.translate.instant("Product links updated successfully")
          );
          this.dialogRef.close();
        })
        .catch((e) => {
          this.isSaving = false; // Done saving
          this.dialogRef.close();
          this.toastr.error(
            this.translate.instant("Error updating product links")
          );
        });
    }
  }

  uploadImage(event: any, index: number) {
    const file = event.target.files[0];
    const filePath = `product_images/${new Date().getTime()}_${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploading[index] = true;

    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            this.imageUrls[index] = url;
            // Set uploading for this index to false after the upload completes
            this.uploading[index] = false;
          });
        })
      )
      .subscribe();
  }
  // Check if any image is uploading
  isUploading(): boolean {
    return this.uploading.some((u) => u);
  }
  removeProduct(index: number) {
    // Confirm before clearing the product details
    if (!confirm(this.translate.instant('Are you sure you want to clear this product?'))) {
      return;
    }
  
    // Clear the product details at the specified index
    this.form.patchValue({
      [`productName${index}`]: '',
      [`productLink${index}`]: ''
    });
  
    // Optionally, clear the associated image URL
    this.imageUrls[index] = '';
  
    // Update the uploading status for the index
    this.uploading[index] = false;
  
    // Filter out empty products and update Firebase
    const uid = this.data.userId;
    const filledProducts = this.controlNames.map(i => ({
      name: this.form.get(`productName${i}`).value,
      link: this.form.get(`productLink${i}`).value,
      image: this.imageUrls[i]
    })).filter(product => product.name || product.link || product.image);
  
    this.firestore.update(`users/${uid}`, { productLinks: filledProducts })
      .then(() => {
        this.toastr.success(this.translate.instant('Product details cleared successfully'));
      })
      .catch(error => {
        this.toastr.error(this.translate.instant('Error clearing product details'));
        console.error('Error clearing product details:', error);
      });
  }
  
  
}
