import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, take, map, tap, catchError, of, switchMap } from "rxjs";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
// import 'rxjs/add/operator/do';

@Injectable()
export class AppAuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    // private authService: AuthService,
    // private matSnackBar: MatSnackBar,
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.auth.user.pipe(
      take(1),
      switchMap((user) => {
        if (!user) {
          console.log("called here");
          this.router.navigate(["login"], {queryParamsHandling: 'preserve'});
          return of(false);
        } else {
          // Fetch user data from Firestore
          return this.firestore
            .collection("users")
            .doc(user.uid)
            .get()
            .pipe(
              map((doc) => {
                if (doc.exists) {
                  const userData = doc.data() as any;
                  if (!userData.enabled) {
                    // this.auth.signOut().then(() => {
                    //   // alert("Your account is disabled. Please contact admin.");
                    //   // this.router.navigate(["login"]);
                    //   return false;
                    // });
                    
                    return false;
                  }
                  console.log("here");
                  // If user step is 3 or greater, the navigation is allowed
                  if (userData.step >= 3) {
                    return true;
                  } else {
                    // If the user's step is less than 3, redirect them to the correct step
                    switch (userData.step) {
                      case 0:
                        this.router.navigate(["phone-auth"]);
                        break;
                      case 1:
                        this.router.navigate(["signup-detail"]);
                        break;
                        case 2:
                          // allow navigating to both "choose-game" and "gaming-curriculum"
                          if (state.url === "/choose-game" || state.url === "/gaming-curriculm") {
                            return true;
                          } else {
                            this.router.navigate(["choose-game"]);
                          }
                          break;
                      // case 3:
                      //   this.router.navigate(["gaming-curriculm"]);
                      //   break;
                      default:
                        this.router.navigate(["dashboard"]);
                    }
                    return false;
                  }
                } else {
                  // If the user document does not exist, return false or redirect to another route
                  return false;
                }
              })
            );
        }
      }),
      catchError(() => {
        // If there is an error (for instance, due to a network issue), navigate to login
        this.router.navigate(["login"], {queryParamsHandling: 'preserve'});
        return of(false);
      })
    );
  }

  // async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   // let profile: any = {};
  //   // this.store.select('User').subscribe((user: User) => {
  //   //   profile = user;
  //   // })

  //   // LOGGED IN: FALSE
  //   // ACCESSING UNPROTECTED ROUTES: FALSE
  //   if (
  //     state.url !== "/login" &&
  //     state.url !== "/forget-password" &&
  //     !this.authService.isUserLoggedIn()
  //   ) {
  //     return true;
  //   }

  //   // LOGGED IN: FALSE
  //   // ACCESSING UNPROTECTED ROUTES: TRUE
  //   if (
  //     (state.url === "/login" || state.url === "/forget-password") &&
  //     !this.authService.isUserLoggedIn()
  //   ) {
  //     return true;
  //   }

  //   // LOGGED IN: TRUE
  //   // ACCESSING UNPROTECTED ROUTES: TRUE

  //   // // PROTECTING ROUTES BASED ON THEIR ROLES
  //   // let isAllowed = false;

  //   // if (state.url === "/") {
  //   //   isAllowed = true;
  //   //   return isAllowed;
  //   // }

  //   // // IF PROFILE OBJECTS EXISTS, THEN ROLE BASED ACCESS
  //   // if (profile) {

  //   //   // IF ROLE IS ADMIN
  //   //   // if (profile.userRole.find(role => role.name == Roles.ADMIN)) {
  //   //   //   isAllowed = true;
  //   //   //   if ((state.url !== "/" && state.url !== "/dashboards")) {
  //   //   //     this.accessNotAllowed();
  //   //   //     return false;
  //   //   //   }
  //   //   // }
  //   //   // else {
  //   //   //   // IF URL IS DASHBOARDS AND DASHBOARDS NOT ALLOWED, REDIRECTING TO SUBSCRIPTIONS
  //   //   //   if(profile.userRole && !profile.userRole.find(role => role.name == Roles.GUEST) && state.url === "/dashboards") {
  //   //   //     this._router.navigateByUrl("/subscriptions")
  //   //   //   }

  //   //   //   this.accessNotAllowed();
  //   //   //   return false
  //   //   // }
  //   // }
  //   // return isAllowed;
  // }

  // accessNotAllowed() {
  //   this.matSnackBar.open("Access not allowed", "Ok", {
  //     duration: 2000,
  //     verticalPosition: "bottom",
  //   });
  // }
}
