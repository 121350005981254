<app-navbar *ngIf="isMobile" [title]="'Add Class' | translate"></app-navbar>

<div class="main pt-3">
  <div class="add-class">
    <div class="container-fluid">
      <h2>{{ "Add Your Class" | translate }}</h2>
      <div class="form-area">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>{{ "Class Title :" | translate }} </label>
              <input
                type="text"
                class="form-control"
                id=""
                placeholder=""
                autocomplete="off"
                [(ngModel)]="classData.title"
                [max]="20"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{ "Game :" | translate }} </label>
              <select
                class="form-control"
                name="game"
                (ngModel)="(selectedGame)"
                (ngModelChange)="selectNewGame($event)"
              >
                <option *ngFor="let game of games" [ngValue]="game">
                  {{ game.name }}
                </option>
              </select>
              <mat-error *ngIf="games && games.length == 0">
                {{
                  "No games available, wait for your request to be approved."
                    | translate
                }}
              </mat-error>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{ "Class Time :" | translate }} </label>

              <div
                [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="menuConfig"
                style="cursor: pointer"
                (click)="openMenu()"
                #trigger="matMenuTrigger"
              >
                <input
                  matInput
                  type="text"
                  class="form-control"
                  [value]="selectedClassTime"
                  readonly
                  [placeholder]="
                    selectedRate != null
                      ? 'Seleziona l`orario'
                      : 'Seleziona una tariffa prima di selezionare l`orario'
                  "
                  autocomplete="off"
                />
              </div>

              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="time"
              >
                <mat-menu #menu="matMenu">
                  <p *ngIf="!selectedRate" class="mat-error p-2">
                    {{ "Please select rate before setting time." | translate }}
                  </p>
                  <div class="select-date-time" *ngIf="selectedRate">
                    <h4>{{ "Set Class time and days" | translate }}</h4>
                    <p class="alert alert-danger text-center">
                      {{
                        "Be careful to not set overlapping times with other classes."
                          | translate
                      }}
                    </p>
                    <div class="set-time" (click)="$event.stopPropagation()">
                      <span class="text">{{ "Set time" | translate }}</span>
                      <div class="time">
                        <mat-form-field>
                          <mat-label>{{ "Start Time" | translate }}</mat-label>
                          <input
                            matInput
                            [ngxMatTimepicker]="picker"
                            readonly
                            [(ngModel)]="selectedForTime"
                            name="selectedTime"
                            [format]="24"
                            (ngModelChange)="startTimeSelected()"
                          />
                          <mat-icon matSuffix (click)="picker.open()">
                            watch_later
                          </mat-icon>
                        </mat-form-field>

                        <ngx-mat-timepicker #picker></ngx-mat-timepicker>
                      </div>

                      <span class="to">{{ "To" | translate }}</span>
                      <div class="time">
                        <mat-form-field>
                          <mat-label>{{ "End Time" | translate }}</mat-label>
                          <!--                              [ngxMatTimepicker]="picker1" -->

                          <input
                            matInput
                            readonly
                            [ngxMatTimepicker]="picker1"
                            [(ngModel)]="selectedToTime"
                            name="selectedTimeTo"
                            [format]="24"
                            [disabled]="true"
                          />
                          <mat-icon matSuffix> watch_later </mat-icon>
                        </mat-form-field>

                        <ngx-mat-timepicker #picker1></ngx-mat-timepicker>
                      </div>
                    </div>
                    <div
                      class="days"
                      style="padding: 1rem"
                      (click)="$event.stopPropagation()"
                    >
                      <h4 class="text-left">
                        {{ "Schedule Class" | translate }}
                      </h4>
                      <div class="select-days">
                        <span *ngFor="let day of days; let i = index">
                          <input
                            type="checkbox"
                            id="{{ day }}"
                            class="graphic"
                            (click)="
                              keepDropdownOpen = true; updateDaysArray(i)
                            "
                          />
                          <label style="cursor: pointer" for="{{ day }}">{{
                            day
                          }}</label>
                        </span>
                      </div>
                    </div>
                    <div
                      class="alert alert-danger"
                      *ngIf="isNotTouched() && !getIfDaysEqualToSelectedRate()"
                    >
                      {{
                        ("Please select days according to selected rate. i.e. "
                          | translate) +
                          selectedRate.daysPerWeek +
                          " days"
                      }}
                    </div>
                    <div class="btn-row text-center my-3">
                      <div class="btn btn-default mr-3">
                        {{ "Cancel" | translate }}
                      </div>
                      <div
                        class="btn btn-primary"
                        (click)="onSave()"
                        [disabled]="!getIfDaysEqualToSelectedRate()"
                      >
                        {{ "Save" | translate }}
                      </div>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group date-option">
              <label class="d-flex"
                >{{ "Starting Date :" | translate }}
                <mat-icon
                  class="info-tooltip"
                  [matTooltip]="'Starting Date Tooltip' | translate"
                  >info</mat-icon
                >
              </label>

              <mat-form-field appearance="outline" class="w-100">
                <input
                  matInput
                  [matDatepicker]="datePicker"
                  [min]="minDate"
                  [(ngModel)]="selectedDate"
                />

                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="datePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker class="qwe" #datePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group rate-option">
              <label>{{ "Choose Rate :" | translate }}</label>
              <mat-form-field appearance="outline">
                <mat-label></mat-label>
                <mat-select name="rate" [(ngModel)]="selectedRate">
                  <mat-option *ngFor="let rate of rates" [value]="rate">
                    <div class="box">
                      <div class="rate">
                        <span
                          >€{{ rate.price }}/{{ rate.duration.split(' ')[0] }} <!-- Display the numeric part -->
                          {{ ' ' }} <!-- Add a space -->
                          {{ rate.duration.split(' ')[1] | translate }}
                        </span>
                        <!-- <button class="btn btn-primary">Select</button> -->
                      </div>
                      <div class="text">
                        <span>{{ rate.title }}</span>
                      </div>
                    </div>
                  </mat-option>
                </mat-select>
                <!-- <mat-icon matSuffix>attach_money</mat-icon> -->
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>{{ "Language:" | translate }}</label>
              <ng-container
                *ngIf="language$ | async as langs; else noLanguages"
              >
                <select
                  class="form-control"
                  id=""
                  (selectionchange)="selectionChange('language', $event)"
                  [(ngModel)]="classData.language"
                >
                  <option *ngFor="let language of langs" [value]="language">
                    {{ language }}
                  </option>
                </select>
              </ng-container>
              <ng-template #noLanguages>
                <p>
                  {{
                    "No language added, please add languages in your profile"
                      | translate
                  }}
                </p>
              </ng-template>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>{{ "Game Platforms :" | translate }}</label>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select
                  id=""
                  multiple
                  [(ngModel)]="classData.gamingPlatform"
                  name="platform"
                >
                  <mat-option
                    *ngFor="let platform of gamePlatforms"
                    [value]="platform"
                  >
                    {{ platform }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{ "Required Peripherals :" | translate }}</label>

              <mat-form-field appearance="outline" style="width: 100%">
                <mat-select
                  id=""
                  multiple
                  [(ngModel)]="classData.peripherals"
                  name="peripherals"
                >
                  <mat-option *ngFor="let peri of peripherals" [value]="peri">
                    {{ peri }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{ "Maximum number of Students :" | translate }}</label>
              <input
                type="number"
                min="1"
                max="12"
                class="form-control"
                id=""
                placeholder=""
                autocomplete="off"
                [(ngModel)]="classData.maxNumberOfStudents"
                name="maxNumberOfStudents"
                #maxNumberOfStudents="ngModel"
              />
              <div
                *ngIf="
                  maxNumberOfStudents.invalid && maxNumberOfStudents.touched
                "
                class="alert alert-danger"
              >
                <div *ngIf="maxNumberOfStudents.errors?.min">
                  {{ "Minimum value is 5." | translate }}
                </div>
                <div *ngIf="maxNumberOfStudents.errors?.max">
                  {{ "Maximum value is 12." | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>{{ "Description :" | translate }}</label>

              <mat-form-field appearance="outline" style="width: 100%">
                <textarea
                  matInput
                  placeholder="{{ 'Description' | translate }}"
                  name="Description"
                  [(ngModel)]="classData.description"
                >
                </textarea>
              </mat-form-field>
            </div>
          </div>
          <div
            class="col-md-12"
            [class.disabled]="isRateWithSingleDiscount()"
            (click)="isRateWithSingleDiscount() ? showErrorMessage() : null"
          >
            <app-rate-discount
            (error)="handleError($event)"
            (onSave)="discountsUpdated($event)"
            [discountsList]="_discounts"
            [selectedDiscounts]="selectedDiscounts"
            [isDiscountAvailableForClass]="isDiscountAvailableForClass"
            ></app-rate-discount>
          </div>
          <div *ngIf="isRateWithSingleDiscount()" class="error-message">
            {{"This class has a discount already associated with it." | translate}}
          </div>
          <div class="col-md-12 text-right">
            <button
              class="btn btn-primary"
              [disabled]="!isFormValid() || maxNumberOfStudents.errors"
              (click)="saveClass()"
            >
              {{ "Save" | translate }}
            </button>
          </div>
        </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
</div>
