import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { InfoDialogComponent, ProfileComponent } from "./profile.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { ProfileEditComponent } from "./profile-edit/profile-edit.component";
import { MatCardModule } from "@angular/material/card";
import { CarouselModule } from "ngx-owl-carousel-o";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { SharedAppModule } from "app/shared/shared-app.module";
import { AppAuthGuard } from "app/app.authguard";
import { HttpClientModule } from "@angular/common/http";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

const routes = [
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AppAuthGuard]

  },
  {
    path: "profile-edit",
    component: ProfileEditComponent,
    canActivate: [AppAuthGuard]

  },
];

@NgModule({
  declarations: [ProfileComponent, ProfileEditComponent, InfoDialogComponent],
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatTabsModule,
    MatCardModule,
    CarouselModule,
    MatFormFieldModule, 
    MatInputModule, 
    MatSelectModule,
    TranslateModule, 
    SharedAppModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule
  ],
})
export class ProfileModule {}
