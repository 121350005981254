<div id="auth" class="auth verification">
  <div class="container-fluid">
    <img
      class="sweech-logo"
      style="position: absolute"
      src="assets/images/sweech-logo.png"
      alt=""
    />
    <div class="row align-items-center">
      <div class="col-md-5">
        <div class="auth-box pt-0 text-center">
          <h1>Verify your Email</h1>
          <p>Enter the 4 Digit Code sent to your phone</p>
          <form>
            <div class="verify-code-fields">
              <input
                type="number"
                class="form-control"
                id=""
                placeholder=""
                autocomplete="off"
              />
              <input
                type="number"
                class="form-control"
                id=""
                placeholder=""
                autocomplete="off"
              />
              <input
                type="number"
                class="form-control"
                id=""
                placeholder=""
                autocomplete="off"
              />
              <input
                type="number"
                class="form-control"
                id=""
                placeholder=""
                autocomplete="off"
              />
            </div>
            <p class="resend-code-text">
              Didn’t recieve code? <a href="">Request again</a>
            </p>
            <button
              type="submit"
              class="btn btn-primary mb-3"
              routerLink="/signup-detail"
            >
              Verify & Create Account
            </button>
          </form>
        </div>
      </div>
      <div class="col-md-7">
        <img class="img-fluid" src="/assets/images/login.jpg" />
      </div>
    </div>
  </div>
</div>
