
<ngx-stripe-card-group
    [formGroup]="stripeTest"
    [elementsOptions]="elementsOptions"
  >
    <mat-form-field appearance="outline">
        <input matInput formControlName="name" />
    </mat-form-field>
    <span class="mr-1"></span>
    <mat-form-field>
        <input matInput appearance="outline" formControlName="amount" />
    </mat-form-field>
    
    
    <ngx-stripe-card-number [options]="cardOptions"></ngx-stripe-card-number>
    <ngx-stripe-card-expiry [options]="cardOptions"></ngx-stripe-card-expiry>
    <ngx-stripe-card-cvc [options]="cardOptions"></ngx-stripe-card-cvc>
  </ngx-stripe-card-group> 

<div style="width: 450px;" class="mb-4">
  <ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
</div>

<button mat-raised-button color="primary" type="submit" (click)="pay()">PAY</button>
