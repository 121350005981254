import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { SettingsComponent } from "./settings.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { addProductDialogComponent } from "./add-product-dialog/add-product-dialog.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SharedAppModule } from "app/shared/shared-app.module";
import { selectPaymentDialogComponent } from "./select-payment-dialog/select-payment-dialog.component";
import { CreditCardFormatterDirective, cardDetailDialogComponent } from "./card-detail-dialog/card-detail-dialog.component";
import { TranslateModule } from "@ngx-translate/core";
import { AppAuthGuard } from "app/app.authguard";
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { MatRadioModule } from "@angular/material/radio";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConnectStripeComponent } from './connect-stripe/connect-stripe.component';

const routes = [
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: "settings/connect-stripe",
    component: ConnectStripeComponent,
    canActivate: [AppAuthGuard]
  }
];

@NgModule({
  declarations: [
    SettingsComponent,
    addProductDialogComponent,
    selectPaymentDialogComponent,
    cardDetailDialogComponent,
    CreditCardFormatterDirective,
    VerifyAccountComponent,
    ConnectStripeComponent
  ],
  imports: [
    MatButtonModule,
    MatDialogModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    SharedAppModule,
    MatSnackBarModule,
    TranslateModule,
    MatRadioModule,
    MatProgressSpinnerModule
  ],
  exports: [
    VerifyAccountComponent
  ]
})
export class SettingsModule {}
